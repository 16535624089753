import { Route, Routes } from "react-router-dom";
import Inbox from "./inbox/Inbox";
import Settings from "./settings/Settings";
import Profile from "./profile/Profile";
import ListingLayout from "../../layouts/ListingLayout";
import NotFoundPage from "./error/NotFoundPage";
import Calendar from "./calendar";
import OnboardingFlow from "./onboardingTwo/OnboardingFlow";
import Menu from "./menu";
import EmailVerify from "./emailVerfiy/emailVerify";

function PrivateViews() {
  return (
    <>
      <Routes>
        <Route index element={<Inbox />} />
        <Route path="/" element={<Inbox />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path={"/listings/*"} element={<ListingLayout />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/onboarding" element={<OnboardingFlow />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/email-verify" element={<EmailVerify />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default PrivateViews;
