
import axios from "axios";
import { BASE_API_URL } from ".";
import { GenericMetaData, ReservationDetails } from "../types";

const API_BASE_URL = BASE_API_URL



export interface GetAllReservationDetailsParams {
  accessToken: string;
  startPoint: string
  propertyId: string
}

export const getAllReservationDetailsApi = async ({
  accessToken,
  startPoint,
  propertyId
}: GetAllReservationDetailsParams) => {
  const startDate = new Date(startPoint);
  startDate.setMonth(new Date(startPoint).getMonth() - 2);
  const endDate = new Date(startPoint);
  endDate.setMonth(new Date(startPoint).getMonth() + 2);
  try {
    const response = await axios.post(`${API_BASE_URL}/Reservation/GetAll`,
      {
        properties: [propertyId],
        startDate,
        endDate
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

    return response.data as GenericMetaData<ReservationDetails>;
  } catch (error: any) {
    console.error("Veri çekme hatası:", error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch booking details"
    );
  }
};

export interface GetReservationDetailsParams {
  accessToken: string;
  bookingId: string;
  propertyId: string
}

export const getReservationDetailsApi = async ({
  accessToken,
  bookingId,
}: GetReservationDetailsParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Reservation/Get`, {
      params: { Id: bookingId },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return response.data as ReservationDetails;
  } catch (error: any) {
    console.error("Veri çekme hatası:", error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch booking details"
    );
  }
};


