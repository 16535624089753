import React, { useEffect, useState } from "react";
import styles from "../onboardingTwoStyles";
import SearchIcon from "../../../../assets/images/icons/general.svg";
import settings from "../../../../assets/images/setting.svg";
import {
  Box,
  Button,
  Stack,
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import locationPin from "../../../../assets/images/locationPin.svg";
import placeholder from "../../../../assets/images/placeholder.png";
import unchecked from "../../../../assets/images/UnCheckbox.svg";
import checked from "../../../../assets/images/Checkbox.svg";
import lighter from "../../../../assets/images/lighter-cancel.svg";
import axios from "axios";
import CustomButton from "../../../../components/shared-components/CustomButton";
import { useAuth0 } from "@auth0/auth0-react";

interface Property {
  propertyId: string;
  name: string;
  city: string;
}

interface ListingModalProps {
  handleAddProperty: (selectedListings: number[]) => void;
  selectedListings: number[];
  selectedUserId?: string;
  userProperties?: Property[];
  onRemoveProperty?: (propertyId: string) => void;
}

function ListingModal({
  handleAddProperty,
  selectedListings: initialSelectedListings,
  userProperties,
  onRemoveProperty,
}: ListingModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [listings, setListings] = useState<any>([]);
  const [filteredListings, setFilteredListings] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectedListings, setSelectedListings] = useState<number[]>(
    initialSelectedListings
  );
  const [selectAll, setSelectAll] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await axios.get(
          `${API_BASE_URL}/Property/GetAll?PageIndex=1&PageSize=20`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const apiData = response.data.items
          .filter((property: any) => property.status === true)
          .map((property: any, index: number) => ({
            Id: property.id,
            listingName: property.name || "N/A",
            nickname: property.internalName || "N/A",
            typeOfUnit: property.typeCode || "N/A",
            city: property.city || "N/A",
            address: `${property.street}, ${property.city}, ${property.state}, ${property.zipCode}`,
            tags: property.tags?.length > 0 ? property.tags : ["No Tags"],
            status: property.status,
          }));

        setListings(apiData);
        setFilteredListings(apiData);
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    fetchListings();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    setSelectedListings(initialSelectedListings);
  }, [initialSelectedListings]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    const searchTerm = e.target.value.toLowerCase();

    const filtered: any[] = listings.filter(
      (listing: any) =>
        listing.listingName.toLowerCase().includes(searchTerm) ||
        listing.Id.toLowerCase().includes(searchTerm) ||
        listing.city.toLowerCase().includes(searchTerm)
    );

    setFilteredListings(filtered);
  };

  const handleToggle = (id: number) => {
    if (selectedListings.includes(id)) {
      setSelectedListings(
        selectedListings.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedListings([...selectedListings, id]);
    }
  };

  const shouldShowNoListingsMessage = () => {
    return !userProperties?.length && selectedListings.length === 0;
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedListings([]);
    } else {
      setSelectedListings(filteredListings.map((listing: any) => listing.Id));
    }
    setSelectAll(!selectAll);
  };

  const handleRemove = (id: number) => {
    setSelectedListings(
      selectedListings.filter((selectedId) => selectedId !== id)
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%",
        maxHeight: "550px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          padding: isMobile ? "0" : "20px 0",
          gap: "20px",
          height: "100%",
          minHeight: "100%",
          maxHeight: "calc(100vh - 200px)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            ...styles.innerModalContainer,
            border: isMobile
              ? "none"
              : "1px solid var(--Gray-Gray-300, #E1E3EA)",
            padding: isMobile ? "0px" : "12px",
            height: "100%",
            flex: 1,
            overflow: "hidden",
          }}
        >
          {!isMobile && (
            <p style={{ fontSize: "18px" }} className="radioButtonDescription">
              All Listings
            </p>
          )}

          <Stack my={"20px"} width={"100%"} mt={isMobile ? 0 : undefined}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={search}
              onChange={handleSearch}
              placeholder={"Search"}
              InputProps={{
                startAdornment: (
                  <img
                    style={{
                      cursor: "pointer",
                      width: "20px",
                      marginLeft: "10px",
                    }}
                    src={SearchIcon}
                    alt="search-icon"
                  />
                ),
                endAdornment: (
                  <img
                    style={{
                      cursor: "pointer",
                      width: "20px",
                      marginRight: "10px",
                    }}
                    src={settings}
                    alt="settings-icon"
                  />
                ),
              }}
            />
          </Stack>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              checkedIcon={
                <img src={checked} alt="checked" style={{ width: 16 }} />
              }
              icon={
                <img src={unchecked} alt="unchecked" style={{ width: 16 }} />
              }
            />
            <span className="listingSubtitle">
              Select all ({filteredListings?.length})
            </span>
          </label>
          <List
            sx={{
              maxHeight: isMobile ? "100%" : "305px",
              height: "100%",
              minWidth: isMobile ? "100%" : "370px",
              pb: "20px",
              overflowY: "auto",
            }}
          >
            {filteredListings.length > 0 ? (
              filteredListings.map((listing: any, index: number) => (
                <ListItem
                  key={listing.Id}
                  onClick={() => handleToggle(listing.Id)}
                  sx={{
                    cursor: "pointer",
                    padding: "12px 0",
                    width: isMobile ? "100%" : "370px",
                    minWidth: "370px",
                    "&:hover": {
                      backgroundColor: "#F1F1F2",
                    },
                    borderBottom:
                      index !== filteredListings.length - 1
                        ? "1px solid #E1E3EA"
                        : "none",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "40px !important" }}>
                    <Checkbox
                      edge="end"
                      checkedIcon={
                        <img
                          src={checked}
                          alt="checked"
                          style={{ width: 16 }}
                        />
                      }
                      icon={
                        <img
                          src={unchecked}
                          alt="unchecked"
                          style={{ width: 16 }}
                        />
                      }
                      checked={selectedListings.includes(listing.Id)}
                      onChange={() => handleToggle(listing.Id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </ListItemIcon>
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={"8px"}
                  >
                    <Stack>
                      <img
                        style={{ width: "48px", height: "48px" }}
                        src={placeholder}
                        alt="listing"
                      />
                    </Stack>
                    <Stack gap={"4px"}>
                      <p className="listingTitle"> {listing.listingName}</p>
                      <p className="listingSubtitle">
                        <img
                          style={{
                            width: "12px",
                            paddingTop: "1px",
                            height: "12px",
                            marginRight: "4px",
                          }}
                          src={locationPin}
                          alt="location"
                        />
                        {listing.city}
                      </p>
                    </Stack>
                  </Stack>
                </ListItem>
              ))
            ) : (
              <Stack
                alignContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                py={isMobile ? 3 : "50%"}
              >
                <p className="listingTitle"> No results found...</p>
              </Stack>
            )}
          </List>
        </Box>

        <Box
          sx={{
            ...styles.innerModalContainer,
            border: isMobile
              ? "none"
              : "1px solid var(--Gray-Gray-300, #E1E3EA)",
            padding: isMobile ? "0px" : "12px",
            height: "100%",

            overflow: "hidden",
            maxHeight: "calc(100vh - 200px)",
          }}
        >
          <h3 className="radioButtonDescription">
            Selected Listings (
            {(userProperties?.length || 0) + selectedListings.length})
          </h3>
          <List
            sx={{
              overflowY: "auto",
              maxHeight: isMobile ? "100%" : "420px",
              height: "100%",
              minWidth: isMobile ? "100%" : "370px",
              pb: "20px",
            }}
          >
            {userProperties && userProperties.length > 0 && (
              <>
                {userProperties.map((property) => (
                  <ListItem
                    key={property.propertyId}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px 0",
                      borderBottom: "1px solid #E1E3EA",
                    }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <img
                        style={{ width: "48px", height: "48px" }}
                        src={placeholder}
                        alt="property"
                      />
                      <Stack>
                        <p className="listingTitle">{property.name}</p>
                        <p className="listingSubtitle">
                          <img
                            src={locationPin}
                            alt="location"
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "4px",
                            }}
                          />
                          {property.city}
                        </p>
                      </Stack>
                    </Stack>
                    <Button
                      onClick={() =>
                        onRemoveProperty &&
                        onRemoveProperty(property.propertyId)
                      }
                      sx={{ minWidth: "auto" }}
                    >
                      <img
                        src={lighter}
                        alt="remove"
                        style={{ width: 24, cursor: "pointer" }}
                      />
                    </Button>
                  </ListItem>
                ))}
              </>
            )}
            {selectedListings.length > 0 ? (
              listings
                .filter((listing: any) => selectedListings.includes(listing.Id))
                .map((listing: any) => (
                  <ListItem
                    key={listing.Id}
                    onClick={() => handleToggle(listing.Id)}
                    style={{
                      padding: "12px 0",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: isMobile ? "100%" : "370px",
                      minWidth: "370px",
                    }}
                  >
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                    >
                      <Stack>
                        <img
                          style={{ width: "48px", height: "48px" }}
                          src={placeholder}
                          alt="listing"
                        />
                      </Stack>
                      <Stack gap={"4px"}>
                        <p className="listingTitle"> {listing.listingName}</p>
                        <p className="listingSubtitle">
                          <img
                            style={{
                              width: "12px",
                              paddingTop: "1px",
                              height: "12px",
                              marginRight: "4px",
                            }}
                            src={locationPin}
                            alt="location"
                          />
                          {listing.city}
                        </p>
                      </Stack>
                    </Stack>
                    <ListItemIcon sx={{ minWidth: "40px !important" }}>
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemove(listing.Id);
                        }}
                        src={lighter}
                        alt="remove"
                        style={{ width: 24, cursor: "pointer" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))
            ) : shouldShowNoListingsMessage() ? (
              <Stack
                alignContent={"center"}
                alignItems={"center"}
                alignSelf={"center"}
                py={isMobile ? 3 : "50%"}
              >
                <p className="listingTitle"> No listings selected...</p>
              </Stack>
            ) : null}
          </List>
        </Box>
      </Box>
      <Stack
        width={"100%"}
        justifyContent={isMobile ? undefined : "flex-end"}
        alignItems={isMobile ? undefined : "flex-end"}
      >
        <CustomButton
          backgroundColor="#0065E0"
          color="#FFFFFF"
          buttonText={`Apply for ${(userProperties?.length || 0) + selectedListings.length
            } properties`}
          marginTop={0}
          onClick={() => {
            handleAddProperty(selectedListings);
          }}
        // disable={selectedListings.length === 0}
        />
      </Stack>
    </div>
  );
}

export default ListingModal;
