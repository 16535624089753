import { Button, Flex, Group, LoadingOverlay, Modal, MultiSelect, Paper, Progress, Text, Title, Tooltip } from '@mantine/core';
import { IconFileText, IconTag, IconUpload, IconX } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import Upload from "../../../assets/images/icons/upload.svg";
import './DocumentUploadComponent.scss'
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { BASE_API_URL } from '../../../api';
import { toast } from 'react-toastify';
import LoadingDots from '../loadings/LoadingDots';
import { getPropertyTags } from '../../../store/slices/propertySlice';
import { PropertyFile, Tag } from '../../../types';

interface UploadAttachModalProps {
  opened: boolean;
  close: () => void;
  handleSave: (files: Array<CustomFileStruct>) => void;
  existingFiles?: PropertyFile[]
  propertyId: string;
}

export interface ResponseFile { name: string, size: number };

export interface CustomFileStruct {
  id: string | number;
  file: File | ResponseFile
  tags: Tag[]
  exist?: boolean;
}


function formatFileSize(size: number | undefined): string {
  if (size === undefined) {
    return "Size unavailable";
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  return `${(size / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}


const DocumentUploadComponent = ({
  opened,
  close,
  handleSave,
  existingFiles = [],
  propertyId,
}: UploadAttachModalProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch()

  const localId = useRef(1)
  const uploadedFileCount = useRef(0)
  const currentNewTags = useRef<string[]>([])

  const propertyTags = useSelector((state: RootState) => state.property.propertyTags)

  const [files, setFiles] = useState<CustomFileStruct[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState<string[]>([])
  const [currentEditTag, setCurrentEditTag] = useState<number | string | null>(null)
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});


  const _handleFileUpload = (uploadFiles: File[]) => {
    const totalFiles: any = []
    uploadFiles.forEach(file => {
      const filePackage = {
        file: file,
        id: localId.current,
        tags: [],
      }
      totalFiles.push(filePackage)
      localId.current += 1
    })
    setFiles([...files, ...totalFiles])
    postUploadFileRequest([...files, ...totalFiles])
  }

  const _handleFileDelete = async (file: CustomFileStruct) => {
    //WARN: When we add file on local state we set id as number
    //if data comes from service we get as string this auto handles conditions
    if (uploadLoading) return ''
    if (typeof file.id === "string") {
      setDeleteLoading([...deleteLoading, file.id])
      await deleteFileRequest(file.id)
      setDeleteLoading(deleteLoading.filter(id => id !== file.id))
      setUploadProgress({})
    }
    uploadedFileCount.current -= 1
    setFiles(prevState => prevState.filter(stateFile => stateFile.id !== file.id))
  }

  const _handleToggleEditTag = (file: CustomFileStruct) => {
    if (currentNewTags.current.length > 0) {
      postTagRequest(file)
    }
    if (currentEditTag === file.id) {
      setCurrentEditTag(null)
    } else {
      setCurrentEditTag(file.id)
    }
  }

  const _handleTagChange = (value: string[], index: number) => {
    const newTags = value.filter(tagName => !files[index].tags.map(tag => tag.tag).includes(tagName));
    if (newTags) {
      currentNewTags.current = newTags
    }
    setFiles(prevState => {
      const updatedFiles = [...prevState]
      updatedFiles[index] = {
        ...prevState[index],
        tags: value.map(valueTag => ({ tag: valueTag }))
      }
      return updatedFiles
    })
  }

  const _handleRemoveTag = (tag: string, file: CustomFileStruct) => {
    const foundedTag = file.tags.find(t => t.tag === tag)
    if (foundedTag && foundedTag.id) {
      deleteTagRequest(foundedTag.id)
    }
  }

  const onCloseModal = () => {
    uploadedFileCount.current = 0
    currentNewTags.current = []
    setFiles([])
    handleSave(files)
    close()
  }

  const deleteFileRequest = async (id: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await axios.delete(`${BASE_API_URL}/Property/DeleteFile`,
        {
          data: { id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          }
        }
      )
      toast.success("File deleted successfully");
    } catch (error: any) {
      toast.error("File cannot deleted");
    }
  }

  const postUploadFileRequest = async (allFiles: CustomFileStruct[]) => {
    console.log(allFiles)
    if (!propertyId) {
      return;
    }

    if (allFiles.length === 0) {
      return;
    }

    try {
      setUploadLoading(true);
      const filesToUpload = allFiles.filter(file => !file.exist)

      const formData = new FormData();
      formData.append("PropertyId", propertyId);
      let eachFileMap: { [key: number]: number } = {};

      filesToUpload.forEach((file: CustomFileStruct, index: number) => {
        formData.append("files", file.file as File);
        formData.append("typeCode", "PROPERTY_DOCUMENT")
        formData.append("displayPriority", index.toString());
        formData.append("caption", file.file.name);
        formData.append("type", (file.file as File).type);
        formData.append("size", file.file.size.toString());
        eachFileMap[index] = 0
      })

      setUploadProgress(eachFileMap)
      const API_BASE_URL = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();


      const prevFiles = allFiles.filter(file => file.exist)
      let index = prevFiles.length

      const response = await axios.post(
        `${API_BASE_URL}/Property/UploadMultiPart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: () => {
            setUploadProgress((prev) => ({
              ...prev,
              [index]: 100,
            }));
            index += 1;
          },
        }
      );

      response.data.forEach((obj: any, innerIndex: number) => {
        if (filesToUpload.length > 0) {
          filesToUpload[innerIndex] = { ...filesToUpload[innerIndex], id: obj.id, exist: true }
        }
      })

      localId.current = 1
      uploadedFileCount.current = filesToUpload.length

      setFiles([...prevFiles, ...filesToUpload])

      setUploadProgress({})
      setUploadLoading(false);
    } catch (error) {
      console.error("Upload error:", error);
      setUploadLoading(false)
    }
  };

  const deleteTagRequest = async (tagId: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(
      `${BASE_API_URL}/Tag/DeleteTagAssigment`,
      {
        params: { id: tagId },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    toast.success("Tags Removed");
  }

  const postTagRequest = async (file: CustomFileStruct) => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
      `${BASE_API_URL}/Tag/AddTagAssigment`,
      {
        relationId: file.id,
        relationType: "propertyfile",
        tags: currentNewTags.current
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    toast.success("Tags Updated");

    //NOTE: GET TAGS FROM FILE
    const index = files.findIndex(stateFile => stateFile.id === file.id)
    let currentFileTags = files[index].tags

    //NOTE: CHECK RESPONSES
    response.data.forEach((tag: Tag) => {
      const tagIndex = currentFileTags.findIndex(cft => cft.tag === tag.tag)
      if (tagIndex !== -1) {
        currentFileTags[tagIndex].id = tag.id
      }
    })

    //NOTE: SET FILES
    setFiles(prevFiles => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = { ...updatedFiles[index], tags: currentFileTags };
      return updatedFiles;
    });
  }

  useEffect(() => {
    if (existingFiles && existingFiles.length > 0) {
      const files: CustomFileStruct[] = []
      existingFiles.forEach(file => {
        if (file.id) {
          const fileObj = {
            id: file.id,
            file: { name: file.name, size: file.size ?? 0 },
            tags: file.tags,
            exist: true,
          }
          files.push(fileObj)
        }
      })
      uploadedFileCount.current = existingFiles.length
      setFiles(files)
    } else {
      setFiles([])
    }
  }, [existingFiles]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(getPropertyTags({ accessToken }))
      } catch (error) {
        console.error("Tags error:", error);
      }
    };
    fetchTags()
  }, [])


  const RenderDropzone = () => {
    return (
      <Dropzone
        onDrop={(uploadFiles) => _handleFileUpload(uploadFiles)}
        disabled={uploadLoading}
        maxSize={50 * 1024 ** 2}
        accept={[...PDF_MIME_TYPE, ...IMAGE_MIME_TYPE]}
      >
        <Group justify="center" gap="xl" mih={160} style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload size={52} color="var(--mantine-color-blue-6)" stroke={1.5} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={52} color="var(--mantine-color-red-6)" stroke={1.5} />
          </Dropzone.Reject>
          <Flex
            direction="column"
            align="center"
            gap={10}
          >
            <div className='upload-image-wrapper'>
              <img
                src={Upload}
                alt="upload-icon"
                width={42}
                height={42}
              />
            </div>
            <Text fw={500} size="xl" inline>
              Upload document for property
            </Text>
            <Text size="sm" c="dimmed" inline fw={500}>
              Drag or drop your files here or <Text span c="blue.5" style={{ cursor: "pointer" }}>choose files</Text>
            </Text>
          </Flex>
        </Group>
      </Dropzone>
    )
  }

  const tags = propertyTags.map(tag => ({ value: tag.tag, label: tag.tag }))

  return (
    <Modal
      className="document-upload-modal-content"
      title={
        <Flex direction="column">
          <Title order={4}>Upload and attach files</Title>
          <Text size="sm" c="dimmed">Supported format: pdf</Text>
        </Flex>
      }
      closeOnClickOutside={!uploadLoading}
      closeOnEscape={!uploadLoading}
      opened={opened}
      onClose={onCloseModal}
      centered size="lg"
    >
      <div className="dropzone-outline" >
        <RenderDropzone />
      </div>
      <div className="files-box">
        {files.map((item: CustomFileStruct, index: number) => {
          const progressBarCount = uploadProgress[index] ?? 0
          const renderDeleteLoading = deleteLoading.find(id => id === item.id)

          return (
            <Paper withBorder pos="relative" p={10} radius="lg" key={item.id}>
              <LoadingOverlay visible={!!renderDeleteLoading} loaderProps={{ children: <LoadingDots /> }} />
              <Flex direction="column" gap={15}>
                <Flex gap={5} pos="relative" align="center">
                  <IconFileText color='#343434' size={45} stroke="1" />
                  <Flex direction="column">
                    <Text size="lg" c="gray.8">{item.file.name}</Text>
                    <Text c="dimmed" size="14px">{formatFileSize(item.file.size)}</Text>
                  </Flex>
                  <IconX
                    className="file-box-close-icon"
                    color='gray'
                    onClick={() => { _handleFileDelete(item) }}
                  />
                </Flex>
                {!item.exist &&
                  <Progress.Root size={20} transitionDuration={200}>
                    <Progress.Section value={progressBarCount} color="blue.4">
                      <Progress.Label>
                        {progressBarCount === 100 ? "Upload Complete" : "Uploading..."}
                      </Progress.Label>
                    </Progress.Section>
                  </Progress.Root>
                }
                <Flex gap={20} pl={5}>
                  <Flex gap={5} c="gray.7" align="center">
                    <IconTag />
                    Tags
                  </Flex>
                  <Tooltip disabled={item.exist} label="Upload your files to enable tag selection.">
                    <MultiSelect
                      flex={1}
                      disabled={!item.exist}
                      placeholder="Select Tag"
                      data={tags}
                      variant="default"
                      value={item.tags.map(tag => tag.tag)}
                      onChange={(value: string[]) => { _handleTagChange(value, index) }}
                      onRemove={(value) => { _handleRemoveTag(value, item) }}
                      nothingFoundMessage="No Tag Found"
                      onBlur={() => { _handleToggleEditTag(item) }}
                      searchable
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            </Paper>
          )
        })}
      </div>
      <Flex justify="flex-end" gap={10} pt={10}>
        <Button disabled={uploadLoading} variant="outline" color="gray.7" onClick={() => { onCloseModal() }}>Close</Button>
        <Button
          onClick={() => { onCloseModal() }}
          loading={uploadLoading}
          disabled={uploadLoading}
        >
          Save
        </Button>
      </Flex>
    </Modal>
  )

}

export default DocumentUploadComponent;
