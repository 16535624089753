import axios from "axios";
import { BASE_API_URL } from ".";

interface UserData {
  id?: string;
  customerId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  providerCode?: string;
  channelId?: string;
  isActive?: boolean;
  group?: string;
  propertyIds?: string[];
  tags?: string[];
  roles?: string[];
}

// Add new user
export const addUserApi = async (accessToken: string, userData: UserData) => {
  try {
    const response = await axios.post(`${BASE_API_URL}/User/Add`, userData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to add user");
  }
};

// Update user
export const updateUserApi = async (
  accessToken: string,
  userData: Partial<UserData>
) => {
  try {

    if (!userData?.firstName && !userData.lastName && !userData.phoneNumber) {
      throw new Error("User not found");
    }

    const updatedUserData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNumber: userData.phoneNumber
    };

    const response = await axios.post(
      `${BASE_API_URL}/User/Update`,
      updatedUserData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Update user error:", error);
    throw new Error(error.response?.data?.message || "Failed to update user");
  }
};

// Get users
export const getUsersApi = async (accessToken: string) => {
  try {
    const response = await axios.get<{
      items: UserData[];
      totalCount: number;
      pageSize: number;
      pageIndex: number;
    }>(`${BASE_API_URL}/User/Get`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to get users");
  }
};

// Add property to user
export const addPropertyToUserApi = async (
  accessToken: string,
  userId: string,
  propertyId: string
) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/User/AddProperty`,
      {
        userId,
        propertyId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to add property to user"
    );
  }
};

// Delete property from user
export const deletePropertyFromUserApi = async (
  accessToken: string,
  userId: string,
  propertyId: string
) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}/User/DeleteProperty`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        userId,
        propertyId,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to delete property from user"
    );
  }
};

// Get user properties
export const getUserPropertiesApi = async (
  accessToken: string,
  userId: string
) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/User/GetProperties`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { userId },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to get user properties"
    );
  }
};

// Invite user
export const inviteUserApi = async (accessToken: string, email: string) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/User/Invite`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { email },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to invite user");
  }
};

// Assign user
export const assignUserApi = async (
  accessToken: string,
  userId: string,
  targetUserId: string
) => {
  try {
    const response = await axios.get(`${BASE_API_URL}/User/AssignUser`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        userId,
        targetUserId,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to assign user");
  }
};
