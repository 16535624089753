import avatar from "../../../../assets/images/avatar2.jpeg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import StatusEllipseSvg from "../../../../assets/images/icons/StatusEllipseSvg";
import Call from "../../../../assets/images/icons/call-icon.svg";
import { parsePhoneNumber, CountryCode } from "libphonenumber-js";
import { ReservationDetails } from "../../../../types";
import { Flex, Text, Avatar, Anchor, Button } from "@mantine/core";
import "./ReservationDetails.scss";
import {
  h_formatStatusText,
  h_getConfirmationColor,
  h_getCurrencySymbol,
} from "../../../../utils/helpers";
import ProviderImage from "../../../../components/shared-components/ProviderImage/ProviderImage";

const ReservationDetailCard = ({
  reservationData,
}: {
  reservationData?: ReservationDetails;
}) => {
  const { t } = useTranslation();
  //const callOptions = ["Phone Call", "Whatsapp Call", "Facetime Audio"];

  if (!reservationData) return <div></div>;

  const {
    propertyUrl,
    propertyImageUrl,
    status,
    customerOtaUrl,
    customerNameSurname,
    propertyName,
    roomName,
    checkInDate,
    checkOutDate,
    days,
    adults,
    children,
    infants,
    currency,
    amount,
    phone,
  } = reservationData;

  const sd_showPreApproveButton = status === "inquiry";

  // STATE DRIVEN
  const sd_details = [
    `${propertyName} : ${roomName}`,
    `${moment(checkInDate).format("MM/DD")} – ${moment(checkOutDate).format(
      "MM/DD"
    )} (${days + 1} ${t("inboxpage.nights")})`,
    `${adults + children + infants} ${t(
      "inboxpage.guests"
    )} · ${h_getCurrencySymbol(currency)} ${amount} `,
  ];

  const formatPhoneNumber = (phoneNumber: string): string => {
    try {
      const cleanNumber = phoneNumber.replace(/\D/g, "");

      let parsedNumber;

      try {
        parsedNumber = parsePhoneNumber(cleanNumber);
      } catch {
        const commonCountries: CountryCode[] = ["TR", "SA", "AE", "US", "GB"];

        for (const country of commonCountries) {
          try {
            parsedNumber = parsePhoneNumber(cleanNumber, country);
            if (parsedNumber?.isValid()) {
              break;
            }
          } catch {
            continue;
          }
        }
      }

      if (parsedNumber?.isValid()) {
        return parsedNumber.formatInternational();
      }

      if (cleanNumber.length === 10) {
        return `+ 90 ${cleanNumber.slice(0, 3)} ${cleanNumber.slice(
          3,
          6
        )} ${cleanNumber.slice(6, 8)} ${cleanNumber.slice(8)} `;
      }

      return phoneNumber;
    } catch (error) {
      console.error("Telefon numarası formatlanırken hata oluştu:", error);
      return phoneNumber;
    }
  };

  const RenderProviderImage = () => {
    const source = reservationData?.otaName ?? "";
    return <ProviderImage source={source} size={16} opacity={1} />;
  };

  return (
    <div className="flex-box column p-10 gap-10">
      <div className="detail-box">
        <Flex
          style={{ overflow: "hidden" }}
          flex={1}
          direction="column"
          align="flex-start"
          justify="center"
          gap={4}
        >
          <div className="flex-box a-center gap-5">
            <StatusEllipseSvg status={status} />
            <Text size="sm" c={h_getConfirmationColor(status)}>
              {h_formatStatusText(status)}
            </Text>
          </div>
          <Flex align="center" justify="center" gap={4}>
            {customerOtaUrl ? (
              <Anchor
                c="dark.6"
                target="_blank"
                href={customerOtaUrl || ""}
                underline="hover"
              >
                {customerNameSurname}
              </Anchor>
            ) : (
              <Text c="dark.2">{customerNameSurname}</Text>
            )}
            <RenderProviderImage />
          </Flex>
          {propertyUrl ? (
            <Anchor
              c="dark.8"
              target="_blank"
              href={propertyUrl || ""}
              underline="hover"
            >
              <Flex
                flex={1}
                direction="column"
                align="flex-start"
                gap={4}
                w="100%"
              >
                {sd_details.map((detail, index) => (
                  <Text
                    size="sm"
                    c="dimmed"
                    truncate="end"
                    key={index}
                    w="100%"
                  >
                    {detail}
                  </Text>
                ))}
              </Flex>
            </Anchor>
          ) : (
            <Flex
              flex={1}
              direction="column"
              align="flex-start"
              gap={4}
              w="100%"
            >
              {sd_details.map((detail, index) => (
                <Text size="sm" c="dimmed" truncate="end" key={index} w="100%">
                  {detail}
                </Text>
              ))}
            </Flex>
          )}
        </Flex>
        <div style={{ flexShrink: "1" }}>
          {propertyUrl ? (
            <Anchor target="_blank" href={propertyUrl || ""}>
              <Avatar size="lg" src={propertyImageUrl || avatar} />
            </Anchor>
          ) : (
            <Avatar size="lg" src={propertyImageUrl || avatar} />
          )}
        </div>
      </div>
      {phone && (
        <div className="detail-box phone">
          <div className="phone-number">
            <Text c="dark.6">Phone Number</Text>
            <Text c="dimmed" size="sm">
              {formatPhoneNumber(phone)}
            </Text>
          </div>
          <div className="flex-box a-center">
            <img src={Call} height="30px" alt="call-icon"></img>
            {/*<Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon variant="subtle" color="gray.6">
                  <IconChevronDown />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Call Options</Menu.Label>
                <Menu.Divider />
                {callOptions.map((option) => (
                  <Menu.Item key={option} >
                    {option}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>*/}
          </div>
        </div>
      )}
      {sd_showPreApproveButton && (
        <div className="detail-box">
          <Button
            fullWidth
            variant="outline"
            color="dark.7"
          //onClick={handleOpenApproveModal}
          >
            <Text size="md">Pre-approve</Text>
          </Button>
        </div>
      )}
      {/* <SuggestModal
        open={openSuggestModal}
        handleClose={handleCloseSuggestModal}
        handleSave={handleSaveSuggestModal}
      />
      <DeclineModal
        open={openDeclineModal}
        handleClose={handleCloseDeclineModal}
        handleSave={handleSaveDeclineModal}
      />
      <ApproveModal
        open={openApproveModal}
        handleClose={handleCloseApproveModal}
        handleSave={handleSaveApproveModal}
        bookingId={reservationData.id}
        onStatusUpdate={() => { }}
      //onStatusUpdate={handleStatusUpdate}
      />
      <SpecialOfferModal
        open={openSpecialOfferModal}
        handleClose={handleCloseSpecialOfferModal}
        handleSave={handleSaveSpecialOfferModal}
      />*/}
    </div>
  );
};

export default ReservationDetailCard;
