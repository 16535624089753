import { useState } from "react";
import { IconButton } from "@mui/material";
import AI from "../../../../../assets/images/icons/ai-icon.svg";
import edit from "../../../../../assets/images/icons/edit-icon.svg";
import send from "../../../../../assets/images/icons/send-icon.svg";
import walk from "../../../../../assets/images/icons/walking.svg";
import deleteIcon from "../../../../../assets/images/icons/deletegray.svg";
import { useAuth0 } from "@auth0/auth0-react";
import MessageFormatter from "./MessageFormatter";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { useDispatch } from "react-redux";
import { setInputMessage, clearInputMessage, postSendMessage, setActionNeededModal } from "../../../../../store/slices/chatSlice";
import "./AiGeneralStyles.scss"
import { Flex } from "@mantine/core";
import { updateMessageNeededPost } from "../../../../../store/slices/messageSlice";

const AiCard = () => {
  const { messageId } = useSelector((state: RootState) => state.chat);
  const { selectedMessage } = useSelector((state: RootState) => state.message);
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  //     const response = await axios.get<MessageNeedPostResponse>(
  //       `${API_BASE_URL}/Message/GetMessageNeedPost`,
  //       {
  //         params: { MessageId: messageId },
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );

  const messageNeedPost = selectedMessage?.messageNeedPost

  const _updateMessageStatus = async (status: "approved" | "declined") => {
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();

      await dispatch(updateMessageNeededPost({
        accessToken,
        messageDetails: {
          messageId,
          status
        }
      })
      )

      dispatch(clearInputMessage())

      if (status === "approved") {
        await dispatch(postSendMessage({
          accessToken,
          messageDetails: {
            propertyId: selectedMessage?.propertyId ?? "",
            messageId: messageId,
            message: messageNeedPost?.message ?? "",
            channelId: "",
            channel: "",
            messageType: "message",
            sender: "property",
            usedAI: true,
            isNeedAttention: false,
            editAImessage: true,
          }
        }))
      }

      // if (status === "declined") {
      //   setIsVisible(false);
      // }
    } catch (error) {
      console.error("Durum güncelleme hatası:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const _handleForwardMessage = () => _updateMessageStatus("approved");
  const _handleDelete = () => _updateMessageStatus("declined");

  // TODO: NEED TO SEND editAImessage true sending message
  const _handleEditClick = () => {
    dispatch(setInputMessage({ inputMessage: messageNeedPost?.message ?? "", messageId, editAImessage: true }))
  };

  //NOTE: ACTION NEEDED = an
  const an = messageNeedPost?.messageType !== "semiauto"


  return (
    <div className={`ai-suggestion-card ${an && "action-needed"}`} >
      <div className={`ai-image-box ${an && "action-needed"}`}>
        <img src={an ? walk : AI} alt="AI Icon" />
      </div>
      <Flex className="ai-scroll-box" gap={5} w="100%" direction="column">
        <p className="ai-title">
          {an ? "Action Needed" : "AI Response"}
          <span className="ai-date"></span>
        </p>

        <div className="ai-message-box" >
          <p className="ai-description" >
            <MessageFormatter text={messageNeedPost?.message || messageNeedPost?.messageAIKnows || ""} />
          </p>
        </div>

        <div className="ai-response-button-box" >
          {messageNeedPost?.messageType === "semiauto" ? (
            <>
              <button
                className="ai-response-button suggestion"
                onClick={_handleEditClick}
              >
                <img src={edit} alt="Edit Icon" />
                <span>
                  Edit Message
                </span>
              </button>
              <button
                className="ai-response-button suggestion"
                onClick={_handleForwardMessage}
                disabled={isLoading}
              >
                <img src={send} alt="Send Icon" />
                <span>
                  Love it! Forward Message
                </span>
              </button>
            </>
          ) : (
            <button
              className="ai-response-button action"
              onClick={() => { dispatch(setActionNeededModal(true)) }}
              disabled={isLoading}
            >
              <img src={walk} alt="Walk Icon" />
              Action Needed
            </button>
          )
          }
          <IconButton
            className="ai-response-button circle"
            onClick={_handleDelete}
            disabled={isLoading}
          >
            <img src={deleteIcon} alt="Delete Icon" />
          </IconButton>
        </div>
      </Flex>
    </div >
  );
};

export default AiCard;
