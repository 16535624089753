import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPropertiesApi, GetAllPropertiesParams, } from "../../api/propertyApi";
import { GenericMetaData, PropertyLite } from "../../types"
import { deleteAutopilotDataApi, DeleteAutopilotDataApiParams, getAllAutopiloDataApi, GetAutoPilotDataApiParams, postNewAutopilotDataApi, putUpdateAutopilotDataApi } from "../../api/autopilotApi";
import { RootState } from "../store";
import { toast } from "react-toastify";

export interface AutopilotForm {
  customerId?: string
  id?: string
  properties?: PropertyLite[]
  reservationStatus: []
  startTime: string
  endTime: string
  weekDays: string[],
  workingMode: null | string
  delay: null | string
  coolOff: null | string
}

export interface AutopilotState {
  properties: PropertyLite[],
  propertiesMetaData: GenericMetaData | null
  selectedListings: PropertyLite[]
  selectAll: boolean
  selectedPropertyId: string
  addedProperties: AutopilotForm[]
  propertiesLoading: boolean
  propertiesListLoading: boolean
  chatbotDeleteloading: boolean
  chatbotLoading: boolean
  currentForm: AutopilotForm
}

const initialState: AutopilotState = {
  properties: [],
  propertiesMetaData: null,
  selectedListings: [],
  selectAll: false,
  selectedPropertyId: "",
  addedProperties: [],
  propertiesLoading: false,
  propertiesListLoading: false,
  chatbotDeleteloading: false,
  chatbotLoading: false,
  currentForm: {
    reservationStatus: [],
    startTime: "10:00",
    endTime: "22:00",
    weekDays: [],
    workingMode: "",
    delay: "",
    coolOff: "",
  }
}

export const autopilotFetchAllProperties = createAsyncThunk(
  "autopilot/autopilotFetchAllProperties",
  async (params: GetAllPropertiesParams) => {
    try {
      const response = await getAllPropertiesApi(params)
      return response.data
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)

export const getAllAutopiloData = createAsyncThunk(
  "autopilot/getAllAutopiloData ",
  async (params: GetAutoPilotDataApiParams) => {
    try {
      const response = await getAllAutopiloDataApi(params)
      return response.data
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)

export const postNewAutopilotData = createAsyncThunk(
  "autopilot/postNewAutopilotData",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    const { autopilot, global } = getState() as RootState
    try {
      if (global.userDetails?.customerId) {
        const { delay, coolOff } = autopilot.currentForm
        if (delay && coolOff) {
          const autopilotData = {
            customerId: global.userDetails?.customerId,
            properties: autopilot.selectAll ? [] : autopilot.selectedListings.map(pro => pro.id),
            isActive: true,
            ...autopilot.currentForm,
            delay: +delay,
            coolOff: +coolOff,
          }
          const response = await postNewAutopilotDataApi({ accessToken, autopilotData })
          toast.success('AI agent created successfully!');
          return {
            ...autopilot.currentForm,
            properties: autopilot.selectedListings,
            id: response.data.id
          }
        }
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)



export const putUpdateAutopilotData = createAsyncThunk(
  "autopilot/putUpdateAutopilotData",
  async ({ accessToken, autopilotForm }: { accessToken: string, autopilotForm: AutopilotForm }, { getState }) => {
    const { autopilot, global } = getState() as RootState
    try {
      if (global.userDetails?.customerId && autopilot.selectedPropertyId) {

        const { delay, coolOff } = autopilot.currentForm

        if (delay && coolOff) {
          const autopilotData = {
            customerId: global.userDetails?.customerId,
            isActive: true,
            id: autopilot.selectedPropertyId,
            properties: autopilot.selectAll ? [] : autopilot.selectedListings.map(pro => pro.id),
            ...autopilotForm,
            delay: +delay,
            coolOff: +coolOff,
          }

          await putUpdateAutopilotDataApi({ accessToken, autopilotData })

          toast.success('AI agent updated successfully!');

          return {
            ...autopilot.currentForm,
            customerId: global.userDetails?.customerId,
            properties: autopilot.selectedListings,
            id: autopilot.selectedPropertyId
          }
        }
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)

export const deleteAutopilotData = createAsyncThunk(
  "autopilot/deleteAutopilotData",
  async (params: DeleteAutopilotDataApiParams) => {
    try {
      await deleteAutopilotDataApi(params)
      toast.success('AI agent deleted successfully!');
      return params.autopilotId
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)



const autopilotSlice = createSlice({
  name: 'autopilot',
  initialState,
  reducers: {
    handleSelectedListing: (state, action) => {
      state.selectedListings = action.payload
    },
    handleSetSelectAll: (state, action) => {
      state.selectAll = action.payload
    },
    handleSelectedPropertyId: (state, action) => {
      state.selectedPropertyId = action.payload
    },
    handleForm: (state, action) => {
      state.currentForm = action.payload
    },
    handleAddedProperties: (state, action) => {
      state.addedProperties = action.payload
    },
    handleChatbotLoading: (state, action) => {
      state.chatbotLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(autopilotFetchAllProperties.pending, (state, action) => {
        state.propertiesListLoading = true
      })
      .addCase(autopilotFetchAllProperties.rejected, (state, action) => {
        state.propertiesListLoading = false
      })
      .addCase(autopilotFetchAllProperties.fulfilled, (state, action) => {
        if (action.payload) {
          const { items, ...meta } = action.payload as any
          if (items) {
            //const apiData = items.filter((property: any) => property.status === true)
            const apiData: PropertyLite[] = items.map((property: any, _: number) => ({
              id: property.id,
              name: property.name,
              internalName: property.internalName,
              typeCode: property.typeCode,
              city: property.city,
              state: property.state,
              street: property.street,
              image: (property.images && property.images[0]?.url) || null,
            }))

            state.properties = [...state.properties, ...apiData]
            state.propertiesMetaData = meta
          }
          state.propertiesListLoading = false
        }
      })
      .addCase(getAllAutopiloData.pending, (state, action) => {
        state.propertiesLoading = true
      })
      .addCase(getAllAutopiloData.fulfilled, (state, action) => {
        state.addedProperties = action.payload
        state.propertiesLoading = false
      })
      .addCase(postNewAutopilotData.pending, (state) => {
        state.chatbotLoading = true
      })
      .addCase(postNewAutopilotData.fulfilled, (state, action) => {
        if (action.payload) {
          state.addedProperties = [...state.addedProperties, action.payload]
          state.chatbotLoading = false
        }
      })
      .addCase(putUpdateAutopilotData.pending, (state) => {
        state.chatbotLoading = true
      })
      .addCase(putUpdateAutopilotData.fulfilled, (state, action: any) => {
        const updatedAutopilotIndex = state.addedProperties.findIndex(autopilot => autopilot.id === action.payload?.id)
        const { properties, customerId, id } = action.payload
        if (updatedAutopilotIndex !== -1) {
          const updated: AutopilotForm = {
            ...state.currentForm,
            properties,
            customerId,
            id: id
          }
          state.addedProperties[updatedAutopilotIndex] = updated
        }
        state.chatbotLoading = false
      })
      .addCase(deleteAutopilotData.pending, (state) => {
        state.chatbotDeleteloading = true
      })
      .addCase(deleteAutopilotData.fulfilled, (state, action) => {
        if (action.payload) {
          const deletedAutopilotId = state.addedProperties.find(autopilot => autopilot.id === action.payload)
          state.addedProperties = state.addedProperties.filter(autopilot => autopilot !== deletedAutopilotId)
          state.chatbotDeleteloading = false
        }
      })
  }
})

export const {
  handleSelectedListing,
  handleSetSelectAll,
  handleSelectedPropertyId,
  handleForm,
  handleAddedProperties,
  handleChatbotLoading
} = autopilotSlice.actions


export default autopilotSlice.reducer;
