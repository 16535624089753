import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";

/* Onboarding Provider Thumbnail */
import HostawayGetStarted from "../../../../assets/images/onboarding/get-started-image-hostaway.svg";
import GuestyGetStarted from "../../../../assets/images/onboarding/get-started-image-guesty.svg";
import LodgifyGetStarted from "../../../../assets/images/onboarding/get-started-image-lodgify.svg";

/* Onboarding Hostaway Steps Image */
import HostawayStep1 from "../../../../assets/images/onboarding/hostaway/hostaway-step-1.png";
import HostawayStep2 from "../../../../assets/images/onboarding/hostaway/hostaway-step-2.png";
import HostawayStep3 from "../../../../assets/images/onboarding/hostaway/hostaway-step-3.png";

/* Onboarding Lodgify Steps Image */
import LodgifyStep1 from "../../../../assets/images/onboarding/lodgify/lodgify-step-1.png";
import LodgifyStep2 from "../../../../assets/images/onboarding/lodgify/lodgify-step-2.png";
import LodgifyStep3 from "../../../../assets/images/onboarding/lodgify/lodgify-step-3.png";

/* Onboarding Guesty Steps Image */
import GuestyStep1 from "../../../../assets/images/onboarding/guesty/guesty-step-1.png";
import GuestyStep2 from "../../../../assets/images/onboarding/guesty/guesty-step-2.png";

import ArrowDown from "../../../../assets/images/icons/arrowdown.svg";
import useConnectionAPI from "../../../../api/useConnection";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  setOnboardingData,
  setStepsComplete,
} from "../../../../store/slices/onboardSlice";
import { useDispatch } from "react-redux";
import { Button, Flex, Container, Title, Text, TextInput } from "@mantine/core";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useAuth0 } from "@auth0/auth0-react";
import { getUsersApi, updateUserApi } from "../../../../api/userApi";

interface ContentButtonStackProps {
  title: string;
  details: string;
  image: string;
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  borderBottom: boolean;
}

const providerImages = {
  Hostaway: HostawayGetStarted,
  Guesty: GuestyGetStarted,
  Lodgify: LodgifyGetStarted,
};

const ContentButtonStack: React.FC<ContentButtonStackProps> = ({
  title,
  details,
  image,
  showDetails,
  setShowDetails,
  borderBottom,
}) => (
  <Stack>
    <Stack
      direction={"row"}
      className="showContentButtonStack"
      borderBottom={borderBottom && !showDetails ? "1px solid #cbd5e0" : "none"}
      onClick={() => setShowDetails(!showDetails)}
    >
      <p className="B-18-28-600">{title}</p>
      <img
        src={ArrowDown}
        alt="arrow-down"
        style={{
          transition: "transform 0.3s",
          transform: showDetails ? "rotate(180deg)" : "none",
        }}
      />
    </Stack>
    {showDetails && (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        paddingBottom={"20px"}
        borderBottom={borderBottom ? "1px solid #cbd5e0" : "none"}
      >
        <p className="TextMdRegular">{details}</p>
        <img alt="get started" src={image} className="getStartedImage" />
      </Stack>
    )}
  </Stack>
);

interface ProviderStep {
  title: string;
  details: string;
  image: string;
}

const providerSteps: Record<string, ProviderStep[]> = {
  Hostaway: [
    {
      title: "Go to Hostaway Dashboard",
      details:
        "Go to your Hostaway dashboard to get your Account ID and API Key",
      image: HostawayStep1,
    },
    {
      title: "Generate API Key",
      details: "Go to API Settings section to generate your API key",
      image: HostawayStep2,
    },
    {
      title: "Enter Your Credentials",
      details: "Enter your Account ID and API Key in the fields above",
      image: HostawayStep3,
    },
  ],
  Guesty: [
    {
      title: "Find Botel on the Guesty marketplace",
      details:
        "If you can't find it press this link https://app.guesty.com/integrations/partners/botel",
      image: GuestyStep1,
    },
    {
      title: "Press connect and generate your secret keys",
      details: "",
      image: GuestyStep2,
    },
  ],
  Lodgify: [
    {
      title: "Access Lodgify Dashboard",
      details: "Navigate to your Lodgify dashboard settings",
      image: LodgifyStep1,
    },
    {
      title: "Locate API Settings",
      details: "Find the API section in your account settings",
      image: LodgifyStep2,
    },
    {
      title: "Generate API Key",
      details: "Generate and copy your API key to connect with Botel",
      image: LodgifyStep3,
    },
  ],
};

const GetStarted = ({ handleGetStarted }: { handleGetStarted: () => void }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [showPhoneInput, setShowPhoneInput] = useState(true);
  const userDetails = useSelector((state: RootState) => state.global.userDetails)

  const handlePhoneChange = (value: string) => {
    setPhone(value);
    setPhoneError(null);
  };

  const validatePhone = () => {
    if (!phone || phone.length < 5) {
      setPhoneError("Phone number is required");
      return false;
    }
    return true;
  };

  const STORAGE_KEYS = {
    Hostaway: "hostawayConnectionInfo",
    Guesty: "guestyTokenInfo",
    Airbnb: "airbnbConnectionInfo",
  };
  const CONNECTION_STATE_KEYS = {
    Hostaway: "hostawayConnectionState",
    Guesty: "guestyConnectionState",
    Airbnb: "airbnbConnectionState",
  };

  const { onboardingData } = useSelector((state: RootState) => state.onboard);
  const dispatch = useDispatch();
  const compStep = 3;
  const { mutate: connectChannel } = useConnectionAPI();
  const selectedProvider = onboardingData.channels[0];
  const isHostaway = selectedProvider === "Hostaway";
  const isAirbnb = selectedProvider === "Airbnb";
  const [tokenError, setTokenError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isConnected, setIsConnected] = useState(() => {
    const stateKey =
      CONNECTION_STATE_KEYS[
      selectedProvider as keyof typeof CONNECTION_STATE_KEYS
      ];
    const savedConnectionState = sessionStorage.getItem(stateKey);
    if (savedConnectionState) {
      dispatch(setStepsComplete({ key: compStep, status: true }));
      return true;
    } else return false;
  });

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setOnboardingData({
        ...onboardingData,
        getStarted: {
          ...onboardingData.getStarted,
          accountName: e.target.value,
        },
      })
    );
  };

  const handleClientIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setOnboardingData({
        ...onboardingData,
        getStarted: {
          ...onboardingData.getStarted,
          clientId: e.target.value,
        },
      })
    );
  };

  const handleClientSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setOnboardingData({
        ...onboardingData,
        getStarted: {
          ...onboardingData.getStarted,
          clientSecret: e.target.value,
        },
      })
    );
  };

  useEffect(() => {
    const storageKey =
      STORAGE_KEYS[selectedProvider as keyof typeof STORAGE_KEYS];

    const savedInfo = sessionStorage.getItem(storageKey);
    if (savedInfo) {
      const parsedInfo = JSON.parse(savedInfo);

      switch (selectedProvider) {
        case "Hostaway":
          dispatch(
            setOnboardingData({
              ...onboardingData,
              getStarted: {
                ...onboardingData.getStarted,
                accountName: parsedInfo.title || "",
                clientId: parsedInfo.clientId || "",
                clientSecret: parsedInfo.clientSecret || "",
              },
            })
          );
          break;

        case "Guesty":
          dispatch(
            setOnboardingData({
              ...onboardingData,
              getStarted: {
                ...onboardingData.getStarted,
                accountName: parsedInfo.title || "",
                clientSecret: parsedInfo.clientCode || "",
              },
            })
          );
          break;

        case "Lodgify":
          dispatch(
            setOnboardingData({
              ...onboardingData,
              getStarted: {
                ...onboardingData.getStarted,
                accountName: parsedInfo.title || "",
                clientSecret: parsedInfo.clientCode || "",
              },
            })
          );
          break;

        case "Airbnb":
          dispatch(
            setOnboardingData({
              ...onboardingData,
              getStarted: {
                ...onboardingData.getStarted,
                accountName: parsedInfo.title || "",
                clientSecret: parsedInfo.token || "",
              },
            })
          );
          break;
      }
    }
  }, [selectedProvider]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const userData = await getUsersApi(accessToken);
        if (userData?.items?.[0]?.phoneNumber) {
          // Adjust according to user API response structure
          setShowPhoneInput(false);
          setPhone(userData.items[0].phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [getAccessTokenSilently]);

  const handleSubmitForm = async () => {
    if (!validatePhone()) {
      return;
    }
    try {
      const accessToken = await getAccessTokenSilently();

      const userData = { firstName: userDetails?.given_name, lastName: userDetails?.family_name, phoneNumber: phone }
      await updateUserApi(accessToken, userData);


      const { accountName, clientId, clientSecret } = onboardingData.getStarted;

      let isValid = false;
      switch (selectedProvider) {
        case "Hostaway":
          isValid = !!(accountName && clientId && clientSecret);
          break;
        case "Guesty":
          isValid = !!(accountName && clientSecret);
          break;
        case "Lodgify":
          isValid = !!(accountName && clientSecret);
          break;
        default:
          isValid = false;
      }

      if (!isValid) {
        setTokenError("Please fill in all required fields");
        return;
      }

      setIsVerifying(true);

      try {
        let requestData;
        switch (selectedProvider) {
          case "Hostaway":
            requestData = {
              title: accountName,
              clientId: clientId,
              clientSecret: clientSecret,
              providerType: "Hostaway",
            };
            break;

          case "Lodgify":
            requestData = {
              title: accountName,
              clientSecret: clientSecret,
              providerType: "Lodgify",
            };
            break;

          case "Airbnb":
            requestData = {
              title: accountName,
              token: clientSecret,
              providerType: "Airbnb",
            };
            break;

          default:
            requestData = {
              title: accountName,
              token: clientSecret,
              providerType: selectedProvider,
            };
        }

        connectChannel(requestData, {
          onSuccess: (response) => {
            if (response && response.success) {
              console.log("Connection API success:", response.data);
              setTokenError(null);
              setIsConnected(true);

              let connectionInfo;
              switch (selectedProvider) {
                case "Hostaway":
                  connectionInfo = {
                    title: accountName,
                    clientId: clientId,
                    clientSecret: clientSecret,
                  };
                  break;

                case "Airbnb":
                  connectionInfo = {
                    title: accountName,
                    token: clientSecret,
                  };
                  break;

                case "Guesty":
                  connectionInfo = {
                    title: accountName,
                    clientId: response.data?.clientId,
                    clientCode: response.data?.clientSecret,
                  };
                  break;

                case "Lodgify":
                  connectionInfo = {
                    title: accountName,
                    clientId: response.data?.clientId,
                    clientCode: response.data?.clientSecret,
                  };
                  break;
              }

              const storageKey =
                STORAGE_KEYS[selectedProvider as keyof typeof STORAGE_KEYS];
              const stateKey =
                CONNECTION_STATE_KEYS[
                selectedProvider as keyof typeof CONNECTION_STATE_KEYS
                ];

              sessionStorage.setItem(
                storageKey,
                JSON.stringify(connectionInfo)
              );
              sessionStorage.setItem(stateKey, "true");

              handleGetStarted();
              dispatch(setStepsComplete({ key: compStep, status: false }));
            } else {
              dispatch(setStepsComplete({ key: compStep, status: false }));
            }
            setIsVerifying(false);
          },
          onError: (error) => {
            console.error("Connection API error:", error);
            dispatch(setStepsComplete({ key: compStep, status: false }));
            setIsVerifying(false);
            setIsConnected(false);
            const stateKey =
              CONNECTION_STATE_KEYS[
              selectedProvider as keyof typeof CONNECTION_STATE_KEYS
              ];
            sessionStorage.removeItem(stateKey);
          },
        });
      } catch (error) {
        console.error("Error in handleSubmitForm:", error);
        dispatch(setStepsComplete({ key: compStep, status: false }));
        setIsVerifying(false);
        setIsConnected(false);
        const stateKey =
          CONNECTION_STATE_KEYS[
          selectedProvider as keyof typeof CONNECTION_STATE_KEYS
          ];
        sessionStorage.removeItem(stateKey);
      }
    } catch (error) {
      console.error("Error updating phone:", error);
      setPhoneError("Failed to update phone number");
      return;
    }
  };

  const steps =
    providerSteps[selectedProvider as keyof typeof providerSteps] || [];

  const [stepsVisibility, setStepsVisibility] = useState<
    Record<number, boolean>
  >({});

  const handleStepVisibility = (index: number) => {
    setStepsVisibility((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderTokenLabel =
    (selectedProvider &&
      {
        Guesty: "Guesty Secret",
        Hostaway: "Hostaway API token",
        Lodgify: "Lodgify API key",
        Airbnb: "Airbnb API token",
      }[selectedProvider as keyof typeof providerSteps]) ||
    "API Token";

  return (
    <Container size="lg">
      <Flex direction="column" align={"center"} gap={30} w="100%">
        <Flex direction="column" align="center">
          <Title order={1} fw={600}>
            Great! Lets get started.
          </Title>
          <Text size="lg" fw={500} c="dimmed">
            Connect seamlessly with our platform by easily generating your
            credentials and adding your listings today.
          </Text>
        </Flex>
        <Flex px={20} gap={20} justify="center" w="100%">
          <img
            src={
              providerImages[selectedProvider as keyof typeof providerImages]
            }
            width="40%"
            alt={`${selectedProvider} get started`}
          />
          <Flex
            gap={20}
            miw={300}
            w="40%"
            mih={250}
            direction="column"
            align="center"
            ta="left"
            justify="space-evenly"
          >
            <TextInput
              w="100%"
              size="md"
              label="Please Enter your Company Name"
              value={onboardingData.getStarted.accountName}
              onChange={handleAccountNameChange}
              placeholder="Company Name"
            />

            {showPhoneInput && (
              <Flex direction={"column"} w="100%">
                <label>Please Enter your Company Phone</label>
                <PhoneInput
                  style={{ width: "100%" }}
                  defaultCountry="us"
                  value={phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: "100%",
                    height: "36px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    border: phoneError ? "1px solid red" : "1px solid #ced4da",
                  }}
                />
                {phoneError && (
                  <Text size="sm" c="red" mt={4}>
                    {phoneError}
                  </Text>
                )}
              </Flex>
            )}

            {selectedProvider === "Hostaway" && (
              <TextInput
                w="100%"
                size="md"
                label="Account ID"
                value={onboardingData.getStarted.clientId}
                onChange={handleClientIdChange}
                placeholder="12345"
              />
            )}
            <TextInput
              w="100%"
              size="md"
              label={`Please Enter your ${renderTokenLabel}`}
              value={onboardingData.getStarted.clientSecret}
              onChange={handleClientSecretChange}
              placeholder="xxx-xxx-xxx"
              error={tokenError}
            />
            <Button
              loading={isVerifying}
              variant="contained"
              color="primary"
              bg={isConnected ? "gray.3" : "blue.5"}
              onClick={handleSubmitForm}
              disabled={isVerifying || isConnected}
            >
              {isConnected ? "Connected" : "Verify Connection"}
            </Button>
          </Flex>
        </Flex>
        <p className="TextSmMedium" style={{ marginTop: "40px" }}>
          {isHostaway
            ? "If you don't have your API credentials, please reach out to us at help@botel.ai"
            : isAirbnb
              ? "If you don't have your Airbnb API token, please reach out to us at help@botel.ai"
              : "If you don't have your token. Follow these steps to get your token. If you need help, write to us at help@botel.ai"}
        </p>
        <Flex
          p="0 20px"
          style={{ borderRadius: "var(--mantine-radius-md)" }}
          miw={900}
          w="100%"
          bg="gray.1"
          direction="column"
        >
          {steps.map((step, index) => (
            <ContentButtonStack
              key={index}
              title={step.title}
              details={step.details}
              image={step.image}
              showDetails={!!stepsVisibility[index]}
              setShowDetails={() => handleStepVisibility(index)}
              borderBottom={index !== steps.length - 1}
            />
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default GetStarted;
