import { Flex, Title, Text, Button } from "@mantine/core";
import { useDispatch } from "react-redux";
import ArrowsRight from "../../../../assets/images/icons/arrows-right.svg";
import OnboardingImage from "../../../../assets/images/Mobile login-pana 1.svg";
import { setCurrentStep } from "../../../../store/slices/onboardSlice";

const WelcomePage = () => {
  const dispatch = useDispatch()
  return (
    <Flex direction="column" justify="center" gap={20} align="center" h="calc(100vh - (87px + 97px))">
      <Flex direction="column" align="center">
        <Title order={1} fw={600} >Connect Your Property Listings</Title>
        <Text size="lg" fw={500} c="dimmed">
          Seamlessly integrate with your existing property management systems.
        </Text>
      </Flex>
      <img alt="onboard" className="onboardingImage" src={OnboardingImage} />
      <Button rightSection={<img src={ArrowsRight} alt="arrows" />} onClick={() => { dispatch(setCurrentStep(2)) }} >
        <Text>Start Now</Text>
      </Button>
    </Flex>
  );
}

export default WelcomePage;
