import { useState, useEffect } from "react";
import iconbutton from "../../../assets/images/IconButton.svg";
import { useAuth0 } from "@auth0/auth0-react";
import ListPropertyModal from "../ListPropertyModal/ListPropertyModal";
import { Divider, Select, Flex, MultiSelect, Button, Text, UnstyledButton, LoadingOverlay, Tooltip } from "@mantine/core";
import { useForm } from "@mantine/form";
import "./ChatBot.scss"
import { IconCalendar, IconClock, IconPlus, IconRefresh } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { AutopilotForm, deleteAutopilotData, getAllAutopiloData, handleForm, handleSelectedListing, handleSelectedPropertyId, postNewAutopilotData, putUpdateAutopilotData } from "../../../store/slices/autopilotSlice";
import { useSelector } from "react-redux";
import LoadingDots from "../loadings/LoadingDots";

const aiTimeOptions: { value: string, label: string }[] = [
  { value: "1", label: "1 minutes" },
  { value: "5", label: "5 minutes" },
  { value: "10", label: "10 minutes" },
  { value: "15", label: "15 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "60", label: "1 hour" },
]

const timeOptions: { value: string, label: string }[] = [
  { value: "00:00", label: "00:00" },
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
];

const reservationStatusOptions = [
  { value: "expired", label: "Expired" },
  { value: "inquiry", label: "Inquiry" },
  { value: "follow_up", label: "Follow up" },
  { value: "confirmed", label: "Confirmed" },
  { value: "post_stay", label: "Post Stay" },
  { value: "check_in_today", label: "Check in today" },
  { value: "check_in_tomorrow", label: "Check in tomorrow" },
  { value: "currently_hosting", label: "Currently hosting" },
  { value: "arriving_in_two_days", label: "Arriving in two days", },
  { value: "check_out_today", label: "Check out today" },
];
const workingModeOptions = [
  { value: "always_online", label: "7/24 Online" },
  { value: "custom", label: "Custom" },
];
const dayOptions = [
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
  { label: "Sunday", value: "7" },
];

const initialValues: AutopilotForm = {
  reservationStatus: [],
  startTime: "10:00",
  endTime: "22:00",
  weekDays: [],
  workingMode: null,
  delay: null,
  coolOff: null,
};


const ChatBot = () => {

  const {
    addedProperties,
    selectedListings,
    selectedPropertyId,
    chatbotLoading,
    chatbotDeleteloading,
  } = useSelector((state: RootState) => state.autopilot)

  const userDetails = useSelector((state: RootState) => state.global.userDetails)

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<AutopilotForm>({
    mode: "controlled",
    initialValues,
    validate: {
      reservationStatus: (value) => (value.length !== 0 ? null : "Reservation status is required"),
      workingMode: (value) => (value ? null : "Working mode is required"),
      weekDays: (value, values) => (value.length === 0 && values.workingMode === "Custom" ? "Please select at least one working day"
        : null),
      delay: (value) => (value ? null : "Delay required"),
      coolOff: (value) => (value ? null : "CollOff required")
    },
  });

  const [openModal, setOpenModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();


  const _handleSelectButton = (autopilot: AutopilotForm) => {
    const {
      reservationStatus,
      startTime,
      endTime,
      weekDays,
      workingMode,
      delay,
      coolOff,
      properties
    } = autopilot

    if (delay && coolOff) {
      form.setValues({
        reservationStatus,
        startTime,
        endTime,
        weekDays,
        workingMode,
        delay: String(delay),
        coolOff: String(coolOff)
      })
    }

    if (properties) {
      dispatch(handleSelectedListing(properties))
    } else {
      dispatch(handleSelectedListing([]))
    }
    dispatch(handleSelectedPropertyId(autopilot.id))
  }

  const _handleSave = async () => {
    const accessToken = await getAccessTokenSilently();
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      dispatch(handleForm({ ...form.values }))
      if (selectedPropertyId) {
        dispatch(putUpdateAutopilotData({ accessToken, autopilotForm: { ...form.values } }))
      } else {
        dispatch(postNewAutopilotData({ accessToken }))
      }
      _handleReset()
    }
  }

  const _handleOpenModal = () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      dispatch(handleForm({ ...form.values }))
      setOpenModal(true);
    }
  };

  const _handleReset = () => {
    form.setValues(initialValues);
    dispatch(handleSelectedListing([]))
    dispatch(handleSelectedPropertyId(""));
  }

  const _handleCloseModal = () => setOpenModal(false);

  const deletePropertyAutopilot = async (id: string) => {
    const accessToken = await getAccessTokenSilently();
    dispatch(deleteAutopilotData({ accessToken, autopilotId: id }))
  }

  useEffect(() => {
    const fetchPilot = async () => {
      const accessToken = await getAccessTokenSilently();
      if (userDetails?.customerId) {
        dispatch(getAllAutopiloData({ accessToken, customerId: userDetails.customerId }))
      }
    }
    fetchPilot()
  }, [userDetails])

  const RenderAutopilotCard = ({ property }: { property: AutopilotForm }) => {
    const workingMode = workingModeOptions.find(mode => mode.value === property.workingMode)
    let reservationStatus = ""
    if (Array.isArray(property.reservationStatus)) {
      const foundReservations = reservationStatusOptions.filter((option: any) => property.reservationStatus.some((status: string) => status === option.value))
      if (foundReservations) {
        reservationStatus = foundReservations.map(item => item.label).join(", ")
      }
    }

    const weekDays = dayOptions.filter(day => property.weekDays.includes(day.value))?.map(dayValue => dayValue.label)
    return (
      <div key={property.id} className={`chat-bot-property-container ${selectedPropertyId === property.id && "active"} `} >
        <LoadingOverlay visible={!!chatbotDeleteloading} loaderProps={{ children: <LoadingDots /> }} />
        <div className="property-box tags">
          <img
            src={iconbutton}
            alt="icon"
          />
          <Text>{property.properties?.length || "All "} listings </Text>
          <div className="property-tag-box">
            <div className="property-tag">
              <Tooltip label={reservationStatus} >
                <Text truncate="end" >{reservationStatus}</Text>
              </Tooltip>
            </div>
            <div className="property-tag">
              <Text >{workingMode?.label}</Text>
            </div>
            {property.workingMode === "custom" && (
              <>
                <div className="property-tag">
                  <Text truncate="end">
                    {weekDays.length === 7 ? "All days" : weekDays.join(", ")}
                  </Text>
                </div>
                <div className="property-tag">
                  <Text>
                    {property.startTime}
                  </Text>
                </div>
                <div className="property-tag">
                  <Text >
                    {property.endTime}
                  </Text>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="chat-bot-property-buttons" >
          <UnstyledButton
            className="property-button"
            variant="transparent"
            onClick={() => {
              _handleSelectButton(property);
            }}
          >
            <Text fw={500} c="blue.5">
              Select
            </Text>
          </UnstyledButton>
          <UnstyledButton
            className="property-button"
            variant="transparent"
            onClick={() => { deletePropertyAutopilot(property.id ?? "") }} >
            <Text c="red.6" fw={500}>
              Delete
            </Text>
          </UnstyledButton>
        </div>
      </div>
    )
  }

  return (
    <div className="chat-bot-main">
      {addedProperties && addedProperties.map((property: AutopilotForm) => {
        return (
          <RenderAutopilotCard key={property.id} property={property} />
        )
      })}
      <form style={{ width: "100%" }}>
        <Flex justify="space-between">
          <Text size="xl" fw={600} >Edit auto-pilot settings</Text>
          <Button leftSection={<IconRefresh />} onClick={() => { _handleReset() }}> Reset Form </Button>
        </Flex>
        <Flex direction="column" gap={30} mt={30} w="100%">
          <MultiSelect
            size="md"
            w="100%"
            data={reservationStatusOptions}
            label="Reservation status"
            {...form.getInputProps("reservationStatus", { type: "input", })}
          />
          <Select
            size="md"
            w="100%"
            clearable
            allowDeselect
            data={workingModeOptions}
            label="Working Mode"
            {...form.getInputProps("workingMode", {
              type: "input",
            })}
          />
        </Flex>
        {form.values.workingMode === "custom" && (
          <Flex direction="column" gap={30} w="100%" mt={30}>
            <MultiSelect
              size="md"
              leftSectionPointerEvents="none"
              leftSection={<IconCalendar />}
              w="100%"
              data={dayOptions}
              multiple
              label="Working Day"
              {...form.getInputProps("weekDays")}
            />
            <Flex gap={30}>
              <Select
                leftSectionPointerEvents="none"
                clearable
                leftSection={<IconClock />}
                size="md"
                w="100%"
                data={timeOptions}
                label="Start Working Hour"
                {...form.getInputProps("startTime")}
              />
              <Select
                leftSectionPointerEvents="none"
                clearable
                leftSection={<IconClock />}
                size="md"
                w="100%"
                data={timeOptions}
                label="End Working Hour"
                {...form.getInputProps("endTime")}
              />
            </Flex>
          </Flex>
        )
        }
      </form>
      <Flex w="100%" align="center" justify="space-between">
        <Text >Choose listing responsible: {selectedListings?.length || "All Listings"}</Text>
        <Button
          size="sm"
          onClick={() => {
            _handleOpenModal();
          }}
          leftSection={<IconPlus />}
        >
          <Text>{selectedPropertyId ? "Update listing" : "Add Listing"}</Text>
        </Button>
      </Flex>
      <Flex direction="column" gap={20} >
        <p className="B-18-18-600">Autopilot cooloff</p>
        <div>
          <Text span >
            If you jump into a conversation with a guest to send a message,
            Botel will wait
          </Text>
          <span style={{ display: "inline-block", margin: "0 5px" }}>
            <Select
              clearable
              placeholder="..."
              miw="120px"
              w="min-content"
              data={aiTimeOptions}
              error={form.errors.delay ? true : false}
              {...form.getInputProps("coolOff")}
              styles={{
                error: {
                  display: 'none', // Completely hide the error message text
                },
              }}

            />
          </span>
          <Text span >
            after your last message before sending another message to that
            guest on autopilot.
          </Text>
        </div>

        <p className="B-18-18-600">Autopilot delay</p>
        <div>
          <Text span>
            Normally, autopilot responds to messages as soon as it receives it
            from the PMS and finishes processing them (can be up to 3
            minutes). If you want to add a delay on every message
          </Text>
          <Text mx={5} span display="inline-block">
            <Select
              clearable
              placeholder="..."
              miw="120px"
              w="min-content"
              data={aiTimeOptions}
              {...form.getInputProps("delay")}
              styles={{
                error: {
                  display: 'none', // Completely hide the error message text
                },
              }}

            />
          </Text>
          <Text span>
            after receiving a message to reply to it.
          </Text>
        </div>
      </Flex>
      <Flex justify="flex-end" w="100%">
        <Button loading={chatbotLoading} onClick={() => { _handleSave() }}> Save </Button>
      </Flex>
      <Divider />
      <ListPropertyModal open={openModal} handleClose={_handleCloseModal} />
    </div >
  );
}

export default ChatBot;
