import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Box,
  Chip,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ChatTextfield from "./components/ChatTextfield";
import { MessageItem } from "../../../../types";
import Chat from "./components/Chat";
import DateDivider from "./components/DateDivider";
import AiModal from "./components/AiModal";
import { useAuth0 } from "@auth0/auth0-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { fetchSingleMessage, selectMessageItemsPageInfo, setMobileOpen, togglePlayGround } from "../../../../store/slices/chatSlice";
import "./ChatCard.scss"
import LoadingDots from "../../../../components/shared-components/loadings/LoadingDots";
import AiCard from "./components/AiCard";
import { ActionIcon, Button, Flex, Select, Text, Tooltip } from "@mantine/core";
import { IconArchive, IconMail, IconMessage, IconSandbox } from "@tabler/icons-react";
import { getAssigness, putAssignUser, updateMessageHeader } from "../../../../store/slices/messageSlice";
import ProviderImage from "../../../../components/shared-components/ProviderImage/ProviderImage";

const ChatCard = React.memo(() => {

  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { t } = useTranslation();

  const [showEdit, setShowEdit] = useState(false);
  //const [openSnackbar, setOpen] = useState(false);

  const chatMessagesAreaRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const PAGE_SIZE = 10;
  const { getAccessTokenSilently } = useAuth0();

  const currentPage = useRef(0)

  const dispatch = useDispatch<AppDispatch>();
  // TODO: CHECK THIS

  const {
    messageId,
    singleMessageLoading,
    mobileMessageOpen,
    playgroundMode
  } = useSelector((state: RootState) => state.chat);

  const selectedMessage = useSelector((state: RootState) => state.message.selectedMessage, shallowEqual);
  const assigneUsers = useSelector((state: RootState) => state.message.assigneUsers, shallowEqual);

  const currentMessage = useSelector((state: RootState) => state.chat.messagesCache[messageId])

  let currentMessageItems: MessageItem[] = []

  if (currentMessage) {
    currentMessageItems = currentMessage.chatMessages
  }

  const { hasMore } = useSelector(
    (state: RootState) => selectMessageItemsPageInfo(state, messageId)
  );


  const handleScroll = useCallback(
    async (event: React.UIEvent<HTMLDivElement>) => {
      const element = event.currentTarget;
      const scrollThreshold = 50;

      if (isLoadingMore || singleMessageLoading || !hasMore) return;

      if (element.scrollTop <= scrollThreshold) {
        const previousScrollHeight = element.scrollHeight;
        try {
          setIsLoadingMore(true);

          const accessToken = await getAccessTokenSilently();
          currentPage.current = currentPage.current + 1;
          const params = {
            messageId,
            page: currentPage.current,
            limit: PAGE_SIZE,
            accessToken,
          }

          await dispatch(fetchSingleMessage(params)).unwrap();
        } catch (error) {
          console.error("Mesajlar yüklenirken hata oluştu:", error);
        } finally {
          setTimeout(() => {
            const newScrollHeight = element.scrollHeight;
            const additionalHeight = newScrollHeight - previousScrollHeight;
            element.scrollTop = additionalHeight;
            setIsLoadingMore(false);
          }, 0);
        }
      }
    },
    [
      isLoadingMore,
      hasMore,
      messageId,
      dispatch,
      getAccessTokenSilently,
    ]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (currentMessageItems) {
        const accessToken = await getAccessTokenSilently();
        dispatch(
          fetchSingleMessage({
            messageId,
            page: 1,
            limit: PAGE_SIZE,
            accessToken,
          })
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    currentPage.current = 0
  }, [messageId]);

  useEffect(() => {
    if (!isLoadingMore) {
      scrollToBottom()
    }
  }, [currentMessageItems, selectedMessage?.messageNeedPost])

  const messageGroups = useMemo(() => {
    if (!currentMessageItems || currentMessageItems.length === 0) {
      return {};
    }

    return currentMessageItems.reduce((acc, message) => {
      const date = new Date(message.createdAt).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {} as { [key: string]: MessageItem[] });
  }, [currentMessageItems]);

  const handleTimeRangeChange = () => {
    // setTimeRange(value);
  };

  const _handleSelectChange = async (selectedId: string) => {
    try {
      //const userId = nameToIdMap[selectedName];
      const user = assigneUsers.find(user => user.id === selectedId)
      if (!user) {
        console.error("Selected user ID not found");
        return;
      }

      const accessToken = await getAccessTokenSilently();
      const assignUserResponse: any = dispatch(putAssignUser({
        messageId: messageId,
        userId: user.id,
        accessToken
      }))

      if (assignUserResponse && assignUserResponse.status === 200) {
        await dispatch(
          fetchSingleMessage({
            messageId,
            page: 1,
            limit: PAGE_SIZE,
            accessToken,
          })
        );
        // setOpen(true);
      }
    } catch (error) {
      console.error("Error in handleSelectChange:", error);
      //setOpen(true);
    }
  };


  const scrollToBottom = () => {
    if (chatMessagesAreaRef.current) {
      chatMessagesAreaRef.current?.scrollTo({
        top: chatMessagesAreaRef.current.scrollHeight,
        behavior: "auto"
      });
    }
  };

  useEffect(() => {
    const fetchAssignees = async () => {
      // TODO: MOVE TO STATE
      try {
        const accessToken = await getAccessTokenSilently();
        await dispatch(getAssigness({ accessToken }))
      } catch (error) {
        console.error("Error fetching assignees:", error);
      }
    };
    fetchAssignees();
  }, []);


  //  const timeOptions = [
  //    "1 hour",
  //    "3 hours",
  //    "6 hours",
  //    "12 hours",
  //    "24 hours",
  //  ];


  const handleMarkUnread = async () => {
    //TODO: HANDLE MARK UNREAD
    const accessToken = await getAccessTokenSilently();
    await dispatch(updateMessageHeader({ accessToken, updateData: { id: messageId, isRead: false } })).unwrap();
  };

  const handleArchive = async () => {
    //TODO: HANDLE ARCHIVE
    const accessToken = await getAccessTokenSilently();
    await dispatch(
      updateMessageHeader({ accessToken, updateData: { id: messageId, isClosed: 1 } })
    ).unwrap();
  }

  // const handleMute = async (duration: number) => {
  // try {
  //   const accessToken = await getAccessTokenSilently();
  //   // const success = await updateMessageHeaderApi({
  //   //   accessToken: accessToken,
  //   //   updateData: {
  //   //     id: messageId,
  //   //     mute: duration,
  //   //   }
  //   // });
  //   const success = null

  //   if (success) {
  //     setMuteStatus(duration);
  //     setOpen(true);
  //     setTitle(
  //       duration === 0
  //         ? "Mesaj sessizden çıkarıldı"
  //         : "Mesaj sessize alındı"
  //     );
  //     handlePopoverClose();
  //   }
  // } catch (error) {
  //   console.error("Mute işlemi sırasında hata:", error);
  //   setOpen(true);
  //   setTitle("İşlem başarısız oldu");
  // }
  // };

  const HandleReservation = () => {
    const source = selectedMessage?.source || "";
    return <ProviderImage source={source} />;
  }

  const handleBackClick = () => {
    dispatch(setMobileOpen(false))
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, '', url.toString());
  }


  const sm = selectedMessage
  const shouldShowAiNeedOrSuggestion = sm && sm.messageNeedPost && ((sm.isNeedAttention && sm.messageNeedPost.messageType === "actionneeded") ||
    (sm.messageNeedPost.messageType === "semiauto"))

  const sd_assignees = assigneUsers.map(ass => ({ value: ass.id, label: ass.name }))
  const sd_assignedUser = assigneUsers.find(ass => ass.email === sm?.assignedUser)
  const sd_assigneValue = sd_assignees.find(ass => ass.value === sd_assignedUser?.id)

  if (!selectedMessage) return (
    <Box className="chat-card">
      <Box
        height="100%"
        display="flex"
        justifyItems="center"
        alignItems="center"
        color="var(--Gray-600, #475467)"
      >
        <Flex direction="column" align="center" gap={10}>
          <IconMessage color="gray" size={60} stroke="0.9" />
          <Text c="dimmed" fw={600} size="lg">Please Select Message </Text>
        </Flex>
      </Box>
    </Box >
  )

  return (
    <Box className={`chat-card ${mobileMessageOpen ? 'mobile' : ''}`}>
      {singleMessageLoading && currentPage.current === 0 ? (
        <LoadingDots />
      ) : (
        <>
          <Box className={`chat-header-box ${playgroundMode ? "playground" : ""}`} >
            <Box className="header-left">
              <Stack direction={"row"} spacing={1} alignItems="center">
                {isMobile && (
                  <IconButton
                    sx={{
                      margin: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    onClick={handleBackClick}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {!isMobile && (
                  <Box className="chat-customer-image-box">
                    {selectedMessage?.title && selectedMessage.title[0]}
                  </Box>
                )}
                <p className="chat-card-customer-name B-20-20-600">
                  {selectedMessage?.title}
                </p>
                <HandleReservation />
              </Stack>
            </Box>
            <div className="header-right" >
              <Select
                data={sd_assignees}
                placeholder="Assign agent"
                value={sd_assigneValue?.value ?? ""}
                onChange={(value) => { value && _handleSelectChange(value) }}
                variant="default"
                radius="sm"
              />
              <AiModal
                showEdit={showEdit}
                setShowEdit={setShowEdit}
              />

              <Tooltip onClick={handleMarkUnread} label="Mark as unread" radius="md">
                <ActionIcon variant="transparent" color="dark.9" size="md">
                  <IconMail size="md" stroke="1.4" />
                </ActionIcon>
              </Tooltip>

              {/*<Tooltip title={muteStatus === 0 ? "Mute" : "Unmute"}>
                    <IconButton
                      style={{ color: "#181C32" }}
                      aria-describedby={
                        muteStatus === 0 ? popoverId : undefined
                      }
                      onClick={(event) => {
                        if (muteStatus === 0) {
                          handleScheduleClick(event);
                        } else {
                          handleMute(0);
                        }
                      }}
                    >
                      {muteStatus === 0 ? (
                        <ScheduleIcon />
                      ) : (
                        <NotificationsOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Popover
                    sx={{
                      "& .MuiPopover-paper": {
                        borderRadius: "12px !important",
                      },
                      "& .MuiPaper-root": {
                        borderRadius: "12px !important",
                      },
                    }}
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box className="time-options-box">
                      {timeOptions.map((option) => {
                        const hours = parseInt(option.split(" ")[0]);
                        return (
                          <MenuItem
                            key={option}
                            onClick={() => {
                              handleMute(hours);
                              handlePopoverClose();
                            }}
                            className="B-14-20-400 time-options-menu-item"
                          >
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Box>
                  </Popover>*/}
              <Tooltip onClick={handleArchive} label="Move to archive" radius="md">
                <ActionIcon variant="transparent" color="dark.9" size="md">
                  <IconArchive size="md" stroke="1.4" />
                </ActionIcon>
              </Tooltip>

              {/*<SimpleSnackbar
                  setOpen={setOpen}
                  open={openSnackbar}
                  title={title}
                />*/}

              <Tooltip label="Playground" radius="md">
                <ActionIcon onClick={() => { dispatch(togglePlayGround(!playgroundMode)) }} variant="transparent" color={playgroundMode ? "blue.5" : "dark.9"} size="md">
                  <IconSandbox size="md" stroke="1.4" />
                </ActionIcon>
              </Tooltip>

              {isMobile && (
                <a href="tel:+1234567890">
                  <IconButton style={{ color: "#181C32" }}>
                    <CallOutlinedIcon />
                  </IconButton>
                </a>
              )}
            </div>
          </Box>

          <Box
            ref={chatMessagesAreaRef}
            className={`chat-card-messages-area ${playgroundMode ? "playground" : ""}`}
            onScroll={handleScroll}
          >
            {isLoadingMore && (
              <Box display="flex" width="100%" justifyContent="center" padding="10px">
                <CircularProgress size={20} />
              </Box>
            )}

            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              pt={"20px"}
            >
              <Chip
                className="last-message-chip"
                label={moment(selectedMessage?.lastMessageReceivedAt).format("DD MMM YYYY")}
              />
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              width={"100%"}
            >
              <span className="B-12-18-500">
                We analyze messages for safety, support, product enhancement,
                or other purposes.
                <Button variant="transparent" className="chat-learn-more-button" >
                  <span>Learn more</span>
                </Button>
              </span>
            </Stack>
            {/*TODO: MESSAGES HERE*/}
            {currentMessageItems && currentMessageItems.length > 0 ? (
              Object.keys(messageGroups).sort().map((day, index) => (
                <React.Fragment key={`${day}-${index}`}>
                  <DateDivider
                    date={moment(day).format("YYYY-MM-DD")}
                    onTimeRangeChange={handleTimeRangeChange}
                    chatMessagesAreaRef={chatMessagesAreaRef}
                    messageItemInComponent={currentMessageItems}
                  />
                  {messageGroups[day].sort((a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  ).map((message) => {
                    return (
                      <Chat
                        message={message}
                        key={`${message.id}-${message.createdAt}`}
                        showTime={true}
                      />
                    );
                  })}
                </React.Fragment>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <p className="B-14-14-600">
                  {t("inboxpage.letstartchatting")}
                </p>
              </Box>
            )}
            {shouldShowAiNeedOrSuggestion && <AiCard />}
          </Box>
          <ChatTextfield />
        </>
      )
      }
    </Box >
  );
}
);

export default ChatCard;
