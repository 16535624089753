import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Flex,
  Text,
  Badge,
  Divider,
  Card,
  Avatar,
} from "@mantine/core";
import {
  IconBrandWhatsapp,
  IconLinkPlus,
} from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../../../../../api";
import { RootState } from "../../../../../store/store";
import {
  getCustomChannelApi,
  ChannelEnum,
  CustomerChannelResponse,
} from "../../../../../api/customChannelApi";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

const WhatsappConnection = () => {
  const customerId = useSelector((state: RootState) => state.global.userDetails?.customerId);
  const { getAccessTokenSilently } = useAuth0();
  const [wabaId, setWabaId] = useState("");
  const [phoneNumberId, setPhoneNumberId] = useState("");
  const [code, setCode] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [whatsappDetail, setWhatsappDetail] = useState<CustomerChannelResponse | null>(null)

  const checkWhatsAppConnection = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getCustomChannelApi({
        accessToken,
        customerId: customerId || "",
        channel: ChannelEnum.Whatsapp,
      });
      setWhatsappDetail(response)
      setIsConnected(!!response.channelCode);
    } catch (error) {
      console.error("Error checking WhatsApp connection:", error);
      setIsConnected(false);
    }
  };

  const _fbLoginCallback = (response: any) => {
    if (response.authResponse) {
      setCode(response.authResponse.code);
    } else if (response.error) {
    }
  };

  const _postMetaCode = async () => {
    try {
      const payload = { customerId: customerId, WhatsAppToken: code, phoneNumber: phoneNumberId, wabaId: wabaId };
      const accessToken = getAccessTokenSilently();
      if (BASE_API_URL) {
        let no_api = BASE_API_URL.split("/");
        no_api?.pop();
        const api = no_api.join("/");
        await axios.post(
          `${api}/communication/WhatsApp/add-sender`,
          { ...payload },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Whatsapp connected successfully");
      }

    } catch (error) {
      toast.error("Whatsapp can not connect, some error happen");
      console.error("Connection error:", error);
    }
  };

  const _handleConnectWhatsapp = (type: "wba" | "twillo") => {
    if (type === "wba") {
      try {
        if (!window.FB) {
          console.error("Facebook SDK not loaded.");
          return;
        }

        window.FB.login(_fbLoginCallback, {
          config_id: process.env.REACT_APP_FB_CONFIG_ID,
          response_type: "code",
          override_default_response_type: true,
          extras: {
            sessionInfoVersion: 3,
            setup: {
              solutionID: process.env.REACT_APP_FB_SOLUTION_ID,
            },
            featureType: "",
            //sessionInfoVersion: "2",
          },
        });
      } catch (err: any) {
        console.log(err)
      }
    }
  };

  useEffect(() => {
    const loadFbSdk = () => {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src =
        "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v22.0";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
    };

    if (!window.FB) {
      loadFbSdk();
    }

    window.fbAsyncInit = function() {
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v22.0",
      });
    };

    const messageListener = (event: MessageEvent) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            const { waba_id, phone_number_id } = data.data;
            setWabaId(waba_id);
            setPhoneNumberId(phone_number_id);
          } else if (data.event === "CANCEL") {
            console.warn("User canceled at step:", data.data.current_step);
          } else if (data.event === "ERROR") {
            console.error("Error:", data.data.error_message);
          }
        }
      } catch {
        console.log("Non JSON Responses", event.data);
      }
    };

    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
      const script = document.getElementById("facebook-jssdk");
      if (script) document.body.removeChild(script);
      delete window.FB;
    };
  }, []);

  useEffect(() => {
    if (customerId) {
      checkWhatsAppConnection();
    }
  }, [customerId]);

  useEffect(() => {
    const checkAndPostMetaCode = () => {
      if (wabaId && code) {
        _postMetaCode();
        checkWhatsAppConnection(); // Recheck connection after posting meta code
      }// else {
      // const retryLimit = 5;
      // let retryCount = 0;
      // const interval = setInterval(() => {
      //   retryCount += 1;
      //   console.log("Retry count:", retryCount);
      //   if (wabaId && code) {
      //     _postMetaCode();
      //     checkWhatsAppConnection(); // Recheck connection after posting meta code
      //     clearInterval(interval);
      //   } else if (retryCount >= retryLimit) {
      //     console.log("Retry limit reached, not proceeding.");
      //     clearInterval(interval);
      //   }
      // }, 1000);
    };

    checkAndPostMetaCode();
  }, [wabaId, code]);

  return (
    <>
      <Flex
        className="container-settings"
        h="100%"
        direction="column"
        p={40}
        gap={20}
        align="start"
        justify="start"
      >
        <Flex direction="column" gap={0}>
          <Text size="xl" fw={700} mb={0} c={"gray.9"}>
            WhatsApp Connection
          </Text>
          <Text size="sm" fw={500} c={"gray.6"}>
            Access Whatsapp conversations in your Botel AI inbox
          </Text>
        </Flex>

        <Divider my="md" c={"gray.9"} w={"100%"} />

        <Flex direction="column" gap={0}>
          <Text size="lg" fw={500} mb={0} c={"gray.9"}>
            Your Account
          </Text>
          <Text size="xs" fw={500} c={"gray.6"}>
            Connect your Whatsapp business account through Meta Business.
          </Text>
        </Flex>

        {isConnected ? (
          <Card
            withBorder
            shadow="sm"
            padding="lg"
            w={"100%"}
            h={"auto"}
            radius="md"
          >
            <Flex direction="row" justify={"space-between"}>
              <Flex direction="row" gap={20} align={"center"}>
                <IconBrandWhatsapp size={34} color="green" />
                <Text size="sm" fw={700}>
                  Business Account ID : {whatsappDetail?.channelData?.Id}
                </Text>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Card
            withBorder
            shadow="sm"
            padding="lg"
            w={"100%"}
            h={"auto"}
            radius="md"
          >
            <Flex direction="row" justify={"space-between"}>
              <Flex direction="row" gap={20}>
                <IconBrandWhatsapp size={34} color="black" />
                <Flex direction="column" gap={0}>
                  <Text size="sm" fw={700}>
                    Business Account Connect
                  </Text>
                  <Text size="xs">Connection not yet</Text>
                </Flex>
              </Flex>
              {/* <Button>Connect Business Account</Button> */}
              <Button
                onClick={() => {
                  _handleConnectWhatsapp("wba");
                }}
                leftSection={<IconLinkPlus size={20} />}
              >
                Connect Business Account
              </Button>
            </Flex>
          </Card>
        )}

        {isConnected && (
          <>
            <Divider my="md" c={"gray.9"} w={"100%"} />

            <Flex direction="column" gap={20} w="100%">
              <Flex direction="column" gap={0}>
                <Text size="lg" fw={500} mb={0} c={"gray.9"}>
                  Phone Number
                </Text>
                <Text size="xs" fw={500} c={"gray.6"}>
                  Your Whatsapp phone number. Refresh to fetch the last status.
                </Text>
              </Flex>
              <Flex
                direction="row"
                w="100%"
                justify={"space-between"}
                align={"center"}
              >
                <Flex direction="row" w={"100%"} gap={20} align={"center"}>
                  <Avatar color="gray" radius="xl" size="lg">
                    {whatsappDetail?.channelData?.Verifiedname}
                  </Avatar>
                  <Text fw={500} c={"gray.7"} size="sm">
                    {`+ ${whatsappDetail?.channelId}`}
                  </Text>
                  <Badge variant="dot" color="green">
                    Number Verified
                  </Badge>
                </Flex>
                {/*<Menu shadow="md" width={200}>
                  <Menu.Target>
                    <ActionIcon
                      variant="default"
                      color="gray"
                      aria-label="Settings"
                      size="lg"
                    >
                      <IconAdjustments
                        style={{ width: "70%", height: "70%" }}
                        stroke={1.5}
                      />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => {
                        toggleConnection();
                      }}
                      color="red"
                      leftSection={<IconUnlink size={14} />}
                    >
                      Disconnect
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>*/}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default WhatsappConnection;
