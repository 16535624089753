import { useEffect } from "react";
import "./onboardingTwoStyles.scss";
import "./OnboardingFlow.scss"
import { LinearProgress } from "@mui/material";
import ConnectPropertyListing from "./step1/ConnectPropertyListing";
import GetStarted from "./step1/GetStarted";
import SuccessfullyImported from "./step1/SuccessfullyImported";
import ReviewImportedListings from "./step1/ReviewImportedListings";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch } from "react-redux";
import { flushOnboardState, initializeSignalR, postReservationDetails, setCurrentStep, setProgressCount } from "../../../store/slices/onboardSlice";
import { getNewRefreshToken } from "../../../store/slices/globalSlice";
import SyncProperties from "./step1/SyncProperties";
import WelcomePage from "./step1/WelcomePage";
import { getAllProperties, postPropertyDetails, postSelectedPropertyList } from "../../../store/slices/propertySlice";
import { Button, Text } from "@mantine/core";
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import Listing from "../listing/Listing";

const OnboardingFlow = () => {

  const {
    currentStep: step,
    stepsComplete,
    connectionStarted,
  } = useSelector((state: RootState) => state.onboard)

  const {
    accessToken
  } = useSelector((state: RootState) => state.global)
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const initializeData = async () => {
      const accessToken = await getAccessTokenSilently();
      await dispatch(initializeSignalR(accessToken));
    };

    if (!connectionStarted) {
      initializeData();
    }

  }, [connectionStarted]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("accessToken", token);
      } catch (error) {
        console.error("Token Error:", error);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);

  const handleRefreshToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await dispatch(getNewRefreshToken(accessToken)).unwrap()
    } catch (error) {
      console.error("Token Error", error);
      return null;
    }
  };

  const handleGetstarted = async () => {
    dispatch(setCurrentStep(step + 1))
    await handleRefreshToken();
    dispatch(getAllProperties({ accessToken, PageSize: "20", PageIndex: "1" }))
    dispatch(setProgressCount(10));
    try {
      dispatch(postPropertyDetails({ accessToken }))
      dispatch(setProgressCount(20));
    } catch (error) {
      throw error;
    }
  }

  const handleNext = async () => {
    try {
      if (step === 3) {
        handleGetstarted()
      } else if (step === 5) {
        dispatch(postReservationDetails({ accessToken }))
      } else if (step === 7) {
        dispatch(setCurrentStep(1))
        navigate("/");
        //dispatch(postSelectedPropertyList({ accessToken }))
      }

    } catch (error) {
      if (step < 6) {
        dispatch(setCurrentStep(step + 1))
      } else {
        dispatch(setCurrentStep(1))
        navigate("/");
      }
    }

    if (step < 6) {
      dispatch(setCurrentStep(step + 1))
    } else {
      dispatch(flushOnboardState())
      dispatch(setCurrentStep(1))
      navigate("/");
    }

  };

  const handleBack = () => {
    if (step > 1) {
      dispatch(setCurrentStep(step - 1))
    }
  };

  const barSx = {
    width: "calc(100% / 2)", // Changed from calc(100% / 3)
    height: "8px",
    flexShrink: "0",
    borderRadius: "5px",
    background: "#CCE3FD",
  }

  return (
    <>
      <div className="onboarding-main">
        <div className={`onboarding-wrapper ${step === 5 ? "no-m" : ""}`}>
          {step === 1 && <WelcomePage />}
          {step === 2 && <ConnectPropertyListing />}
          {step === 3 && <GetStarted handleGetStarted={handleGetstarted} />}
          {step === 4 && <SyncProperties />}
          {step === 5 && <Listing hasHeader />}
          {step === 6 && <SuccessfullyImported />}
        </div>
      </div>
      {step !== 1 && (
        <div className="onboarding-bottom-box" >
          <div className="onboarding-progress-bar">
            <LinearProgress
              sx={barSx}
              variant="determinate"
              value={step >= 4 ? 100 : ((step - 3) / 2) * 100}
            />
            <LinearProgress
              sx={barSx}
              variant="determinate"
              value={step >= 6 ? 100 : step > 4 ? ((step - 4) / 2) * 100 : 0}
            />
          </div>
          <div className="onboarding-bottom-buttons">
            <Button
              leftSection={<IconChevronsLeft />}
              onClick={handleBack}
              variant="outline"
              disabled={step < 2}
            >
              <Text>
                Back
              </Text>
            </Button>
            <Button
              rightSection={<IconChevronsRight />}
              onClick={handleNext}
              disabled={!stepsComplete[step]}
            >
              <Text>
                {step < 6 ? "Next" : "Finish and go playground"}
              </Text>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default OnboardingFlow;
