import React, { useCallback, useEffect, useState, } from "react";
import {
  IconButton,
} from "@mui/material";
import "./MessageCard.scss"
import { MessageHeader } from "../../../../types";
import { useTranslation } from "react-i18next";
import IncomeMessages from "./components/IncomeMessages";
import SearchIcon from "../../../../assets/images/icons/searchColored.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchSingleMessage,
  setMobileOpen,
  togglePlayGround,
  createChatCache,
} from "../../../../store/slices/chatSlice";
import SearchBar from "./components/SearchBar";
import { FilterType } from "../../../../api/messageApi";
import LoadingDots from "../../../../components/shared-components/loadings/LoadingDots";
import FilterDrawer from "./components/FilterDrawer";
import { clearReservationState, getAllReservationDetails } from "../../../../store/slices/reservationSlice";
import { debounce } from "../../../../utils/helpers";
import {
  getMessages,
  setSelectedMessage,
  initializeSignalR,
  updateMessageReadStatus,
  setFilterType,
} from "../../../../store/slices/messageSlice";


export interface AssigneeOption {
  label: string;
  value: string;
}

const MessagesCard: React.FC = () => {
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const {
    tabMessageCounts,
    messagesLoading,
    connectionStarted,
    filterType,
  } = useSelector((state: RootState) => state.message);

  const currentMetaData = useSelector((state: RootState) => state.message.messageMetaDataCache[filterType])
  const currentAllMessage = useSelector((state: RootState) => state.message.allMessagesCache[filterType])

  const { t } = useTranslation();
  const [selectedChip, setSelectedChip] = useState<string>("All");
  const { getAccessTokenSilently } = useAuth0();
  const PAGE_SIZE = 10;

  const _fetchOnFirstRender = async () => {
    const accessToken = await getAccessTokenSilently();
    dispatch(
      getMessages({
        page: 1,
        limit: 20,
        accessToken,
        filterType
      })
    );
    dispatch(
      getMessages({
        page: 1,
        limit: 20,
        accessToken,
        filterType: FilterType.NOT_REPLIED
      })
    );
    dispatch(
      getMessages({
        page: 1,
        limit: 20,
        accessToken,
        filterType: FilterType.ACTION_NEED
      })
    )
    dispatch(
      getMessages({
        page: 1,
        limit: 20,
        accessToken,
        filterType: FilterType.UNREAD
      })
    );;
  }



  //  const scrollToTop = () => {
  //    const messagesList = document.querySelector(".messages-income-area");
  //    if (messagesList) {
  //      messagesList.scrollTo({
  //        top: 0,
  //        behavior: "smooth",
  //      });
  //    }
  //  };

  const _handleChipClick = async (label: string) => {
    if (label === selectedChip) return '';
    try {
      setSelectedChip(label);



      let newFilterType: FilterType;
      switch (label) {
        case "Unread":
          newFilterType = FilterType.UNREAD;
          break;
        case "Not Replied":
          newFilterType = FilterType.NOT_REPLIED;
          break;
        case "Action Needed":
          newFilterType = FilterType.ACTION_NEED;
          break;
        case "All":
          newFilterType = FilterType.ALL;
          break;
        default:
          newFilterType = FilterType.NONE; // Default to NONE or another fallback
          break;
      }

      dispatch(setFilterType(newFilterType))

      // dispatch(
      //   getMessages({
      //     page: 1,
      //     limit: 20,
      //     accessToken,
      //     filterType: newFilterType
      //   })
      // );

    } catch (error) {
      console.error("Error handling chip click:", error);
    }
  }

  const _handleMessageClick = async (message: MessageHeader) => {
    try {
      dispatch(setMobileOpen(true))
      dispatch(togglePlayGround(false))

      dispatch(createChatCache(message.id))
      dispatch(setSelectedMessage(message))

      const accessToken = await getAccessTokenSilently();
      const startPoint = message.bookingCheckIn

      if (message.bookingId) {
        dispatch(getAllReservationDetails({ accessToken, startPoint, propertyId: message.propertyId }))
      } else {
        dispatch(clearReservationState())
      }

      // PERF: DONE
      dispatch(
        fetchSingleMessage({
          messageId: message.id,
          page: 1,
          limit: PAGE_SIZE,
          accessToken,
        })
      );

      if (!message.isRead) {
        dispatch(updateMessageReadStatus({
          messageId: message.id,
          accessToken,
        }))
      }

      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("inboxId", message.id);
    } catch (error) {
      console.error("Error handling message click:", error);
    }
  };


  const _debouncedHandleScroll = useCallback(
    debounce(async (scrollHeight: number, scrollTop: number, clientHeight: number) => {
      const scrollThreshold = 50;
      const isNearBottom = scrollHeight - (scrollTop + clientHeight) <= scrollThreshold;
      const pageIndex = currentMetaData?.pageIndex

      if (isNearBottom) {
        const accessToken = await getAccessTokenSilently();
        dispatch(
          getMessages({
            page: (pageIndex ?? 0) + 1,
            limit: 20,
            accessToken,
            filterType,
          })
        );
      }
    }, 300),
    [messagesLoading, currentAllMessage, currentMetaData, filterType]
  );

  //FIX: SCROLL HAS BUG ON FULLFILTER Mode, it gets data without filter
  const _handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const cmd = currentMetaData
    if (cmd && !messagesLoading && currentAllMessage && currentAllMessage.length >= 20) {
      if ((cmd.pageIndex && cmd.pageIndex) <= (cmd.totalPageCount && cmd.totalPageCount)) {
        const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
        _debouncedHandleScroll(scrollHeight, scrollTop, clientHeight);
      }
    }
  };

  const returnCorrectFilterCount = (filterTitle: string): string => {
    let count = 0
    switch (filterTitle) {
      case "Unread":
        count = tabMessageCounts.unread;
        break;
      case "Action Needed":
        count = tabMessageCounts.actionNeeded
        break;
      case "Not Replied":
        count = tabMessageCounts.notReplied
        break;
      default:
        return "0"
    }

    return count > 99 ? "+99" : String(count)
  }

  useEffect(() => {
    if (filterType === FilterType.FULL_FILTER) {
      setSelectedChip("All")
    }
  }, [filterType])


  useEffect(() => {
    const initializeData = async () => {
      const accessToken = await getAccessTokenSilently();
      if (!connectionStarted) {
        await dispatch(initializeSignalR(accessToken));
      }
    };
    initializeData();
    if (!currentMetaData) {
      _fetchOnFirstRender();
    } else {
      dispatch(setFilterType(FilterType.ALL))
    }
  }, []);



  const tabFilters = ["All", "Not Replied", "Action Needed", "Unread"]
  const sd_message = currentAllMessage ?? []


  return (
    <div className="messages-main-box">
      <div className="card-container">
        <div className="flex-box">
          {filterType === FilterType.SEARCH ? (
            <SearchBar />
          ) : (
            <>
              <p className="messages-card-header H-24-32-600">
                {t("inboxpage.allmessages")}
              </p>
              <div className="messages-card-settings-box" >
                <IconButton onClick={() => dispatch(setFilterType(FilterType.SEARCH))} className="icon-button">
                  <img src={SearchIcon} alt="search" />
                </IconButton>
                <FilterDrawer />
              </div>
            </>
          )}
        </div>

        <div className="messages-card-filter-box" >
          {/* FILTERS*/}
          {tabFilters.map((label, index) => (
            <div
              key={index}
              onClick={() => _handleChipClick(label)}
              className={`custom-chip ${selectedChip === label ? "active" : ""}`}
            >
              <span>
                {label}
              </span>
              {label !== "All" &&
                <div className={`chip-count ${selectedChip === label ? "active" : ""}`} >
                  <span>
                    {returnCorrectFilterCount(label)}
                  </span>
                </div>
              }
            </div >
          ))}
        </div>
      </div>
      {messagesLoading && sd_message.length === 0 ? <LoadingDots /> : sd_message.length === 0 ? (
        <div className="messages-card-message-not-found-box">
          <p className="B-14-14-600">
            Couldn't find any message
          </p>
        </div>
      ) : (
        <div className="messages-income-area" onScroll={_handleScroll} >
          {sd_message.map((message: MessageHeader, index: number) => {
            return (
              <IncomeMessages key={index} message={message} onMessageClick={_handleMessageClick} />
            );
          })}
          {messagesLoading && <div className="messages-card-loading-box"> <LoadingDots /> </div>}
        </div>
      )}
    </div >
  );
};

export default React.memo(MessagesCard);
