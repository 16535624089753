import { useEffect } from "react";
import "./inbox.scss";
import ReservationDetailsCard from "./reservationDetails/ReservationDetails";
import ChatCard from "./chat/ChatCard";
import MessagesCard from "./messages/MessagesCard";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";


const Inbox = () => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const userDetails = useSelector((state: RootState) => state.global.userDetails)

  useEffect(() => {
    if (user && user.email) {
      if (!localStorage.getItem("email")) {
        localStorage.setItem("email", user.email)
      }
    }
  }, [user])


  useEffect(() => {
    if (userDetails && !userDetails.customerId) {
      navigate("/onboarding")
    }
  }, [userDetails])

  return (
    <div className="inbox-main-box" >
      <MessagesCard />
      <ChatCard />
      <ReservationDetailsCard />
    </div>
  );
}

export default Inbox;
