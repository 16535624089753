import axios from "axios";
import { INTEGRATION_API_URL } from ".";

export interface PostReservationDetailsParams {
  accessToken: string;
  customerId: string
  selectedProviderType: number
}

export const postReservationDetailsApi = async ({ accessToken, customerId, selectedProviderType }: PostReservationDetailsParams) => {
  try {
    await axios.post(INTEGRATION_API_URL + "/Ingtegration/ReservationAllSync",
      {
        CustomerId: customerId,
        ProviderType: selectedProviderType,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to post reservation details ."
    );
  };


}

