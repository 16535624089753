import { Stack } from "@mui/material";
import CheckIcon from "../../../../assets/images/CheckboxChecked.svg";
import ImportedGif from "../../../../assets/images/ImportedGif.gif";

const importTypes = [
  "Documents",
  "Conversions",
  "Users",
  "Reviews",
  "Custom field",
];

const SuccessfullyImported = () => {


  return (
    <Stack alignItems={"center"} spacing={4}>
      <Stack>
        <img
          src={ImportedGif}
          alt="imported gif"
          style={{ width: "160px", height: "160px", alignSelf: "center" }}
        />
        <Stack spacing={1} textAlign={"left"} maxWidth={500}>
          <p className="TextXsSemibold">
            We’ve successfully imported your content!
          </p>
          <p className="TextSmRegular">
            Take a minutes to review and complete your content before publishing
            your listing and making it available for guests to book
          </p>
        </Stack>
      </Stack>
      <Stack
        borderRadius={2}
        spacing={"20px"}
        padding={3}
        maxWidth={500}
        width={"100%"}
        style={{ backgroundColor: "#F9F9F9", border: "1px solid #EBEBEB" }}
      >
        {importTypes.map((type) => (
          <Stack key={type} spacing={1} direction={"row"} alignItems={"center"}>
            <img src={CheckIcon} alt="check icon" />
            <p className="TextSmMedium" style={{ color: "#5E6278" }}>
              {type} were imported successfully
            </p>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default SuccessfullyImported;
