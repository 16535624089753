import axios from "axios";
import { BASE_API_URL } from ".";

export enum ChannelEnum {
  Airbnb = "airbnb",
  Booking = "booking",
  Vrbo = "vrbo",
  Whatsapp = "whatsapp",
}

export interface GetCustomChannelParams {
  accessToken: string;
  customerId: string;
  channel: ChannelEnum;
}

export interface CustomerChannelResponse {
  id: string
  channelCode: string
  channelData: any
  channelId: string
}

export const getCustomChannelApi = async ({ accessToken, customerId, channel }: GetCustomChannelParams): Promise<CustomerChannelResponse> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/Customer/GetCustomChannel`, {
      params: { CustomerId: customerId, Channel: channel },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const formatted = { ...response.data, channelData: JSON.parse(response.data.channelData) }
    return formatted;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch custom channel data."
    );
  }
};
