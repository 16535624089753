import { FilterType } from "../../api/messageApi";
import { MessageHeader } from "../../types";
import { ParsedMessage } from "../types/messageTypes";

export const rh_parseSignalRMessage = (rawMessage: string | any): ParsedMessage => {
  let parsedMessage: any;

  if (typeof rawMessage === "string") {
    parsedMessage = JSON.parse(rawMessage);
  } else {
    parsedMessage = rawMessage;
  }

  return {
    id: String(Date.now()),
    username: parsedMessage.UserName,
    messageId: parsedMessage.MessageId,
    sender: parsedMessage.Sender || "guest",
    message: parsedMessage.Message,
    messageType: parsedMessage.MessageType,
    createdAt: parsedMessage.CreatedAt || new Date().toISOString(),
    createdByName: parsedMessage.CreatedByName,
    source: parsedMessage.source || "manual",
  };
};

export const rh_isSignalMessageValid = (parsedMessage: ParsedMessage): boolean => {
  if (!parsedMessage.messageId) {
    console.error("RecieveMessage can not find message Id")
    return false;
  }


  //NOTE: IF MESSAGE COME WıTH CURRENT USER EMAIL DON'T ADD TO STATE 
  //CAUSE WEBSOCKET ALSO SEND OUR MESSAGE BACK TO US
  const userEmail = localStorage.getItem("email")
  if (parsedMessage.username && parsedMessage.username === userEmail) return false;

  return true
}


// Utility function to update message in all relevant caches
export const rh_updateMessageInAllCaches = (
  state: { [key in FilterType]: MessageHeader[] | [] },
  messageId: string,
  payload: any
) => {
  Object.keys(state).forEach((filterType) => {

    const ft = filterType as FilterType
    const index = state[ft].findIndex((msg: MessageHeader) => msg.id === messageId);

    if (index !== -1) {
      state[ft][index] = { ...state[ft][index], ...payload }
    }
  });
};


export const rh_isMoreThanTwoMonthsApart = (referenceDate: string, targetDate: string): boolean => {
  const refDate = new Date(referenceDate);
  const target = new Date(targetDate);

  // Calculate 2 months before and after
  const twoMonthsBefore = new Date(refDate);
  twoMonthsBefore.setMonth(refDate.getMonth() - 2);

  const twoMonthsAfter = new Date(refDate);
  twoMonthsAfter.setMonth(refDate.getMonth() + 2);

  return target < twoMonthsBefore || target > twoMonthsAfter;
};
