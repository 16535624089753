import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchSingleMessageApi,
  postSendMessageApi,
  PostSendMessageParams,
} from '../../api/messageApi';
import { RootState } from '../store';
import { ChatState, SetInputMessagePayload } from '../types';
import { ParsedMessage } from '../types/messageTypes';
import { addMessageToCache } from './messageSlice';



// HANDLE FILTER SIDE
const initialState: ChatState = {
  actionNeededModalOpen: false,
  singleMessageLoading: false,
  mobileMessageOpen: false,
  playgroundMode: false,
  messagesCache: {},
  chatMessagePages: {},
  messageId: '',
  messageSendLoading: "",
  error: null,
};
//ALL MESSAGES NEED TO BE OJBECt



export const fetchSingleMessage = createAsyncThunk("chat/fetchSingleMessage", async (
  params: { messageId: string; page: number; limit: number; accessToken: string },
  { rejectWithValue, getState }
) => {
  const { chat } = getState() as RootState
  const mCache = chat.messagesCache[params.messageId]
  if (!!mCache) {
    try {
      return await fetchSingleMessageApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
})

// POST
export const postSendMessage = createAsyncThunk("chat/postSendMessage", async (
  params: PostSendMessageParams,
  { rejectWithValue, dispatch }
) => {
  try {
    const response = await postSendMessageApi(params)
    dispatch(addMessageToCache(response))
    return response
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});


const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActionNeededModal: (state, action: PayloadAction<boolean>) => {
      state.actionNeededModalOpen = action.payload
    },
    toggleActionNeededModal: (state) => {
      state.actionNeededModalOpen = !state.actionNeededModalOpen
    },
    setInputMessage: (state, action: PayloadAction<SetInputMessagePayload>) => {
      if (action.payload.messageId) {
        state.messagesCache[action.payload.messageId].chatInput = action.payload.inputMessage;
      }
      if (action.payload.editAImessage) {
        state.messagesCache[action.payload.messageId].editAImessage = action.payload.editAImessage;
      }
    },
    clearInputMessage: (state) => {
      state.messagesCache[state.messageId].chatInput = ""
    },
    setMobileOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileMessageOpen = action.payload
    },
    togglePlayGround: (state, action: PayloadAction<boolean>) => {
      state.playgroundMode = action.payload
    },
    createChatCache: (state, action: PayloadAction<string>) => {
      const messageId = action.payload;

      if (!state.messagesCache[messageId]) {
        state.messagesCache[messageId] = {
          chatMessages: [],
          chatInput: "",
          editAImessage: false
        };
      }
      state.messageId = messageId
    },
    updateChatCache: (state, action: PayloadAction<ParsedMessage>) => {
      const message = action.payload

      if (!state.messagesCache[message.id]) {
        state.messagesCache[message.id] = {
          chatMessages: [],
          chatInput: "",
          editAImessage: false
        };
      }

      state.messagesCache[message.messageId].chatMessages.push(message)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleMessage.pending, (state, action) => {
        const params = action.meta.arg;
        if (state.messagesCache[params.messageId].chatMessages.length === 0) {
          state.singleMessageLoading = true;
          state.error = null;
        }
      })
      .addCase(fetchSingleMessage.fulfilled, (state, action) => {
        if (action.payload) {
          const { messageId, data, page, totalCount } = action.payload;

          if (!state.chatMessagePages[messageId]) {
            state.chatMessagePages[messageId] = {
              currentPage: 1,
              hasMore: true,
            };
          }

          if (data.items) {
            if (page === 1) {
              state.messagesCache[messageId].chatMessages = data.items
            } else {
              const newMessages = data.items;
              const existingMessages = state.messagesCache[messageId].chatMessages || [];

              const uniqueNewMessages = newMessages.filter(
                (newMsg: any) =>
                  !existingMessages.some(
                    (existingMsg) => existingMsg.id === newMsg.id
                  )
              );

              state.messagesCache[messageId].chatMessages = [
                ...uniqueNewMessages,
                ...existingMessages
              ]
            }

            state.chatMessagePages[messageId] = {
              currentPage: page,
              hasMore: (state.messagesCache[messageId].chatMessages?.length || 0) < totalCount,
            };
          }
          state.singleMessageLoading = false
        }
      })
      .addCase(fetchSingleMessage.rejected, (state, action) => {
        state.error = action.error.message || "Mesajlar yüklenirken bir hata oluştu";
        state.singleMessageLoading = false
      })
      //WARN: PUT
      .addCase(postSendMessage.pending, (state, action) => {
        const id = Math.floor(Math.random() * 1000000).toString();
        state.messageSendLoading = id;

        const params = action.meta.arg;

        const newMessage = {
          ...params.messageDetails,
          id,
          createdAt: new Date().toISOString(),
          createdByName: "You",
          source: "manual"
        };

        state.messagesCache[state.messageId].chatMessages.push(newMessage)
        state.messagesCache[state.messageId].chatInput = "";
        state.messagesCache[state.messageId].editAImessage = false
      })
      //POST
      .addCase(postSendMessage.fulfilled, (state) => {
        state.messageSendLoading = ""
      })
      .addCase(postSendMessage.rejected, (state) => {
        state.messageSendLoading = ""
      })
  },
});

export const {
  setInputMessage,
  setActionNeededModal,
  setMobileOpen,
  clearInputMessage,
  toggleActionNeededModal,
  togglePlayGround,
  createChatCache,
  updateChatCache,
} = chatSlice.actions;

export const selectMessageItemsPageInfo = (
  state: { chat: ChatState },
  messageId: string
) =>
  state.chat.chatMessagePages[messageId] || {
    currentPage: 1,
    hasMore: true,
  };


export default chatSlice.reducer;

