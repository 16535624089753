import React, { createContext, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

interface MessageContent {
  Message?: string;
  message?: string;
  title?: string;
  type?: string;
  MessageId?: string;
}

interface ToastContextType {
  showToast: (
    content: string | MessageContent,
    type?: "info" | "success" | "error" | "warning"
  ) => void;
}

export const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const showToast = (
    content: string | MessageContent,
    type: "info" | "success" | "error" | "warning" = "info"
  ) => {
    let message: string;
    let messageId: string | undefined;

    if (typeof content === "string") {
      if (content.trim().startsWith("{") && content.trim().endsWith("}")) {
        try {
          const parsedContent = JSON.parse(content) as MessageContent;
          message = parsedContent.Message || "";
          messageId = parsedContent.MessageId;
        } catch (e) {
          console.error("JSON not parsed", e);
          message = content;
        }
      } else {
        message = content;
      }
    } else {
      message = content.Message || content.title || "";
      messageId = content.MessageId;
    }

    if (!message) {
      message = "Notification";
    }

    toast[type](
      ({ closeToast }) => (
        <div
          onClick={() => {
            if (messageId) {
              navigate(`/inbox?inboxId=${messageId}`);
            } else {
              navigate("/inbox");
            }
            closeToast?.();
          }}
          className="cursor-pointer hover:bg-gray-50 rounded p-1"
        >
          {message}
        </div>
      ),
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        style: {
          zIndex: 9999,
          marginTop: "60px",
          cursor: "pointer",
        },
      }
    );
  };
  const contextValue: ToastContextType = {
    showToast,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <ToastContainer
        stacked={false}
        limit={1}
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
