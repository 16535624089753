import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "../../assets/images/Avatar.png";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/Logo.png";
import ArrowDownSvg from "../../assets/images/icons/linear-arrow-down.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrowBack from "../../assets/images/icons/arrow-left.svg";
import { inputHeaderStyles } from "./HeaderStyles";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { clearUserToken } from "../../store/slices/globalSlice";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { shallowEqual } from "react-redux";
import { flushOnboardState } from "../../store/slices/onboardSlice";
import { getUnreadCount } from "../../store/slices/messageSlice";
import { Badge, Button, Flex, Text } from "@mantine/core";

interface HeaderProps {
  isOnboarding: boolean;
  setIsOnBoarding: (isOnboarding: boolean) => void;
  unreadCount?: number;
}

const Header = ({ isOnboarding }: HeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const unreadCount = useSelector((state: RootState) => state.message.tabMessageCounts.unread, shallowEqual)
  const dispatch: AppDispatch = useDispatch();

  const currentEnv = process.env.REACT_APP_CURRENT_ENV
  const version = process.env.REACT_APP_VERSION


  const settings = [
    { name: `${t("header.profile")}`, route: "/profile" },
    { name: `${t("header.logout")}`, route: "/" },
  ];

  const pages = [
    // { name: `${t("header.today")}`, route: "/" },
    // { name: `${t("header.calendar")}`, route: "/calendar" },
    { name: `${t("header.listing")}`, route: "/listings" },
    {
      name: `${t("header.inbox")}`,
      route: "/inbox",
      badge: unreadCount === 0 ? "" : unreadCount > 99 ? "99+" : unreadCount,
    },
    { name: `${t("On Boarding")}`, route: "/onboarding" },
    { name: `${t("header.settings")}`, route: "/settings" },
  ];

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const fetchUnread = async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getUnreadCount({ accessToken }))
    }
    fetchUnread()
  }, [])

  const handleExitAndSave = () => {
    sessionStorage.clear();
    dispatch(flushOnboardState())
    navigate("/listings");
  };

  if (location.pathname.includes("verify")) return <></>

  return (
    <AppBar className={`appBar ${isOnboarding ? "onboarding" : ""}`} >
      <Toolbar disableGutters className="toolbar">
        <Flex py={16} align="center" gap={16} >
          <Link to="/inbox" >
            <img
              style={{
                cursor: "pointer",
              }}
              src={Logo}
              alt="Botel Logo"
              className="logo"
            />
          </Link>
          {version &&
            <Badge>
              {`v${version}`}
            </Badge>
          }
          {currentEnv === "dev" &&
            <Badge>
              DEV
            </Badge>
          }
        </Flex>
        {isOnboarding ? (
          <button onClick={handleExitAndSave} className="button-header-save-and-exit">
            <img src={arrowBack} alt="arrow-back" className="button-icon" />
            <span className="buttonText B-16-16-600">Exit & Save</span>
          </button>
        ) : (
          <>
            <Flex gap={5}>
              {pages.map((page) => (
                <Button
                  onClick={() => navigate(page.route)}
                  size="compact-sm"
                  color={location.pathname === page.route ? "blue.5" : "gray.8"}
                  variant="transparent"
                  key={page.name}
                >

                  <Flex align="center" gap={5}>
                    <Text fw={500}>{page.name}</Text>
                    {page.badge && page.badge > 0 &&
                      <Badge color="red" >
                        {page.badge}
                      </Badge>
                    }
                  </Flex>
                </Button>
              ))}
            </Flex>
            <Stack direction="row" justifyContent={"end"} spacing={1}>
              <Box className="userBox">
                <IconButton
                  onClick={handleOpenUserMenu}
                  className="iconButton"
                  disableRipple
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    src={user?.picture || Avatar}
                    alt="Avatar"
                    className="avatar"
                  />
                  <img
                    src={ArrowDownSvg}
                    alt="Arrow Down"
                    className="arrowDown"
                  />
                </IconButton>
                <Menu
                  sx={inputHeaderStyles.iconMenu}
                  PaperProps={{
                    className: "menuPaper",
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, index) => (
                    <MenuItem
                      key={setting.name}
                      onClick={() => {
                        if (setting.name === `${t("header.logout")}`) {
                          sessionStorage.clear();
                          localStorage.clear();
                          dispatch(clearUserToken())
                          handleCloseUserMenu();
                          logout({
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          });

                        } else {
                          navigate(setting.route);
                        }
                      }}
                      className="menuItem"
                      style={{
                        marginBottom:
                          index !== settings.length - 1 ? "24px" : "0px",
                        width: "120px",
                      }}
                      component={Link}
                      to={setting.route}
                    >
                      <Container className="menuArea">
                        <Typography className="B-20-30-500">
                          {setting.name}
                        </Typography>
                      </Container>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
