import React from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function AccountSettingsListBox() {
  const { t } = useTranslation();
  const location = useLocation();

  const links = [
    { to: "/settings/user-management", text: "User management" },
    // { to: "/settings/booking-booster", text: "Booking booster" },
    // { to: "/settings/retargeting", text: "Retargeting" },
    //{ to: "/settings/inquiry-win-back", text: "Inquiry win back" },
    { to: "/settings/autopilot", text: "Autopilot" },
    //{ to: "/settings/early-check-in-upsell", text: "Early Check-in Upsell" },
    //{ to: "/settings/late-check-out-upsell", text: "Late Check-out Upsell" },
    //{ to: "/settings/notification-settings", text: "Notification Settings" },
    { to: "/settings/pms-and-channels", text: t("settingspage.pmsAndChannels"), },
    { to: "/settings/whatsapp-connection", text: t("settingspage.whatsappConnection"), },
    // { to: "/settings/payments", text: t("settingspage.payments") },
    //{ to: "/settings/booking-site", text: t("settingspage.bookingSite") },
    //{ to: "/settings/promo-codes", text: t("settingspage.promoCodes") },
  ];

  return (
    <Box className="acc-settings-box">
      <h1 className="B-20-30-500">{t("settingspage.accountSettings")}</h1>
      <Box className="acc-settings-list">
        {links.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            className={`acc-settings-list-item ${location.pathname === link.to ? "active" : ""
              }`}
          >
            <p className="B-16-16-600">{link.text}</p>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default AccountSettingsListBox;
