import { Flex, Text, Accordion, Title } from "@mantine/core";
import "./ReservationDetails.scss";
import { h_formatLabel, h_getCurrencySymbol } from "../../../../utils/helpers";
import { ReservationDetails } from "../../../../types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ProviderImage from "../../../../components/shared-components/ProviderImage/ProviderImage";

interface TableItem {
  item: string;
  price: string;
}

interface BookingDetailItem {
  item1: string;
  item2: string | JSX.Element;
  icon?: string | null;
}

interface BookingDetailGroup {
  group: string;
  items: BookingDetailItem[];
}

const ReservationBookingDetail = ({
  reservationData,
}: {
  reservationData: ReservationDetails;
}) => {
  const { t } = useTranslation();
  const guestPaidTableItems: TableItem[] =
    reservationData?.services?.map((service: any) => ({
      item: service.name,
      price: `${h_getCurrencySymbol(
        reservationData.currency
      )} ${service.totalPrice.toFixed(2)} `,
    })) || [];

  if (!reservationData) return <div> No Data </div>;
  const sd_rd = reservationData;
  const sd_adults = sd_rd.adults
    ? `${sd_rd.adults} ${t("inboxpage.adults")}`
    : "";
  const sd_childs = sd_rd.children
    ? `${reservationData.children} ${t("inboxpage.children")}`
    : "";
  const sd_infats = reservationData.infants
    ? `${reservationData.infants} ${t("inboxpage.infants")}`
    : "";
  const sd_guests =
    sd_adults === "" && sd_childs === "" && sd_infats === ""
      ? "No Guest found"
      : sd_adults + " " + sd_childs + " " + sd_infats;

  const bookingDetails: BookingDetailGroup[] = [
    {
      group: "dates",
      items: [
        {
          item1: t("inboxpage.checkin"),
          item2: (
            <Flex align="center" gap={4}>
              <Text fw={500}>
                {moment(reservationData.checkInDate).format("MM/DD/YYYY")}
              </Text>
              <Text c="teal.6" fw={500}>
                {moment(reservationData.checkInDate).format("HH:mm")}
              </Text>
            </Flex>
          ),
        },
        {
          item1: t("inboxpage.checkout"),
          item2: (
            <Flex align="center" gap={4}>
              <Text fw={500}>
                {moment(reservationData.checkOutDate).format("MM/DD/YYYY")}
              </Text>
              <Text c="red.6" fw={500}>
                {moment(reservationData.checkOutDate).format("HH:mm")}
              </Text>
            </Flex>
          ),
        },
      ],
    },
    {
      group: "booking",
      items: [
        {
          item1: t("inboxpage.bookingdate"),
          item2: moment(reservationData.createdAt).format("MM/DD/YYYY HH:mm"),
        },
        {
          item1: t("inboxpage.bookingsource"),
          item2: (
            <Flex align="center" gap={4}>
              <ProviderImage
                source={reservationData.otaName || ""}
                size={16}
                opacity={1}
              />
              <Text size="sm" c="gray.6" fw={500}>
                {reservationData.otaName || "-"}
              </Text>
            </Flex>
          ),
        },
      ],
    },
    {
      group: "other",
      items: [
        {
          item1: t("inboxpage.guestname"),
          item2: reservationData.customerNameSurname || "-",
        },
        {
          item1: t("inboxpage.guests"),
          item2: sd_guests,
        },
        {
          item1: t("inboxpage.confirmationcode"),
          item2: reservationData.otaReservationCode || "-",
        },
      ],
    },
  ];

  const sd_currency = h_getCurrencySymbol(reservationData.currency);
  const sd_formatAmount = new Intl.NumberFormat("tr-TR", {
    minimumFractionDigits: 2,
  }).format(reservationData.amount);

  return (
    <Accordion defaultValue="Booking Details" miw={300}>
      <Accordion.Item key="details" value="Booking Details">
        <Accordion.Control>
          <Title order={4}>{t("inboxpage.bookingdetails")}</Title>
        </Accordion.Control>
        <Accordion.Panel>
          <div className="popover-content-area">
            {bookingDetails.map((group, groupIndex) => (
              <div key={groupIndex}>
                <Flex
                  direction="column"
                  gap={4}
                  py={16}
                  className="popover-content"
                >
                  {group.items.map((detail, index) => (
                    <div key={index} className="popover-booking-item">
                      <Text size="sm" c="gray.9">
                        {detail.item1}
                      </Text>
                      <Flex align="center" gap={4}>
                        {detail.icon && (
                          <img
                            src={detail.icon}
                            alt="provider"
                            width="16px"
                            height="18px"
                          />
                        )}
                        <Text size="sm" c="gray.6" fw={500}>
                          {detail.item2}
                        </Text>
                      </Flex>
                    </div>
                  ))}
                </Flex>
              </div>
            ))}
            <div className="guest-paid-box">
              <Title order={4} size="sm" c="gray.9">
                {t("inboxpage.guestpaid")}
              </Title>
              <div className="details">
                {guestPaidTableItems.map((item: TableItem, index: number) => (
                  <div className="flex-box jsb" key={index}>
                    <Text size="sm" c="gray.7">
                      {h_formatLabel(item.item)}
                    </Text>
                    <Text size="sm" c="gray.7">
                      {item.price}
                    </Text>
                  </div>
                ))}
                <div className="flex-box jsb">
                  <Text size="md" fw={600}>
                    {t("inboxpage.total") +
                      " ( " +
                      reservationData.currency +
                      " )"}
                  </Text>
                  <Text size="md" fw={600}>
                    {sd_currency + sd_formatAmount}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
export default ReservationBookingDetail;
