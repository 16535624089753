import React, { useState } from "react";
import { Stack, Grid, useMediaQuery, useTheme, Box } from "@mui/material";
import Avatar from "../../../../../../assets/images/avatar2.jpeg";
import CheckinSvg from "../../../../../../assets/images/icons/check-in.svg";
import CheckoutSvg from "../../../../../../assets/images/icons/check-out.svg";
import BedSvg from "../../../../../../assets/images/icons/bed.svg";
import WifiSvg from "../../../../../../assets/images/icons/wifi.svg";
import AirConditionSvg from "../../../../../../assets/images/icons/air-conditioning.svg";
import BathtubSvg from "../../../../../../assets/images/icons/bathtub.svg";
import TvSvg from "../../../../../../assets/images/icons/tv.svg";
import CarSvg from "../../../../../../assets/images/icons/car.svg";
import PoolSvg from "../../../../../../assets/images/icons/pool.svg";
import FlagSvg from "../../../../../../assets/images/icons/report-flag.svg";
import WasherSvg from "../../../../../../assets/images/icons/washer-icon.svg";
import KitchenSvg from "../../../../../../assets/images/icons/kitchen-icon.svg";
import Barbeque from "../../../../../../assets/images/icons/bbq-icon.svg";
import LinearArrowDown from "../../../../../../assets/images/icons/linear-arrow-down.svg";
import PreviewListing1 from "../../../../../../assets/images/preview-listing1.png";
import PreviewListing2 from "../../../../../../assets/images/preview-listing2.png";
import PreviewListing3 from "../../../../../../assets/images/preview-listing3.png";
import PreviewListing4 from "../../../../../../assets/images/preview-listing4.png";
import PreviewListing5 from "../../../../../../assets/images/preview-listing5.png";
import BackIcon from "../../../../../../assets/images/icons/back-icon.svg";
import Card from "./modals/Card";
import OfferItem from "./modals/OfferItem";
import ShowMoreButton from "./modals/ShowMoreButton";
import DatePickerInput from "../../../../home/components/DatePicker";
import { DateRange } from "react-day-picker";
import { addDays } from "date-fns";
import CustomModal from "../../../../../../components/shared-components/CustomModal";
import CounterBox from "../../../../../../components/shared-components/CounterBox";
import { Link } from "react-router-dom";

interface PlaceBasicsItemProps {
  itemText: string;
  itemDescription?: string;
  initialValue?: number;
  onChange: (value: number) => void;
}

const PlaceBasicsItem: React.FC<PlaceBasicsItemProps> = ({
  itemText,
  itemDescription,
  initialValue = 0,
  onChange,
}) => {
  const [itemValue, setItemValue] = useState(initialValue);

  const handleMinusClick = () => {
    if (itemValue > 0) {
      const newValue = itemValue - 1;
      setItemValue(newValue);
      onChange(newValue);
    }
  };

  const handlePlusClick = () => {
    const newValue = itemValue + 1;
    setItemValue(newValue);
    onChange(newValue);
  };

  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{
        width: "100%",
        minWidth: "510px",
        paddingBottom: "24px",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid var(--Gray-Gray-400, #d8d8e5)",
      }}
    >
      <Stack spacing={1}>
        <p className="place-basics-item-text H-20-20-600">{itemText}</p>
        <p className="B-14-20-400"> {itemDescription} </p>
      </Stack>
      <CounterBox
        itemValue={itemValue}
        handleMinusClick={handleMinusClick}
        handlePlusClick={handlePlusClick}
      />
    </Stack>
  );
};

function PreviewListing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isTextShown, setIsTextShown] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [openGuests, setOpenGuests] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [pets, setPets] = useState(0);
  const totalGuests = adults + children + infants + pets;

  const propertyText = `Come and stay in this superb duplex T2, in the heart of the
  historic center of Bordeaux. Spacious and bright, in a real
  Bordeaux building in exposed stone, you will enjoy all the
  charms of the city thanks to its ideal location. Close to
  many shops, bars and restaurants, you can access the
  apartment by tram A and C and bus routes 27 and Come and stay in this superb duplex T2, in the heart of the
  historic center of Bordeaux. Spacious and bright, in a real
  Bordeaux building in exposed stone, you will enjoy all the
  charms of the city thanks to its ideal location. Close to
  many shops, bars and restaurants, you can access the
  apartment by tram A and C and bus routes 27.`;

  const truncatedText =
    propertyText.slice(0, 400) + (propertyText.length > 400 ? "..." : "");

  const handleShowMoreClick = () => {
    setIsTextShown(!isTextShown);
  };

  const offers = [
    { src: WifiSvg, alt: "wifi", text: "Wifi" },
    { src: AirConditionSvg, alt: "air-conditioning", text: "Air-conditioning" },
    { src: BathtubSvg, alt: "bath-tub", text: "Bath tub" },
    { src: TvSvg, alt: "tv", text: '32" HDTV with Netflix' },
    { src: CarSvg, alt: "car", text: "Free parking on premises" },
    { src: PoolSvg, alt: "pool", text: "Pool" },
    { src: WasherSvg, alt: "washer", text: "Washer" },
    { src: KitchenSvg, alt: "kitchen", text: "Kitchen" },
    { src: Barbeque, alt: "barbeque", text: "Barbeque" },
  ];

  const handleSecondShowMoreClick = () => {
    setIsShowMore(isShowMore ? false : true);
  };

  const displayedOffers = isShowMore ? offers : offers.slice(0, 6);

  const initialRange: DateRange = {
    from: new Date(),
    to: addDays(new Date(), 4),
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => { };

  const [range, setRange] = React.useState(initialRange);

  const handleGuestsChange = (type: string, value: number) => {
    if (type === "Adults") {
      setAdults(value);
    } else if (type === "Children") {
      setChildren(value);
    } else if (type === "Infants") {
      setInfants(value);
    } else if (type === "Pets") {
      setPets(value);
    }
  };

  const guestItems = [
    { itemText: "Adults", itemDescription: "Ages 13+", value: adults },
    { itemText: "Children", itemDescription: "Ages 2–12", value: children },
    { itemText: "Infants", itemDescription: "Under 2", value: infants },
    { itemText: "Pets", itemDescription: "", value: pets },
  ];

  const handleGuestsClose = () => {
    setOpenGuests(false);
  };

  const handleGuestsSave = () => {
    setOpenGuests(false);
  };

  const previewImages = [
    PreviewListing1,
    PreviewListing2,
    PreviewListing3,
    PreviewListing4,
    PreviewListing5,
  ];
  const month = {
    alignItems: "center",
  };
  const [currentImageIndex] = useState(0);



  return (
    <Box
      className={!isMobile ? "container" : ""}
      sx={{
        width: { xs: "100%", sm: "auto" },
        margin: "auto",
      }}
      padding={isMobile ? 2 : 0}
      pb={isMobile ? 10 : 0}
    >
      <Stack spacing={5} mt={isMobile ? 0 : 5} mb={5}>
        <button className="preview-list-back-button">
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: "100%" }}
            alignItems={"center"}
          >
            <Link to={"/listings/id/details"}>
              {" "}
              <img
                onClick={() => window.history.back()}
                src={BackIcon}
                alt="back"
                style={{
                  width: "16px",
                  height: "16px",
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              />
            </Link>
            <p
              className={
                isMobile
                  ? "H-24 preview-listing-header"
                  : "H-30-30-600 preview-listing-header"
              }
              style={{
                alignItems: "flex-start",
              }}
            >
              Preview Listing
            </p>
          </Stack>
        </button>
        <Stack spacing={5}>
          <Stack sx={{ width: "100%" }}>
            <Grid
              container
              spacing={1}
              sx={{ overflow: "hidden", width: "100%" }}
            >
              {isMobile ? (
                <Grid item xs={12} sx={{ position: "relative" }}>
                  <img
                    src={previewImages[currentImageIndex]}
                    alt="preview"
                    className="preview-listing-img"
                    style={{
                      width: "100%",
                      height: "280px",
                      objectFit: "cover",
                      borderRadius: "16px",
                    }}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={6}>
                    <img
                      src={PreviewListing1}
                      alt="preview"
                      className="preview-listing-img"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <img
                          src={PreviewListing2}
                          alt="preview"
                          className="preview-listing-img-small"
                        />
                        <img
                          src={PreviewListing3}
                          alt="preview"
                          className="preview-listing-img-small"
                          style={{ borderTopRightRadius: "16px" }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <img
                          src={PreviewListing4}
                          alt="preview"
                          className="preview-listing-img-small"
                        />
                        <img
                          src={PreviewListing5}
                          alt="preview"
                          className="preview-listing-img-small"
                          style={{ borderBottomRightRadius: "16px" }}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          </Stack>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack
              spacing={5}
              sx={{ maxWidth: "750px" }}
              width={isMobile ? "100%" : undefined}
            >
              {/* Main İnfos */}
              <Stack spacing={3}>
                <Stack
                  spacing={1}
                  sx={{
                    borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                  }}
                >
                  <h1 className="H-24">Entire villa in Marmaris, Turkey</h1>
                  <p className="B-16" style={{ marginBottom: "24px" }}>
                    8 bedrooms • 8 bedrooms • 8 beds • 4 baths
                  </p>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                    paddingBottom: "24px",
                  }}
                >
                  <img
                    src={Avatar}
                    alt="avatar"
                    className="preview-listing-avatar-img"
                  />
                  <Stack alignSelf="center">
                    <p className="B-16-16-500">Hosted by Ali</p>
                    <p className="B-14-20-400">
                      2 years hosting: How To Boost…
                    </p>
                  </Stack>
                </Stack>
              </Stack>
              {/* All infos */}
              <Stack spacing={6}>
                <Stack
                  spacing={2}
                  sx={{
                    borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                    paddingBottom: "40px",
                  }}
                >
                  <p className="B-18-18-600">About this property</p>
                  <p
                    className="B-16"
                    style={{ color: "#3F4254", maxWidth: "670px" }}
                  >
                    {isTextShown ? propertyText : truncatedText}
                  </p>
                  <ShowMoreButton onClick={handleShowMoreClick}>
                    {isTextShown ? "Show Less" : "Show More"}
                  </ShowMoreButton>
                </Stack>
                {/* Giriş-çıkış */}
                <Stack
                  spacing={3}
                  direction={"row"}
                  sx={{
                    borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                    paddingBottom: "40px",
                    overflowX: isMobile ? "scroll" : "hidden",
                    display: "flex",
                    flexDirection: "row",
                    "& > *": {
                      flex: `0 0 ${isMobile ? "300px" : "auto"}`,
                    },
                    "&::-webkit-scrollbar": {
                      display: isMobile ? "none" : "auto",
                    },
                  }}
                  width={"100%"}
                >
                  <Card
                    imgSrc={CheckinSvg}
                    altText="check-in"
                    title="Check-in:"
                    description="Anytime after 11:00 AM"
                  />
                  <Card
                    imgSrc={CheckoutSvg}
                    altText="check-out"
                    title="Check-out:"
                    description="Before 10:00 AM"
                  />
                </Stack>
                {/* Offers */}
                <Stack
                  spacing={4}
                  sx={{
                    borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                    paddingBottom: "40px",
                  }}
                >
                  <p className="B-18-18-600">What this place offers</p>
                  <Grid container maxWidth={"631px"}>
                    {displayedOffers.map((offer, index) => (
                      <Grid
                        item
                        xs={6}
                        key={index}
                        style={{
                          marginBottom:
                            Math.floor(index / 2) <
                              Math.ceil(displayedOffers.length / 2) - 1
                              ? "24px"
                              : "0px",
                        }}
                      >
                        <OfferItem {...offer} />
                      </Grid>
                    ))}
                  </Grid>
                  {offers.length > 6 && (
                    <ShowMoreButton onClick={handleSecondShowMoreClick}>
                      {isShowMore ? "Show Less" : "Show More"}
                    </ShowMoreButton>
                  )}
                </Stack>
                {/*  Sleeping arregments -TODO - Slider olmalı */}
                <Stack
                  spacing={4}
                  sx={{
                    borderBottom: "1px solid var(--stroke-colors, #EBEBEB)",
                    paddingBottom: "40px",
                  }}
                >
                  <p className="B-18-18-600">Sleeping arrangements</p>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    sx={{
                      overflowX: isMobile ? "scroll" : "hidden",
                      display: "flex",
                      flexDirection: "row",
                      "& > *": {
                        flex: `0 0 ${isMobile ? "140px" : "auto"}`,
                      },
                      "&::-webkit-scrollbar": {
                        display: isMobile ? "none" : "auto",
                      },
                    }}
                    width={"100%"}
                  >
                    <Card
                      imgSrc={BedSvg}
                      altText="bed"
                      title="Bedroom 1:"
                      description="1 queen bed"
                    />
                    <Card
                      imgSrc={BedSvg}
                      altText="bed"
                      title="Bedroom 1:"
                      description="1 queen bed"
                    />
                    <Card
                      imgSrc={BedSvg}
                      altText="bed"
                      title="Bedroom 1:"
                      description="1 queen bed"
                    />
                  </Stack>
                </Stack>
                {/*  Calendar alanı  1*/}
                <Stack spacing={3} width={"100%"}>
                  <Stack>
                    <p className="B-20-20-600" style={{ marginBottom: "12px" }}>
                      18 nights in Marmaris
                    </p>
                    <p className="B-14">Feb 19, 2022 - Feb 26, 2022</p>
                  </Stack>
                  <Stack
                    alignItems={"center"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    width={"100%"}
                  >
                    <DatePickerInput
                      isMobile={isMobile}
                      numberOfMonth={isMobile ? 1 : 2}
                      setSelectedPeriod={() => { }}
                      setRange={setRange}
                      range={range}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {/*  Reservation card alanı */}
            {!isMobile && (
              <Stack spacing={4} sx={{ width: "370px", alignItems: "center" }}>
                <Stack
                  spacing={2}
                  sx={{
                    width: "370px",
                    height: "512px",
                    borderRadius: "8px",
                    border: "1px solid var(--gray-200, #E5E7EB)",
                    padding: "24px",
                    boxShadow:
                      "0px 20px 25px 0px rgba(31, 41, 55, 0.10), 0px 10px 10px 0px rgba(31, 41, 55, 0.04)",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <p className="H-20-20-600">$75</p>
                    <p className="B-16 reserve-card-text">/</p>
                    <p className="B-16 reserve-card-text">night</p>
                  </Stack>
                  {/*   TODO - Buradaki alan editlenebilir olmalı. Custom datepicker ve select
          ile yeniden yapılmalı */}
                  <Stack
                    sx={{
                      borderRadius: "8px",
                    }}
                  >
                    <button
                      onClick={() => setOpen(true)}
                      className="preview-listing-reservation-card-modal-date-button"
                    >
                      <Stack
                        direction="row"
                        sx={{
                          width: "100%",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            padding: "8px",
                            gap: "4px",
                            boxShadow: "-1px 0px 0px 0px #D1D5DB inset",
                            alignItems: "flex-start",
                          }}
                        >
                          <p className="B-10-10-600">CHECK-IN</p>
                          <p className="B-14-20-400">2/19/2022</p>
                        </Stack>
                        <Stack
                          sx={{
                            width: "100%",
                            padding: "8px",
                            gap: "4px",
                            alignItems: "flex-start",
                          }}
                        >
                          <p className="B-10-10-600">CHECK-OUT</p>
                          <p className="B-14-20-400">2/19/2022</p>
                        </Stack>
                      </Stack>
                    </button>
                    <CustomModal
                      open={open}
                      handleClose={handleClose}
                      handleSave={handleSave}
                      title={"Selected dates"}
                    >
                      <Stack flexDirection={"row"}>
                        <DatePickerInput
                          styles={month}
                          setSelectedPeriod={() => { }}
                          setRange={setRange}
                          range={range}
                        />
                      </Stack>
                    </CustomModal>
                    <>
                      <button
                        onClick={() => setOpenGuests(true)}
                        className="preview-listing-reservation-card-modal-button"
                      >
                        <Stack
                          sx={{ padding: "8px" }}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Stack sx={{ alignItems: "flex-start" }}>
                            <p className="B-10-10-600">GUESTS</p>
                            <p className="B-14-20-400">{totalGuests} guests</p>
                          </Stack>
                          <img src={LinearArrowDown} alt="arrow-down" />
                        </Stack>
                      </button>
                      <CustomModal
                        open={openGuests}
                        handleClose={handleGuestsClose}
                        handleSave={handleGuestsSave}
                        title={"Selected guests"}
                      >
                        <Stack spacing={3} mt={1}>
                          {guestItems.map((item, index) => (
                            <PlaceBasicsItem
                              key={index}
                              itemText={item.itemText}
                              itemDescription={item.itemDescription}
                              initialValue={item.value}
                              onChange={(value) =>
                                handleGuestsChange(item.itemText, value)
                              }
                            />
                          ))}
                        </Stack>
                      </CustomModal>
                    </>
                  </Stack>
                  <Stack sx={{ width: "100%" }}>
                    <button className="B-16-16-600 reserve-card-button">
                      Reserve
                    </button>
                  </Stack>
                  <p className="B-14 reserve-card-info-text">
                    You won’t be charged yet
                  </p>
                  <Stack sx={{ width: "100%" }} spacing={1}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <p className="B-14-24-500">$79 x 7 nights</p>
                      <p className="B-14-24-500">$555</p>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <p className="B-14-24-500">Weekly discount</p>
                      <p className="B-14-24-500 reserve-card-discount-text">
                        -$28
                      </p>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <p className="B-14-24-500">Cleaning fee</p>
                      <p className="B-14-24-500">$62</p>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <p className="B-14-24-500">Service fee</p>
                      <p className="B-14-24-500">$83</p>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <p className="B-14-24-500">Occupancy taxes and fees</p>
                      <p
                        className="B-14-24-500"
                        style={{ marginBottom: "8px" }}
                      >
                        $29
                      </p>
                    </Stack>
                    <div className="divider" />
                  </Stack>

                  <Stack sx={{ width: "100%" }} spacing={1}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <p className="B-18-18-600">Total</p>
                      <p className="B-18-18-600">$701</p>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <img
                    src={FlagSvg}
                    alt="flag"
                    className="preview-listing-report-listing-img"
                  />
                  <button className="reservation-card-report-listing">
                    Report this listing
                  </button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default PreviewListing;
