import dayjs from "dayjs";
import { MessageHeader } from "../types";

export const decodeJWT = async (token: string) => {
    try {
        const base64Url = token.split(".")[1]; // Get the payload part
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Handle URL-safe base64
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Error decoding JWT:", error);
        return null;
    }
}


export const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: ReturnType<typeof setTimeout>;
    const debounced = (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };

    debounced.cancel = () => {
        clearTimeout(timer);
    };

    return debounced;
}


export const h_formatUTCtoLocaleTime = (time: string) => {
    const utcDate = new Date(time); // Parse the UTC time from  ISO string
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000); // Adjust to local time

    return localDate.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
    });
}


export const h_getConfirmationColor = (status: string | undefined): string => {
    if (!status) {
        return "#000000"; // Varsayılan renk (Siyah)
    }

    switch (status.toLowerCase()) {
        case "confirmed":
            return "#5DBB63"; // Açık Yeşil
        case "currently hosting":
            return "#5DBB63"; // Açık Yeşil
        case "modified":
            return "#D9A34A"; // Altın Sarısı
        case "check-in today":
            return "#5DBB63"; // Açık Yeşil
        case "inquiry":
            return "#101A47"; // Koyu Lacivert
        case "post stay":
            return "#101A47"; // Koyu Lacivert
        case "pending":
            return "#D9A34A"; // Altın Sarısı
        case "check-in tomorrow":
            return "#5DBB63"; // Açık Yeşil
        case "expired":
            return "#F04A5D"; // Kırmızı
        case "no show":
            return "#101A47"; // Koyu Lacivert
        case "cancelled":
        case "canceled":
            return "#F04A5D"; // Kırmızı
        case "check-out today":
            return "#F04A5D"; // Kırmızı
        case "unknown":
            return "#101A47"; // Koyu Lacivert
        case "arriving in 2 days":
            return "#5DBB63"; // Açık Yeşil
        case "check-out tomorrow":
            return "#F04A5D"; // Kırmızı
        default:
            return "#000000"; // Siyah (Varsayılan renk)
    }
};


export const h_getCurrencySymbol = (currencyCode: string): string => {
    switch (currencyCode) {
        case "USD":
            return "$";
        case "EUR":
            return "€";
        case "GBP":
            return "£";
        case "TRY":
            return "₺";
        default:
            return currencyCode;
    }
}

export const h_formatLabel = (label: string): string => {
    if (!label) return "";

    return label
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export const h_formatStatusText = (status: string): string => {
    return status
        .replace(/_/g, ' ')
        .toUpperCase()
};

export const h_isDateBetween = (date: dayjs.Dayjs, startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null): boolean => {
    if (!startDate || !endDate) return false;
    return (date.isSame(startDate, 'day') || date.isAfter(startDate, 'day')) && (date.isSame(endDate, 'day') || date.isBefore(endDate, 'day'));
};



export const h_sortMessagesByDate = (messages: MessageHeader[]) => {
    return messages?.sort(
        (a: any, b: any) => b.lastMessageReceivedAt - a.lastMessageReceivedAt
    );
};
