import React, { useEffect } from "react";
import Header from "./components/layout-components/Header";
import "./assets/sass/main.scss";
import PrivateViews from "./views/private";
import BooklyAxiosContainer from "./containers/BooklyAxiosContainer";
import "./utils/i18n/i18n";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useTheme, useMediaQuery } from "@mui/material";
import NavbarMobile from "./components/layout-components/NavbarMobile";
import HeaderMobile from "./components/layout-components/HeaderMobile";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { decodeAndSaveUserJWT } from "./store/slices/globalSlice";
import { decodeJWT } from "./utils/helpers";
import LoadingFallback from "./LoadingFallback";

function App() {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const [isOnBoarding, setIsOnBoarding] = React.useState(true);
  const [currentQueryParameters] = useSearchParams();
  const inboxIdParam = currentQueryParameters.get("inboxId") || "";
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isOnInboxPage = location.pathname.includes("inbox");
  const isOnSettingsPage = location.pathname.includes("settings");
  const isOnMenuPage = location.pathname.includes("menu");
  const isOnProfilePage = location.pathname.includes("profile");
  const userDetails = useSelector((state: RootState) => state.global.userDetails)
  const navigate = useNavigate()

  useEffect(() => {
    const handleToken = async () => {
      const jwt = await getAccessTokenSilently()
      const decoded = await decodeJWT(jwt)
      dispatch(decodeAndSaveUserJWT(decoded))
    }

    if (user?.email_verified === false && !location.pathname.includes("verify")) {
      navigate("email-verify")
    } else {


      if (isAuthenticated && !userDetails) {
        handleToken()
      }

      moment.locale("tr");

      if (!isAuthenticated && !isLoading) {
        loginWithRedirect();
      }
    }

    if (
      location.pathname.includes("onboarding") ||
      location.pathname.includes("onboardingtwo")
    ) {
      setIsOnBoarding(true);
    } else {
      setIsOnBoarding(false);
    }

  }, [user, isLoading, location.pathname]);


  if (isLoading && (!userDetails || !userDetails.customerId || user === undefined)) {
    return <LoadingFallback />
  }



  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <BooklyAxiosContainer>
        {isMobile ? (
          <>
            {!inboxIdParam && (
              <NavbarMobile
                isOnboarding={isOnBoarding}
                setIsOnBoarding={setIsOnBoarding}
              />
            )}
            {isOnInboxPage ||
              isOnSettingsPage ||
              isOnMenuPage ||
              isOnProfilePage ? null : (
              <HeaderMobile
                isOnboarding={isOnBoarding}
                setIsOnBoarding={setIsOnBoarding}
              />
            )}
          </>
        ) : (
          <Header
            isOnboarding={isOnBoarding}
            setIsOnBoarding={setIsOnBoarding}
          />
        )}
        <PrivateViews />
      </BooklyAxiosContainer>
    </>
  );
}

export default App;
