import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalState } from "./globalSlice";
import { getAllPropertiesApi, GetAllPropertiesParams, getPropertyTagsApi, GetPropertyTagsParams, postPropertyDetailsApi, postSelectedPropertyListApi } from "../../api/propertyApi";
import { OnboardState } from "./onboardSlice";
import { CustomFileStruct } from "../../components/shared-components/FileUploadComponent/DocumentUploadComponent";
import { GenericMetaData, PropertyFile, PropertyLite } from "../../types";
import { toast } from "react-toastify";
import { RootState } from "../store";

interface PropertyState {
  selectedPropertyId: string
  properties: PropertyLite[],
  propertiesMetaData: GenericMetaData | null
  propertiesLoading: boolean
  propertyTags: {
    id: string,
    tag: string,
    relation: string
  }[]
  modalFiles: PropertyFile[]
}

const initialState: PropertyState = {
  selectedPropertyId: "",
  properties: [],
  propertiesMetaData: null,
  propertiesLoading: false,
  propertyTags: [],
  modalFiles: []
}


export const getAllProperties = createAsyncThunk(
  "property/getAllProperties",
  async (params: GetAllPropertiesParams, { getState }) => {
    try {
      const { property } = getState() as RootState
      if (params.PageIndex === property.propertiesMetaData?.pageIndex) {
        return;
      } else {
        const response = await getAllPropertiesApi(params)
        return response.data
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)

export const getPropertyTags = createAsyncThunk(
  "property/getPropertyTags",
  async (params: GetPropertyTagsParams) => {
    try {
      const response = await getPropertyTagsApi(params)
      return response.data
    } catch (error) {
      console.error("API call error:", error);
    }
  }
)



export const postPropertyDetails = createAsyncThunk(
  "property/postPropertyDetails",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    try {
      const state = getState() as { onboard: OnboardState, global: GlobalState }
      const extraData = {
        accessToken,
        customerId: state.global.userDetails?.customerId ?? "",
        selectedProviderType: state.onboard.onboardingData.selectedProviderType ?? 0
      }
      const response = await postPropertyDetailsApi(extraData)
      return response
    } catch (error: any) {
      console.error("API call error:", error);
    }
  }
)

export const postSelectedPropertyList = createAsyncThunk(
  "property/postSelectedPropertyList",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    try {
      const state = getState() as { property: PropertyState }
      const selectedPropertyIds = state.property.properties.map((row) => row.id);
      const response = await postSelectedPropertyListApi({ accessToken, selectedPropertyIds })
      return response
    } catch (error: any) {
      console.error("API ERRROR")
    }
  }
)



const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    handleUploadSave: (state, action: PayloadAction<CustomFileStruct[]>) => {
      const files = action.payload
      if (state.selectedPropertyId !== null) {
        const newFiles = files.map((file: CustomFileStruct) => ({
          id: file.id as string,
          name: file.file.name,
          tags: file.tags,
          size: file.file.size,
          ragStatus: 0 as 0 | 1,
          isDeleted: false
        }));

        const updatedFiles = newFiles ?? []

        const propertyIndex = state.properties.findIndex((row) => row.id === state.selectedPropertyId);

        if (propertyIndex !== -1) {
          state.properties[propertyIndex] = {
            ...state.properties[propertyIndex],
            files: updatedFiles
          };
        }

        state.modalFiles = updatedFiles
      }
    },
    handleModalFiles: (state, action: PayloadAction<string>) => {
      const propertyId = action.payload

      state.selectedPropertyId = propertyId

      const selectedProperty = state.properties.find((pro) => pro.id === propertyId);

      if (selectedProperty) {
        const existingDocuments = selectedProperty.files || [];
        if (existingDocuments.length > 0) {
          const documentsWithSize = existingDocuments.map((doc: any) => ({
            ...doc,
            size: 500 * 1024,
            tags: doc.tags || [],
            url: doc.url,
            id: doc.id,
          }));
          state.modalFiles = documentsWithSize;
        } else {
          state.modalFiles = [];
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProperties.pending, (state) => {
        state.propertiesLoading = true
      })
      .addCase(getAllProperties.rejected, (state) => {
        toast.warning("Can not fetch properties")
        state.propertiesLoading = false
      })
      .addCase(getAllProperties.fulfilled, (state, action) => {
        if (action.payload) {
          const { items, ...rest } = action.payload
          state.propertiesMetaData = rest
          const apiData: PropertyLite[] = items.map((property: any, _: number) => {

            let files = []
            let tags = []

            if (property.files && property.files.length > 0) {
              const existFiles = property.files.filter((file: any) => !file.isDeleted)
              files = existFiles.map((file: any) => ({
                name: file.name,
                tags: file.tags || [],
                url: file.url,
                id: file.id,
                isImage: file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) !== null,
              }))
            }

            if (property.tags && Array.isArray(property.tags)) {
              tags = property.tags.filter((tag: { tag: string }) => tag && tag.tag)
            }

            return {
              id: property.id,
              name: property.name,
              internalName: property.internalName,
              typeCode: property.typeCode,
              city: property.city,
              state: property.state,
              street: property.street,
              address: `${property.street}, ${property.city}, ${property.state || ""}, ${property.zipCode}`,
              status: property.isActive ? "Active" : "Passive",
              image: (property.images && property.images[0]?.url) || null,
              conversationRagStatus: property.conversationRagStatus,
              tags,
              files
            }
          })
          state.properties = [...state.properties, ...apiData]
        }
        state.propertiesLoading = false
      })
      .addCase(getPropertyTags.fulfilled, (state, action) => {
        if (action.payload) {
          state.propertyTags = action.payload
        }
      })
  }
})

export const {
  handleUploadSave,
  handleModalFiles
} = propertySlice.actions


export default propertySlice.reducer;
