import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchFilteredMessagesApi,
  FetchMessagesParams,
  getAllTagsByRelationApi,
  getAllCitiesApi,
  GetAllCitiesParams,
  GetAllProperties,
  getAllPropertiesApi,
  GetAllTagsParams,
  getAllUsersApi,
  GetAllUsersParams,
  GetAllMessageSourcesParams,
  getAllMessageSourcesApi
} from '../../api/filterApi';

export interface FiltersObject {
  cities: string[];
  sources: string[];
  tags: string[];
  assignedUsers: string[];
  propertyIds: string[];
  isUnreadOnly: boolean;
  isRead: boolean;
  notReplied: boolean;
  isNeedAttention: boolean;
  bookingStatus: string | null;
}

interface FilterState {
  isFilterActive: boolean;
  filterOptions: {
    propertyOptions: any[]
    channelOptions: any[]
    assignerOptions: any[]
    cityOptions: any[]
    tagOptions: any[]
  }
  filters: FiltersObject
}

const initialState: FilterState = {
  isFilterActive: false,
  filterOptions: {
    propertyOptions: [],
    channelOptions: [],
    assignerOptions: [],
    cityOptions: [],
    tagOptions: []
  },
  filters: {
    cities: [],
    sources: [],
    tags: [],
    assignedUsers: [],
    propertyIds: [],
    isUnreadOnly: false,
    isRead: false,
    notReplied: false,
    isNeedAttention: false,
    bookingStatus: null,
  },
}

export const fetchFilteredMessages = createAsyncThunk(
  "filter/fetchFilteredMessages",
  async (params: FetchMessagesParams,
    { getState }
  ) => {
    try {
      const state = getState() as { filter: FilterState };
      const { filters } = state.filter;
      const constructParams = { ...params, filters }
      const response = await fetchFilteredMessagesApi(constructParams)
      return response
    } catch (error) {
      throw error;
    }
  }
);




export const getAllProperties = createAsyncThunk(
  "chat/getAllProperties",
  async (
    params: GetAllProperties,
    { rejectWithValue }
  ) => {
    try {
      return await getAllPropertiesApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)
export const getAllMessageSources = createAsyncThunk(
  "chat/getAllMessageSources",
  async (
    params: GetAllMessageSourcesParams,
    { rejectWithValue }
  ) => {
    try {
      return await getAllMessageSourcesApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const getAllUsers = createAsyncThunk(
  "chat/getAllUsers",
  async (
    params: GetAllUsersParams,
    { rejectWithValue }
  ) => {
    try {
      return await getAllUsersApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const getAllCities = createAsyncThunk(
  "chat/getAllCities",
  async (
    params: GetAllCitiesParams,
    { rejectWithValue }
  ) => {
    try {
      return await getAllCitiesApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const getAllTags = createAsyncThunk(
  "chat/getAllTags",
  async (
    params: GetAllTagsParams,
    { rejectWithValue }
  ) => {
    try {
      return await getAllTagsByRelationApi(params);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)



const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };

      if (!state.isFilterActive) {
        state.isFilterActive = true;
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
      state.isFilterActive = false;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProperties.fulfilled, (state, action) => {
        const properties = action.payload.data
        const formattedOptions = properties.items.map((property: any) => ({
          label: property.internalName || property.name || "N/A",
          value: property.id,
          extraInfo: {
            city: property.city,
            status: property.isActive ? "Active" : "Passive",
            tags: property.tags,
          },
        }));
        state.filterOptions.propertyOptions = formattedOptions
      })
      .addCase(getAllMessageSources.fulfilled, (state, action) => {
        const channels = action.payload.data
        state.filterOptions.channelOptions = channels.map((channel: { source: string; }) => ({
          label: channel.source,
          value: channel.source,
        }));
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        const users = action.payload.data
        state.filterOptions.assignerOptions = users.map((user: any) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.email,
        }));
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        const { cities } = action.payload.data
        state.filterOptions.cityOptions = cities.map((city: string) => ({
          label: city,
          value: city
        }))
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        const tags = action.payload.data
        state.filterOptions.tagOptions = tags.map((tag: any) => ({
          label: tag.tag,
          value: tag.id,
        }));
      })
  }
})

export const {
  setFilters,
  resetFilters,
} = filterSlice.actions;





export default filterSlice.reducer;
