import { memo, useCallback, useEffect, useRef, useState } from 'react';
import "./BotelCalendar.scss"
import dayjs from 'dayjs';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import ReservationBookingDetail from "./ReservationBookingDetails";
import CustomSelect from '../../../../components/form-components/custom-select/CustomSelect';
import { useSelector } from 'react-redux';
import { selectCurrentReservation } from '../../../../store/slices/reservationSlice';
import { ReservationDetails } from '../../../../types';
import Portal from '../../../../components/shared-components/Portal/Portal';
import { h_isDateBetween } from '../../../../utils/helpers';
import { RootState } from '../../../../store/store';
import LoadingDots from '../../../../components/shared-components/loadings/LoadingDots';
import { LoadingOverlay } from '@mantine/core';
import ProviderImage from "../../../../components/shared-components/ProviderImage/ProviderImage";

interface DayProps {
  day: { date: dayjs.Dayjs, isCurrentMonth: boolean };
  first: boolean
  last: boolean
  isBetween: boolean
  isActive: boolean
  isTodayDate: boolean
  handleEnter: () => void
  checkInHour?: number
  checkOutHour?: number
  bookingSource?: string;
}

const SourceIcon = ({ source }: { source?: string }) => {
  if (!source) return null;
  return <ProviderImage source={source} size={16} />;
};

// Day Component
const Day = memo(({
  day,
  isBetween,
  first,
  last,
  isActive,
  isTodayDate,
  handleEnter,
  checkInHour,
  checkOutHour,
  bookingSource
}: DayProps) => {
  const onMouseEnter = useCallback(() => handleEnter?.(), [handleEnter]);

  return (
    <div onMouseEnter={onMouseEnter}
      className={`day
        ${day.isCurrentMonth ? "active" : ""}
        ${isBetween ? "day-between" : ""}
        ${first ? "first" : ""}
        ${last ? "last" : ""}
        ${isActive ? "active-day" : ""}
        ${isTodayDate ? "day-today" : ""}
        ${bookingSource ? `booking-source-${bookingSource.toLowerCase()}` : ""}
      `}>
      <span className="day-number">{day.date.format('D')}</span>
      {first && bookingSource && <div className="source-icon"><SourceIcon source={bookingSource} /></div>}
      <div className="hour-grid">
        {checkInHour !== undefined && checkOutHour !== undefined && (
          <div
            className={`booking-indicator ${isActive ? 'active' : 'inactive'}`}
            style={{
              left: `${(checkInHour / 24) * 100}%`,
              width: `${((checkOutHour - checkInHour) / 24) * 100}%`
            }}
          />
        )}
      </div>
    </div>
  )
});


const months = [
  { value: '0', label: 'January' },
  { value: '1', label: 'February' },
  { value: '2', label: 'March' },
  { value: '3', label: 'April' },
  { value: '4', label: 'May' },
  { value: '5', label: 'June' },
  { value: '6', label: 'July' },
  { value: '7', label: 'August' },
  { value: '8', label: 'September' },
  { value: '9', label: 'October' },
  { value: '10', label: 'November' },
  { value: '11', label: 'December' },
];

const years = Array.from({ length: 10 }, (_, i) => ({
  value: (2020 + i).toString(),
  label: (2020 + i).toString(),
}));

interface HeaderProps {
  currentDate: dayjs.Dayjs;
  onPrevious: () => void; onNext: () => void
  onMonthChange: (month: number) => void;
  onYearChange: (year: number) => void;
}
// Header Component
const Header = ({ currentDate, onPrevious, onNext, onMonthChange, onYearChange }: HeaderProps) => {

  return (
    <div className="header">
      <div className='month-year-box'>
        <CustomSelect
          options={months}
          value={<span>{currentDate.format('MMMM')}</span>}
          dropIcon
          onChange={(value) => onMonthChange(Number(value))}
        />
        <CustomSelect
          options={years}
          value={<span>{currentDate.format('YYYY')}</span>}
          dropIcon
          onChange={(value) => onYearChange(Number(value))}
        />
      </div>
      <div className="header-buttons">
        <button className="header-button" onClick={onPrevious}>
          <IconChevronLeft color='var(--mantine-color-blue-4)' />
        </button>
        <button className="header-button" onClick={onNext}>
          <IconChevronRight color='var(--mantine-color-blue-4)' />
        </button>
      </div>
    </div>
  )
}

const today = dayjs().startOf('day');

const isToday = (date: dayjs.Dayjs): boolean => {
  return date.startOf('day').isSame(today, 'day');
};



// Main Calendar Component
const BotelCalendar = () => {
  const selectedMessage = useSelector((state: RootState) => state.message.selectedMessage)
  const reservationData = useSelector(selectCurrentReservation)
  const calendarLoading = useSelector((state: RootState) => state.reservation.calendarLoading)

  const [currentDate, setCurrentDate] = useState(dayjs())

  const [hoverCardState, setHoverCardState] = useState(false)
  const botelRef = useRef<HTMLDivElement | null>(null);
  const [selectedResIndex, setSelectedResIndex] = useState(-1)
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  const _handlePreviousMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  };

  const _handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
  };

  const _handleOnMonthChange = (month: number) => {
    setCurrentDate(currentDate.month(month));
  };


  const _handleYearChange = (year: number) => {
    setCurrentDate(currentDate.year(year));
  };


  const _handleHoverCard = (active: boolean, resIndex: number) => {
    if (hoverCardState !== active) {
      setHoverCardState(active)
    }
    if (resIndex > -1 && selectedResIndex !== resIndex && reservationData) {
      setSelectedResIndex(resIndex)
    }
  }

  useEffect(() => {
    if (hoverCardState && botelRef.current) {
      const rect = botelRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [hoverCardState]);

  const generateDays = useCallback(() => {
    const startOfMonth = currentDate.startOf('month');
    const endOfMonth = currentDate.endOf('month');
    const days = [];

    // Add days from  previous month 
    for (let i = startOfMonth.day(); i > 0; i--) {
      days.push({
        date: startOfMonth.subtract(i, 'day'),
        isCurrentMonth: false,
      });
    }

    // Add days of current month
    for (let i = 0; i < endOfMonth.date(); i++) {
      days.push({
        date: startOfMonth.add(i, 'day'),
        isCurrentMonth: true,
      });
    }

    // Add days from  next month 
    const remainingDays = 7 - (days.length % 7);
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        date: endOfMonth.add(i, 'day'),
        isCurrentMonth: false,
      });
    }

    return days;
  }, [currentDate]);


  useEffect(() => {
    if (reservationData && selectedMessage) {
      setCurrentDate(dayjs(selectedMessage.bookingCheckIn))
    }

  }, [selectedMessage])

  if (!reservationData || !selectedMessage?.bookingId) return <div>No DATA</div>

  // TODO: ADD LOCALIZATON HERE full_month.split("")[0]
  const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


  const checkInOutDays = reservationData.map((res: ReservationDetails, index: number) => {
    return {
      checkInDate: dayjs(res.checkInDate),
      checkOutDate: dayjs(res.checkOutDate),
      isActive: res.id === selectedMessage?.bookingId,
      resIndex: index,
      source: res.otaName
    }
  })


  const days = generateDays()

  //TODO: IF WE SCHANGE INDEx WE ALSO NEED TO CHANGE DATA INSIDE POPOVER

  return (
    <div className="botel-calendar" onMouseLeave={() => { _handleHoverCard(false, -1) }}>
      <LoadingOverlay visible={calendarLoading} loaderProps={{ children: <LoadingDots /> }} />
      <Header
        currentDate={currentDate}
        onMonthChange={_handleOnMonthChange}
        onYearChange={_handleYearChange}
        onPrevious={_handlePreviousMonth}
        onNext={_handleNextMonth}
      />
      <Portal>
        <div
          className={`popover-target ${hoverCardState ? "visible" : ""}`}
          style={{
            top: position.top - 40,
            left: position.left - 310
          }}
        >
          {selectedResIndex > -1 && !(selectedResIndex > reservationData.length) &&
            <ReservationBookingDetail reservationData={reservationData[selectedResIndex]} />
          }
        </div>
      </Portal >


      <div className="calendar-main" ref={botelRef}>
        {dayLabels.map((day: string, index) => (
          <div className="day" key={index}>
            {day}
          </div>
        ))}
        {days.map((day, index) => {
          const foundDate = checkInOutDays.find(cio => h_isDateBetween(day.date, cio.checkInDate, cio.checkOutDate))

          let isFirstDay = false
          let isLastDay = false
          let isActive = false
          let checkInHour;
          let checkOutHour;

          if (foundDate) {
            isFirstDay = foundDate && day.date.isSame(foundDate?.checkInDate, 'day');
            isLastDay = foundDate && day.date.isSame(foundDate?.checkOutDate, 'day');
            isActive = foundDate.isActive

            if (isFirstDay) {
              checkInHour = foundDate.checkInDate.hour()
            } else {
              checkInHour = 0
            }

            if (isLastDay) {
              checkOutHour = foundDate.checkOutDate.hour()
            } else {
              checkOutHour = 24
            }
          }

          const isTodayDate = isToday(day.date);
          //const isSelected = selectedDates.some((d) => d.isSame(day.date, 'day'));

          return (
            <Day
              handleEnter={() => {
                if (foundDate) {
                  _handleHoverCard(true, foundDate.resIndex)
                } else if (hoverCardState) {
                  _handleHoverCard(false, -1)
                }
              }}
              key={index}
              day={day}
              first={isFirstDay}
              last={isLastDay}
              isBetween={!!foundDate}
              isActive={isActive}
              isTodayDate={isTodayDate}
              checkInHour={checkInHour}
              checkOutHour={checkOutHour}
              bookingSource={foundDate?.source}
            />
          )
        })
        }
      </div>
    </div>
  );
};

export default BotelCalendar;
