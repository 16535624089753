import { Box, Chip, Stack, } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomModal from "../../../../components/shared-components/CustomModal";
import CustomButton from "../../../../components/shared-components/CustomButton";
import PlusIconWhite from "../../../../assets/images/icons/plus-icon.svg";
import SyncIcon from "../../../../assets/images/icons/sync-icon.svg";
import ListingModal from "../step2/ListingModal";
import SelectRoleModal from "./SelectRoleModal";
import AddTeamMemberModal from "./AddTeamMemberModal";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { fetchTeamMembers } from "../../../../store/slices/teamMembersSlice";
import { Button, Flex, Pill, Text, Tooltip } from "@mantine/core";
import { toast } from "react-toastify";

interface InviteTeamMemberProps {
  handleSelectionChange: (selectedRowIds: number[]) => void;
  isSetting?: boolean
}
interface AxiosError {
  response?: {
    data?: any;
  };
  message?: string;
}
interface TeamMemberFormData {
  name: string;
  email: string;
  phone: string;
  role: string | null;
  roleDisplayName: string | null;
  listingIds: number[];
}
interface RowData {
  Id: number;
  name: string;
  email: string;
  userId: string;
  tags: string[];
  role: string;
  listingResponsibleIds: number[];
  status: string;
  action: string;
  isActive: boolean;
}
interface Property {
  propertyId: string;
  name: string;
  city: string;
}

interface Role {
  id: string;
  name: string;
  description: string;
  providerCode: string;
}


function InviteTeamMember({ handleSelectionChange, isSetting }: Readonly<InviteTeamMemberProps>) {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await axios.get(`${API_BASE_URL}/Role/List`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setRoles(response.data);
      } catch (error) {
        console.error("Roller API hatası:", error);
      }
    };

    fetchRoles();
  }, [getAccessTokenSilently]);

  const getColumns = (): GridColDef<any[number]>[] => [
    {
      field: "email",
      headerName: "Email",
      disableColumnMenu: true,
      flex: 2,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Flex h="100%" align="center">
          <Tooltip label={params.row.email}>
            <Text>
              {params.row.email}
            </Text>
          </Tooltip>
        </Flex>
      ),
    },
    {
      field: "tags",
      headerName: "Tags",
      disableColumnMenu: true,
      flex: 3,
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Stack
          sx={{
            display: "flex",
            alignItems: !params.row.tags[0] ? "flex-start" : "center",
            width: "100%",
            height: "100%",
            padding: "12px var(--Spacing-space-10, 24px) 12px 12px",
            alignContent: "center",
            gap: "4px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
          direction={"row"}
          spacing={"4px"}
        >
          {params.row.tags.slice(0, 2).map((tag: string, index: number) => (
            <Chip
              key={index}
              label={tag}
              className="tag"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#3E97FF",
              }}
            />
          ))}
          {params.row.tags.length > 2 && (
            <Chip
              label={`+${params.row.tags.length - 2}`}
              className="tag"
              style={{
                color: "#3F4254",
                backgroundColor: "#FFFFFF",
              }}
            />
          )}
        </Stack>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      disableColumnMenu: true,
      flex: 1.5,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const isUserActive = Boolean(params.row.isActive);

        return (
          <Flex h="100%" align="center">
            <Button size="xs" variant="light" onClick={() => {
              if (!isUserActive) {
                toast.warning("You need to invite the user first");
                return;
              }
              handleOpenModal(params.row.Id);
            }}
            >
              <Text truncate="end" size="sm" >
                {params.row.role || "Not assigned"}
              </Text>
            </Button>
          </Flex>
        );
      },
    },
    {
      field: "listingResponsibleIds",
      headerName: "Listing Responsible",
      disableColumnMenu: true,
      flex: 1.3,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Flex h="100%" justify="center" direction="column" >
          <Text fw={500}>
            {params.row.listingResponsibleIds.length} Listings
          </Text>
          <Text onClick={() => handleOpenListingModal(params.row.Id)} fw={500} c="blue.4" >
            Add/ Remove
          </Text>
        </Flex >
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      disableColumnMenu: true,
      flex: 1.2,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Flex align="center" justify="center" h="100%" w="100%">
          {params.row.isActive ? <Pill size="md" bg="green.1" c="green.9">Ready</Pill> : <Pill size="md" bg="red.1" c="red.9">Not Active</Pill>}
        </Flex>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      disableColumnMenu: true,
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Flex align="center" justify="center" h="100%" w="100%">
          <Button onClick={() => handleButtonClick(params.row.Id)} variant="outline" radius="sm">
            {params.row.action}
          </Button>
        </Flex>
      ),
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [rowsData, setRowsData] = useState<RowData[]>([]);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [openListingModal, setOpenListingModal] = useState(false);
  const [selectedListings, setSelectedListings] = useState<number[]>([]);
  // const handleOpenListingModal = () => setOpenListingModal(true);
  const handleCloseListingModal = () => setOpenListingModal(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [userProperties, setUserProperties] = useState<Property[]>([]);
  const [formData, setFormData] = useState<TeamMemberFormData | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const { members } = useSelector(
    (state: RootState) => state.teamMembers
  );

  const { userDetails } = useSelector(
    (state: RootState) => state.global
  );

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchTeamMembers({ accessToken }));
    };

    fetchData();
  }, [dispatch]);

  const getUserProperties = async (userId: string) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await axios.get(
        `${API_BASE_URL}/User/Get`,
        {
          params: {
            CustomerId: userDetails?.customerId ?? "",
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const user = response.data.find((u: any) => u.id === userId);
      if (user && user.properties) {
        const mappedProperties = user.properties.map((prop: any) => ({
          propertyId: prop.propertyId,
          name: prop.propertyName || "Unnamed Property",
          city: prop.propertyCity || "Unknown Location",
        }));
        setUserProperties(mappedProperties);
      }
    } catch (error) {
      console.error("Failed to fetch user properties:", error);
    }
  };

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleRemoveProperty = async (propertyId: string) => {
    if (!selectedUserId) return;

    try {
      const accessToken = await getAccessTokenSilently();
      const baseUrl = `${API_BASE_URL}/api/User/DeleteProperty`;
      const url = `${baseUrl}?UserId=${selectedUserId}&PropertyIds=${propertyId}`;

      console.log("New Request URL:", url);

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log("removed property successfully", response);
        setUserProperties((prevProperties) =>
          prevProperties.filter((prop) => prop.propertyId !== propertyId)
        );
      }
    } catch (error) {
      console.error("Failed to remove property:", error);
    }
  };

  const handleOpenListingModal = (rowId: number) => {
    const selectedRow = members?.find((row) => row.Id === rowId);
    if (selectedRow) {
      setSelectedUserId(selectedRow.userId as unknown as string);
      getUserProperties(selectedRow.userId as unknown as string);
    }
    setOpenListingModal(true);
  };

  const handleOpenModal = (rowId: number) => {
    const currentRole = members?.find((row) => row.Id === rowId)?.role || null;
    setSelectedRole(currentRole);
    setSelectedRowId(rowId);
    setOpenModal(true);
  };

  const handleSaveModal = async () => {
    if (selectedRowId !== null && selectedRole !== null) {
      const selectedUser = members?.find((row) => row.Id === selectedRowId);
      const selectedRoleObj = roles.find((role) => role.name === selectedRole);

      if (selectedUser && selectedRoleObj) {
        try {
          const accessToken = await getAccessTokenSilently();

          console.log("Selected User ID:", selectedUser.userId);
          console.log("Selected Role ID:", selectedRoleObj.id);

          const response = await axios.post(
            `${API_BASE_URL}/Role/SetUserRoles`,
            {
              userId: selectedUser.userId,
              roleIds: [selectedRoleObj.id],
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          console.log("Role successfully assigned:", response.data);
          setRowsData((prevRows) =>
            prevRows.map((row) =>
              row.Id === selectedRowId ? { ...row, role: selectedRole } : row
            )
          );
        } catch (error) {
          const axiosError = error as AxiosError;
          console.error(
            "Error assigning role:",
            axiosError.response?.data || axiosError.message
          );
        }
      }
    }

    setOpenModal(false);
    setSelectedRole(null);
    setSelectedRowId(null);
  };

  const handleButtonClick = async (rowId: number) => {
    const selectedRow = members?.find((row) => row.Id === rowId);

    if (!selectedRow) {
      console.error("Selected row not found");
      return;
    }

    try {
      if (selectedRow.action === "Invite") {
        const accessToken = await getAccessTokenSilently();

        const response = await axios.get(
          `${API_BASE_URL}/User/Invite`,
          {
            params: {
              UserId: selectedRow.userId,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("User invited successfully");

          setRowsData((prevRows: RowData[]) =>
            prevRows.map((row: RowData) => {
              if (row.Id === rowId) {
                return {
                  ...row,
                  status: "Invited",
                  action: "Undo",
                  isActive: true,
                };
              }
              return row;
            })
          );
        }
      } else {
        setRowsData((prevRows: RowData[]) =>
          prevRows.map((row: RowData) => {
            if (row.Id === rowId) {
              return {
                ...row,
                status: "Not invited",
                action: "Invite",
                isActive: false,
              };
            }
            return row;
          })
        );
      }
    } catch (error) {
      console.error("Error inviting user:", error);
    }
  };

  // const updateProperty = (id: string, updatedListings: number[]) => {
  //   setAddedProperties((prevProperties) =>
  //     prevProperties.map((property) =>
  //       property.id === id
  //         ? { ...property, listingIds: updatedListings }
  //         : property
  //     )
  //   );
  //   setSelectedPropertyId("");
  //   setSelectedListings([]);
  // };

  const [isFormValid, setIsFormValid] = useState(false);

  const handleSaveTeamMember = (isFormValid: boolean) => {
    setIsFormValid(isFormValid);
  };

  const handleAddProperty = async (selectedListings: number[]) => {
    if (!selectedUserId) {
      console.error("Seçili bir kullanıcı bulunamadı!");
      return;
    }

    if (selectedListings.length === 0) {
      console.log("Seçili property yok!");
      // setAddedProperties((prevProperties) =>
      //   prevProperties.filter((property) => property.id !== selectedPropertyId)
      // );
    } else {
      try {
        const accessToken = await getAccessTokenSilently();

        console.log("POST isteği başlıyor...");
        console.log("Seçilen userId:", selectedUserId);
        console.log("Seçilen property'ler:", selectedListings);

        const response = await axios.post(
          `${API_BASE_URL}/User/AddProperty`,
          {
            userId: selectedUserId,
            propertyIds: selectedListings,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log("Properties başarıyla atandı:", response.data);
        console.log("işlem başarılıyor:", response.data);

        setRowsData((prevRows) =>
          prevRows.map((row) =>
            row.userId === selectedUserId
              ? { ...row, listingResponsibleIds: selectedListings }
              : row
          )
        );
      } catch (error) {
        console.error("Property atama hatası:");
      }
    }

    setSelectedListings([]);
    setOpenListingModal(false);
    // setSelectedPropertyId("");
    setSelectedUserId("");
  };

  const handleAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleSaveAddModal = async () => {
    if (!formData) {
      console.log("Form verisi boş, işlem iptal ediliyor...");
      return;
    }

    const selectedRole = roles.find((role) => role.name === formData.role);

    if (!selectedRole) {
      console.error("Seçili rol bulunamadı!");
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently();
      const nameParts = formData.name.split(" ");
      const firstName = nameParts[0] || "";
      const lastName = nameParts.slice(1).join(" ") || "";

      const requestData = {
        customerId: userDetails?.customerId ?? "",
        firstName: firstName,
        lastName: lastName,
        email: formData.email,
        phoneNumber: formData.phone,
        providerCode: selectedRole.providerCode || "default",
        channelId: "",
        isActive: true,
        propertyIds: formData.listingIds || [],
        tags: [],
        roles: [selectedRole.id],
      };

      console.log("Gönderilecek veri:", requestData);

      const response = await axios.post(
        `${API_BASE_URL}/User/Add`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const newMember: RowData = {
          Id: members?.length + 1,
          userId: response.data.id,
          name: `${firstName} ${lastName}`.trim(),
          email: formData.email,
          tags: [],
          role: selectedRole.name,
          listingResponsibleIds: formData.listingIds || [],
          status: "Invited",
          action: "Undo",
          isActive: true,
        };

        setRowsData((prevRows) => [...prevRows, newMember]);
        setOpenAddModal(false);
        setFormData(null);

        console.log("Takım üyesi başarıyla eklendi");
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error("Takım üyesi ekleme hatası:", axiosError);

      if (axiosError.response?.data) {
        console.error("API Hata detayları:", axiosError.response.data);
        const validationErrors = axiosError.response.data.validationErrors;
        if (validationErrors) {
          validationErrors.forEach((err: any) => {
            console.log(`Hata: ${err.propertyName} - ${err.errorMessage}`);
          });
        }
      }
    }
  };

  const handleFormSave = (data: TeamMemberFormData) => {
    setFormData(data);
  };

  useEffect(() => {
    if (members && members.length > 0) {
      const formattedMembers: RowData[] = members.map((member) => ({
        Id: member.Id,
        name: member.name || "",
        email: member.email || "",
        userId: member.userId as unknown as string,
        tags: [],
        role: member.role || "",
        listingResponsibleIds: [],
        status: member.isActive ? "Invited" : "Not invited",
        action: member.isActive ? "Undo" : "Invite",
        isActive: member.isActive,
      }));

      setRowsData(formattedMembers);
    }
  }, [members]);

  return (
    <Stack spacing={4}>
      {isSetting &&
        <Stack spacing={1} justifyContent={"center"} textAlign={"center"}>
          <p className="titleOfStep">Invite Team member</p>
          <p className="secondaryDescription">
            Review and confirm the details of your imported properties to ensure
            accuracy
          </p>
        </Stack>
      }
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <p className="listingTitle">{members?.length} members </p>
          <Stack direction={"row"} spacing={2}>
            <CustomButton
              icon={SyncIcon}
              backgroundColor="#0065E0"
              color="#FFFFFF"
              onClick={() => ""}
              buttonText="Sync new member"
              buttonWidth="auto"
              marginTop={0}
            />
            <CustomButton
              icon={PlusIconWhite}
              backgroundColor="#0065E0"
              color="#FFFFFF"
              onClick={handleAddModal}
              buttonText="Add"
              buttonWidth="auto"
              marginTop={0}
            />
          </Stack>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            checkboxSelection={true}
            rows={rowsData}
            columns={getColumns()}
            disableRowSelectionOnClick
            rowHeight={100}
            hideFooter={true}
            getRowId={(row) => row.Id}
            sx={{
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}

          />
        </Box>
      </Stack>
      <CustomModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSave={handleSaveModal}
        title="Select role"
      >
        <SelectRoleModal
          displayedOptions={roles.map((role) => role.name)}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          roles={roles} // Pass the roles array
          disabled={!members?.find((row) => row.Id === selectedRowId)?.isActive}
        />
      </CustomModal>
      <CustomModal
        open={openListingModal}
        handleClose={handleCloseListingModal}
        handleSave={() => { }}
        title="Listing Responsibility"
        showFooter={false}
      >
        <ListingModal
          handleAddProperty={handleAddProperty}
          selectedListings={selectedListings}
          selectedUserId={selectedUserId}
          userProperties={userProperties}
          onRemoveProperty={handleRemoveProperty}
        />
      </CustomModal>
      <CustomModal
        open={openAddModal}
        handleClose={handleCloseAddModal}
        handleSave={handleSaveAddModal}
        title="Invite team member"
        description="Invite team member so they can perform actions regarding these listings"
        blueButtonText="Invite"
      >
        <AddTeamMemberModal
          isFormValid={isFormValid}
          onSave={handleSaveTeamMember}
          handleSave={handleFormSave}
        />
      </CustomModal>
    </Stack>
  );
}

export default InviteTeamMember;
