import { IconChevronDown } from '@tabler/icons-react';
import React, { useState, useRef, useEffect } from 'react';
import "./CustomSelect.scss"

interface CustomSelectProps {
	options: { value: string; label: string }[];
	value: React.ReactNode
	onChange: (value: string) => void;
	dropIcon?: boolean
	closeNow?: boolean
}

const CustomSelect = ({ options, value, dropIcon, onChange, closeNow }: CustomSelectProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const selectRef = useRef<HTMLDivElement>(null);

	// Close dropdown when clicking outside
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	const closeDropdown = () => {
		setIsOpen(false)
	}

	const _handleOnClick = (option: any) => {
		onChange(option.value);
		if (closeNow !== undefined) {
			if (closeNow === true) {
				closeDropdown();
			}
		} else {
			closeDropdown();
		}
	}

	const selectedOption = options.find((option) => option.value === value);

	return (
		<div ref={selectRef} className="custom-select no-w">
			<div className="cs-header" onClick={() => setIsOpen(!isOpen)} >
				{React.isValidElement(value) ? value : selectedOption?.label || value}
				{dropIcon && <IconChevronDown size={16} />}
			</div>
			<div className={`cs-dropdown ${isOpen && "open"}`} >
				{options.map((option) => (
					<div className="cs-option" key={option.value}
						onClick={() => { _handleOnClick(option) }}
					>
						{option.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomSelect;
