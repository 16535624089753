import {
  IconBrandAirbnb,
  IconBrandBooking,
  IconBrandWhatsapp,
  IconMessages,
} from "@tabler/icons-react";
import { ProviderType, MessageType } from "../../../types/provider";
import Guesty from "../../../assets/images/icons/guesty.svg";
import Hostaway from "../../../assets/images/icons/hostaway.svg";

interface ProviderImageProps {
  source: ProviderType | string;
  size?: number;
  opacity?: number;
  type?: MessageType;
}

const ProviderImage = ({
  source,
  size = 24,
  opacity = 0.5,
  type = "booking",
}: ProviderImageProps) => {
  const textLogoStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
    fontSize: `${size * 0.4}px`,
    fontWeight: "600",
    color: "#666",
    textTransform: "uppercase" as const,
    opacity,
  };

  const sourceType = source ? source.toLowerCase() : "" as ProviderType;

  // Message specific sources
  if (type === "message") {
    switch (sourceType) {
      case ProviderType.MANUAL:
        return <IconMessages />;
      case ProviderType.WHATSAPP:
        return <IconBrandWhatsapp />;
      case ProviderType.AIRBNB:
      case ProviderType.AIRBNB2:
        return <IconBrandAirbnb />;
      case ProviderType.BOOKING:
      case ProviderType.BOOKING2:
        return <IconBrandBooking />;
    }
  }

  // Booking sources
  switch (sourceType) {
    case ProviderType.GUESTY:
      return (
        <img
          src={Guesty}
          alt="guesty-platform"
          style={{
            width: "24px",
            height: "24px",
            flexShrink: 0,
            opacity: "0.5",
          }}
        />
      );
    case ProviderType.AIRBNB:
    case ProviderType.AIRBNB2:
      return <IconBrandAirbnb size={size} opacity={opacity} />;
    case ProviderType.HOSTAWAY:
      return (
        <img
          src={Hostaway}
          alt="hostaway-platform"
          style={{
            width: "24px",
            height: "24px",
            flexShrink: 0,
            opacity: "0.5",
          }}
        />
      );
    case ProviderType.LODGIFY:
      return <div style={textLogoStyle}>L</div>;
    case ProviderType.BOOKING:
    case ProviderType.BOOKING2:
      return <IconBrandBooking size={size} opacity={opacity} />;
    default:
      return (
        <span
          style={{ fontSize: `${size * 0.5}px`, color: "#667085", opacity }}
        >
          {source.length > 1
            ? source.charAt(0).toUpperCase() + source.slice(1)
            : "Unknown"}
        </span>
      );
  }
};

export default ProviderImage;
