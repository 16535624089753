import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import TitleBoxH3 from "../../../../../../../components/shared-components/TitleBoxH3";
import Coverphoto from "../../../../../../../assets/images/cover-photo.jpeg";
import ArrowsHorizontalSvg from "../../../../../../../assets/images/icons/arrows-horizontal.svg";
import CustomModal from "../../../../../../../components/shared-components/CustomModal";
import MainTextField from "../../../../../../../components/core-components/inputs/TextField/MainTextField";
import { useTranslation } from "react-i18next";
import CustomModalMobile from "../../../../../../../components/shared-components/CustomModalMobile";

interface AllPhotosProps {
  setCoverPhoto: (photo: string) => void;
}

function AllPhotos({ setCoverPhoto }: AllPhotosProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [photos, setPhotos] = useState<string[]>(new Array(6).fill(Coverphoto));
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [editingPhotoIndex, setEditingPhotoIndex] = useState<number | null>(
    null
  );
  const [photoDescriptionLength, setPhotoDescriptionLength] =
    useState<number>(0);
  const [photoDescription, setPhotoDescription] = useState<string>("");
  const [photoDescriptions, setPhotoDescriptions] = useState<string[]>(
    new Array(6).fill("")
  );
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);


  const handleCloseMobileModal = () => {
    setIsMobileModalOpen(false);
  };

  const handleMobileModalSave = () => {
    setIsMobileModalOpen(false);
  };

  const handleMenuButtonClick = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const handleEditButtonClick = (index: number) => {
    setEditingPhotoIndex(index);
    setPhotoDescription(photoDescriptions[index] || "");
    setPhotoDescriptionLength((photoDescriptions[index] || "").length);
    if (isMobile) {
      setIsMobileModalOpen(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDescription = event.target.value;
    if (newDescription.length <= 50) {
      setPhotoDescription(newDescription);
      setPhotoDescriptionLength(newDescription.length);
    }
  };

  const handleDescriptionSave = () => {
    if (editingPhotoIndex !== null) {
      setPhotoDescriptions((prevDescriptions) => {
        const newDescriptions = [...prevDescriptions];
        newDescriptions[editingPhotoIndex] = photoDescription;
        return newDescriptions;
      });
    }
    handleModalClose();
  };

  const handleChangePhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (
          file &&
          (file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png")
        ) {
          if (file.size > 25 * 1024 * 1024) {
            alert(t("onboardingpages.step2.invalidFileSize"));
            return;
          }

          if (photos.length >= 200) {
            alert(t("onboardingpages.step2.max200imagesPerProperty"));
            return;
          }

          const img = new Image();
          img.onload = () => {
            if (img.width > 1024 || img.height > 683) {
              alert(t("onboardingpages.step2.invalidFileSize"));
              return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
              if (reader.result) {
                setPhotos((prevPhotos) => [
                  ...prevPhotos,
                  reader.result as string,
                ]);
              }
            };
            reader.readAsDataURL(file);
          };
          img.src = URL.createObjectURL(file);
        } else {
          alert(t("onboardingpages.step2.invalidFileSize"));
        }
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenuIndex(null);
      }
    };

    if (openMenuIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuIndex]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeletePhoto = (index: number) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    setOpenMenuIndex(null);
  };

  const handleMoveForward = (index: number) => {
    setPhotos((prevPhotos) => {
      const newPhotos = [...prevPhotos];
      if (index < newPhotos.length - 1) {
        [newPhotos[index], newPhotos[index + 1]] = [
          newPhotos[index + 1],
          newPhotos[index],
        ];
      }
      return newPhotos;
    });
    setOpenMenuIndex(null);
  };


  const handleMakeCoverPhoto = (index: number) => {
    setCoverPhoto(photos[index]);
    setOpenMenuIndex(null);
  };

  return (
    <Stack spacing={3} id="all-photos">
      <TitleBoxH3
        title={t("detailsPages.details.allPhotos")}
        subtitle={t("detailsPages.details.dragAndDropPhotosToChangeOrder")}
        borderBottom={false}
      >
        <button
          className="change-photo-button B-13-13-600"
          onClick={handleButtonClick}
        >
          {t("detailsPages.details.uploadPhoto")}
        </button>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleChangePhoto}
          multiple
        />
      </TitleBoxH3>
      <Grid container spacing={0} style={{ margin: 0, width: "100%" }}>
        {React.Children.toArray(
          photos.map((photo, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              // key={index}
              style={{
                marginBottom: "24px",
                paddingRight: "16px",
                width: "260px",
                height: "194px",
              }}
            >
              <Stack style={{ position: "relative" }}>
                <button
                  className="all-photos-menu-button"
                  onClick={() => handleMenuButtonClick(index)}
                >
                  <img src={ArrowsHorizontalSvg} alt="arrows" />
                </button>
                {openMenuIndex === index && (
                  <Stack
                    ref={menuRef}
                    className="all-photos-uploaded-photo-menu"
                  >
                    {[
                      t("detailsPages.details.edit"),
                      t("detailsPages.details.moveForward"),
                      t("detailsPages.details.makeCoverPhoto"),
                      t("detailsPages.details.delete"),
                    ].map((item, itemIndex) => (
                      <button
                        key={itemIndex}
                        className="menu-item-button B-12-12-500"
                        onClick={
                          item === t("detailsPages.details.edit")
                            ? () => handleEditButtonClick(index)
                            : item === t("detailsPages.details.moveForward")
                              ? () => handleMoveForward(index)
                              : item === t("detailsPages.details.delete")
                                ? () => handleDeletePhoto(index)
                                : item ===
                                  t("detailsPages.details.makeCoverPhoto")
                                  ? () => handleMakeCoverPhoto(index)
                                  : undefined
                        }
                      >
                        {item}
                      </button>
                    ))}
                  </Stack>
                )}
                <img
                  src={photo}
                  alt={`uploaded ${index}`}
                  className="all-photos-uploaded-photo"
                />
                <Stack
                  mt={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <p className="upload-photos-photo-description">
                    {photoDescriptions[index] ||
                      `Uploaded Photo ${index + 1}`}
                  </p>
                </Stack>
              </Stack>
            </Grid>
          ))
        )}
      </Grid>

      <CustomModalMobile
        open={isMobileModalOpen}
        handleClose={handleCloseMobileModal}
        handleSave={handleMobileModalSave}
        title="Edit photo"
        showFooter={true}
      >
        <Stack spacing={3} pb={4}>
          <img
            src={photos[editingPhotoIndex as number]}
            alt="uploaded"
            style={{
              width: "100%",
              height: "300px",
              flexShrink: 0,
              borderRadius: "12px",
            }}
          />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Stack spacing={1}>
              <p className="cover-photo-title">
                {t("detailsPages.details.photoDetails")}
              </p>
              <p className="cover-photo-subtitle">
                {t(
                  "detailsPages.details.mentionWhatsSpecialAboutThisSpaceLikeComfortableFurnitureOrFavoriteDetails"
                )}
              </p>
            </Stack>
            <Stack spacing={1}>
              <MainTextField
                onChange={handleDescriptionChange}
                value={photoDescription}
                placeholder={t(
                  "detailsPages.details.theBestPlaceInNearTheLake"
                )}
                multiline
                maxLength={50}
              />
              <p className="B-12-12-700" style={{ color: "#7E8299" }}>
                {photoDescriptionLength}/50
              </p>
            </Stack>
          </Stack>
        </Stack>
      </CustomModalMobile>

      <CustomModal
        open={openModal}
        handleClose={handleModalClose}
        handleSave={handleDescriptionSave}
        title={t("detailsPages.details.editPhoto")}
      >
        <Stack direction="row" spacing={3}>
          <img
            src={photos[editingPhotoIndex as number]}
            alt="uploaded"
            style={{
              width: "560px",
              height: "380px",
              flexShrink: 0,
              borderRadius: "12px",
            }}
          />
          <Stack spacing={2} sx={{ maxWidth: "340px" }}>
            <Stack spacing={1}>
              <p className="cover-photo-title">
                {t("detailsPages.details.photoDetails")}
              </p>
              <p className="cover-photo-subtitle">
                {t(
                  "detailsPages.details.mentionWhatsSpecialAboutThisSpaceLikeComfortableFurnitureOrFavoriteDetails"
                )}
              </p>
            </Stack>
            <Stack spacing={1}>
              <MainTextField
                onChange={handleDescriptionChange}
                value={photoDescription}
                placeholder={t(
                  "detailsPages.details.theBestPlaceInNearTheLake"
                )}
                multiline
                maxLength={50}
              />
              <p className="B-12-12-700" style={{ color: "#7E8299" }}>
                {photoDescriptionLength}/50
              </p>
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
    </Stack >
  );
}

export default AllPhotos;
