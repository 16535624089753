import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Chip, } from "@mui/material";
import "./listing.scss";
import ReservationImage from "../../../assets/images/reservation-img-1.jpeg";
import { useTranslation } from "react-i18next";
import DatagridPageLoading from "../../../components/shared-components/loadings/DatagridPageLoading";
import MessagesSearchTextfield from "../../../components/core-components/inputs/TextField/MessagesSearchTextfield";
import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import PlusIcon from "../../../assets/images/icons/plus-arrows.svg";
import DocumentUploadComponent from "../../../components/shared-components/FileUploadComponent/DocumentUploadComponent";
import { Pill, Flex, Text, Badge } from "@mantine/core";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { PropertyLite } from "../../../types";
import { getAllProperties, getPropertyTags, handleModalFiles, handleUploadSave } from "../../../store/slices/propertySlice";
import { IconFileTypePdf } from "@tabler/icons-react";


export default function Listing({ hasHeader }: { hasHeader?: boolean }) {
  const {
    properties,
    propertiesLoading,
    propertiesMetaData,
    selectedPropertyId,
    modalFiles
  } = useSelector((state: RootState) => state.property)

  const dispatch: AppDispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0();


  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getPropertyTags({ accessToken }))
      if (properties.length === 0) {
        dispatch(getAllProperties({ accessToken, PageSize: "20", PageIndex: "1" }))
      }
    }
    fetchTags()
  }, []);

  /// const handleStatusChipClick = (status: string) => {
  ///   setSelectedStatus(status);
  ///   setTempStatus(status);
  ///   setOpen(true);
  /// };

  const handleUploadOpen = (id: string) => {
    dispatch(handleModalFiles(id))
    setIsUploadModalOpen(true);
  };

  const handleUploadClose = () => {
    setIsUploadModalOpen(false);
  };

  const EmptyState = () => (
    <Flex align="center" h="500px" w="%100" justify="center" >
      <Text>
        Couldn't find any property
      </Text>
    </Flex>
  );

  const getColumns = (): GridColDef<any[number]>[] => [
    {
      field: "name",
      headerName: t("listingpage.listingName"),
      disableColumnMenu: true,
      flex: 3,
      align: "center",
      renderCell: (params) => (
        <Flex gap={10} align="center" h="100%" >
          <img src={ReservationImage} alt="reservation" className="reservation-image" />
          <Flex align="flex-start" wrap="wrap" direction="column" justify="center" h="100%" >
            <Text size="sm" style={{ textWrap: "wrap" }} ta="left">
              {params.row.internalName}
            </Text>
            <Text size="xs" c="dimmed">
              {params.row.city}
            </Text>
          </Flex>
        </Flex>
      ),
    },
    {
      field: "location",
      headerName: t("listingpage.location"),
      disableColumnMenu: true,
      flex: 2,
      align: "left",
      renderCell: (params) => (
        <Flex gap={10} align="center" h="100%" >
          <Text size="sm" style={{ textWrap: "wrap" }}>{params.row.address}</Text>
        </Flex>
      ),
    },
    {
      field: "agent",
      headerName: t("listingpage.agent"),
      disableColumnMenu: true,
      flex: 2,
      align: "center",
      renderCell: (params) => (
        <Flex gap={10} align="center" h="100%" >
          <Text size="sm" style={{ textWrap: "wrap" }}>
            {params.row.propertyManager || <Text span c="dimmed">No Manager</Text>}
          </Text>
        </Flex>
      ),
    },
    {
      field: "typeCode",
      headerName: "Type Code",
      flex: 1,
      align: "left",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Flex gap={10} align="center" h="100%" >
          <Text size="sm" style={{ textWrap: "wrap" }}>
            {params.row.typeCode}
          </Text>
        </Flex>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      disableColumnMenu: true,
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isActive = params.row.status === "Active"
        return (
          <Flex align="center" justify="center" h="100%" w="100%">
            <Pill size="md" bg={`${isActive ? "green" : "orange"}.1`} c={`${isActive ? "green" : "orange"}.9`} >
              {params.row.status}
            </Pill>
          </Flex >
        )
      },
    },

    {
      field: "tags",
      headerName: "Tags",
      disableColumnMenu: true,
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Flex gap={5} align="center" justify="flex-start" h="100%" w="fit-content">
          {params.row.tags.slice(0, 2).map((tag: { id: string; tag: string }) => (
            <Badge miw="fit-content" color="blue.5" key={tag.id} size="lg">
              {tag.tag}
            </Badge>
          ))}
          {params.row.tags.length > 2 && (
            <Text>
              {"+" + (params.row.tags.length - 2)}
            </Text>
          )}
        </Flex>
      ),
    },
    {
      field: "files",
      headerName: "Attach Document",
      disableColumnMenu: true,
      flex: 3,
      align: "left",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const documents = params.row.files || [];
        const displayedDocuments = documents.length > 0 ? documents.slice(0, 2) : [];
        const extraDocsCount = documents.length > 0 ? documents.length - 2 : [];

        return (
          <Flex gap={10} align="center" justify="flex-start" h="100%" w="fit-content">
            <Badge
              leftSection={<img src={PlusIcon} alt="plus-icon" style={{ paddingLeft: 4 }} />}
              onClick={() => handleUploadOpen(params.row.id)}
              variant="outline"
              color="gray.6"
              miw={120}
              style={{ cursor: "pointer" }}
            >
              Document
            </Badge>
            {displayedDocuments.map((document: any, index: number) => (
              <Flex key={index} wrap="wrap" >
                <Badge
                  leftSection={<IconFileTypePdf />}
                  onClick={() => handleUploadOpen(params.row.id)}
                  variant="outline"
                  color="gray.7"
                  miw={120}
                  style={{ cursor: "pointer" }}
                >
                  {document.name}
                </Badge>
              </Flex>
            ))}
            {extraDocsCount > 0 && (
              <Chip
                label={`+${extraDocsCount}`}
                className="tag"
                onClick={() => handleUploadOpen(params.row.id)}
                style={{
                  color: "#5E6278",
                  backgroundColor: "transparent",
                  border: "1px solid #D8D8E5",
                  cursor: "pointer",
                }}
              />
            )}
          </Flex>
        );
      },
    },
    {
      field: "conversationRagStatus",
      headerName: "AI Ready",
      disableColumnMenu: true,
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Flex align="center" justify="center" h="100%" w="100%">
            {!!params.row.conversationRagStatus ? <Pill size="md" bg="green.1" c="green.9">Ready</Pill> : <Pill size="md" bg="orange.1" c="orange.9">In Progress</Pill>}
          </Flex>
        );
      },
    },
    /**{
      field: "source",
      headerName: t("listingpage.source"),
      disableColumnMenu: true,
      flex: 0.8,
      align: "left",
      renderCell: (params) => (
        <div className="alignLeft">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "61px", height: "20px" }}
          />
        </div>
      ),
    }, */

    {
      field: "lastModified",
      headerName: t("listingpage.lastModified"),
      disableColumnMenu: true,
      align: "center",
      flex: 1,
      renderCell: () => (
        <div
          style={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
          className="B-14-14-600"
        >
          12.02.2024
        </div>
      ),
    },
    //  {
    //    field: "edit",
    //    headerName: "",
    //    disableColumnMenu: true,
    //    align: "center",
    //    hideSortIcons: true,
    //    sortable: false,
    //    flex: 0.5,
    //    renderCell: (params) => (
    //      <Link to="/listings/id/details">
    //        <img className="editIcon" src={edit} alt="edit" />
    //      </Link>
    //    ),
    //  },
  ];

  const loadMore = async (page: number) => {
    if (page > (propertiesMetaData?.pageIndex ?? 1)) {
      const accessToken = await getAccessTokenSilently();
      const page = (propertiesMetaData?.pageIndex ?? 0) + 1
      dispatch(
        getAllProperties({
          PageIndex: String(page),
          PageSize: "20",
          accessToken,
        })
      );
    }
  };

  const filteredRows = properties.filter(
    (pro: PropertyLite) =>
      pro.name.toLowerCase().includes(searchText.toLowerCase()) ||
      pro.internalName.toLowerCase().includes(searchText.toLowerCase()) ||
      pro.city.toLowerCase().includes(searchText.toLowerCase()) ||
      pro.address.toLowerCase().includes(searchText.toLowerCase()) ||
      pro.typeCode.toLowerCase().includes(searchText.toLowerCase()) ||
      pro.tags.some((tag: any) => typeof tag === "string" && tag.toLowerCase().includes(searchText.toLowerCase()))
  );

  if (propertiesLoading && !filteredRows) return <DatagridPageLoading />
  return (
    <Flex direction="column" gap={20} p={50} h={`calc(100vh - ${hasHeader ? 150 : 80}px)`}>
      {hasHeader &&
        <Flex direction="column" gap={10} justify="center" align="center" >
          <Text size="24px">Review Your Imported Properties</Text>
          <Text size="20px" c="dimmed">
            Review and confirm the details of your imported properties to ensure
            accuracy
          </Text>
        </Flex>}
      <MessagesSearchTextfield
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        height="38px"
      />
      {filteredRows?.length > 0 ? (
        <DataGrid
          rows={filteredRows}
          columns={getColumns()}
          rowHeight={100}
          rowCount={propertiesMetaData?.totalCount}
          getRowId={(row) => row.id}
          onPaginationModelChange={(data: any) => { loadMore(data.page + 1) }}
          disableRowSelectionOnClick
          loading={propertiesLoading}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 20, page: 0 },
            },
          }}
          sx={{
            ".MuiDataGrid-root": {
              minHeight: "100%"
            },
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          }}
        />
      ) : (
        <EmptyState />
      )}
      <DocumentUploadComponent
        opened={isUploadModalOpen}
        close={handleUploadClose}
        handleSave={(files) => { dispatch(handleUploadSave(files)) }}
        existingFiles={modalFiles}
        propertyId={selectedPropertyId}
      />
    </Flex >
  );
}
