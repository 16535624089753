import { useMutation } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { CHANNEL_CONNECT_API } from "./_apiConstants";
import { useToast } from "../contexts/ToastContext";
import { AUDIENCE_API_URL } from ".";

interface ConnectionData {
  title?: string;
  clientId?: string;
  token?: string;
  clientSecret?: string;
  providerType?: string;
}

interface APIResponse {
  isSuccess: boolean;
  clientId?: string;
  clientSecret?: string;
  message?: string;
}

//TODO: MOVE THIS TO STATE ASP!
const useConnectionAPI = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  const callConnectionAPI = async (data: ConnectionData) => {
    try {
      const authToken = await getAccessTokenSilently();
      const savedInfo = sessionStorage.getItem("guestyTokenInfo");
      const savedTokenInfo = savedInfo ? JSON.parse(savedInfo) : null;

      const hasValidTokenInfo = "" || savedTokenInfo;

      let params;
      if (data.providerType === "Hostaway") {
        params = {
          title: data.title,
          clientId: data.clientId,
          clientSecret: data.clientSecret,
          channelType: data.providerType,
        };
      } else if (data.providerType === "Lodgify") {
        params = {
          title: data.title,
          clientId: data.clientSecret,
          channelType: data.providerType,
        };
      } else {
        params = hasValidTokenInfo ? {
          clientId: ("" || savedTokenInfo).clientId,
          clientSecret: ("" || savedTokenInfo).clientCode,
          //clientSecret: (tokenInfo || savedTokenInfo).clientCode,
          title: data.title,
          channelType: data.providerType,
        }
          : {
            title: data.title,
            token: data.token,
            channelType: data.providerType,
          };
      }

      console.log("Request params:", params);

      const response = await axios.get<APIResponse>(CHANNEL_CONNECT_API, {
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          audience: AUDIENCE_API_URL
        },
      });

      if (response.status >= 400) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      if (!response.data.isSuccess) {
        throw new Error(response.data.message || "Connection failed");
      }

      if (
        !hasValidTokenInfo &&
        response.data?.clientId &&
        response.data?.clientSecret
      ) {
        const newTokenInfo = {
          clientId: response.data.clientId,
          clientCode: response.data.clientSecret,
        };
        //setTokenInfo(newTokenInfo);
        console.log("Token info saved:", newTokenInfo);
      }

      showToast(
        {
          Message: "Connection successful",
          title: "Success",
        },
        "success"
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error("API error:", error);

      if (axios.isAxiosError(error)) {
        showToast(
          {
            Message:
              error.response?.data?.message ||
              error.message ||
              "An unknown error occurred",
            title: "API Error",
          },
          "error"
        );
      } else {
        showToast(
          {
            Message: (error as Error).message || "An unexpected error occurred",
            title: "API Error",
          },
          "error"
        );
      }

      throw error;
    }
  };

  return useMutation((data: ConnectionData) => callConnectionAPI(data));
};

export default useConnectionAPI;
