import { useState, useMemo } from "react";
import "./profile.scss";
import { Box, Chip, Stack, useMediaQuery, useTheme } from "@mui/material";
import HeaderTitle from "../settings/components/HeaderTitle";
import TitleBoxH3 from "../../../components/shared-components/TitleBoxH3";
import { useTranslation } from "react-i18next";
import CustomModalMobile from "../../../components/shared-components/CustomModalMobile";
import NameModal from "./components/NameModal";
import PhoneModal from "./components/PhoneModal";
import PasswordModal from "./components/PasswordModal";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../../components/shared-components/MobileHeader";
import CustomModal from "../../../components/shared-components/CustomModal";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { AxiosError } from "axios";

function Profile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();

  const [firstName, setFirstName] = useState<string>(user?.given_name || "");
  const [lastName, setLastName] = useState<string>(user?.family_name || "");
  const [phone, setPhone] = useState<string>(user?.phone || "");

  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const profileFields = useMemo(
    () => [
      {
        title: "Legal Name",
        subtitle: `${firstName} ${lastName}` || t("profilePage.notSet"),
      },
      {
        title: "Email",
        subtitle: user?.email || t("profilePage.notSet"),
      },
      {
        title: "Phone",
        subtitle: phone || t("profilePage.notSet"),
      },
      {
        title: "Password",
        subtitle: "********",
      },
    ],
    [firstName, lastName, phone, t]
  );

  const handleOpenModal = (index: number) => {
    if (index === 0) {
      setIsNameModalOpen(true);
    } else if (index === 2) {
      setIsPhoneModalOpen(true);
    } else if (index === 3) {
      setIsPasswordModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsNameModalOpen(false);
    setIsPhoneModalOpen(false);
    setIsPasswordModalOpen(false);
  };

  const handleNameModalSave = async (
    newFirstName: string,
    newLastName: string
  ) => {
    setFirstName(newFirstName);
    setLastName(newLastName);
    await updateUserInfo({ firstName: newFirstName, lastName: newLastName });
    setIsNameModalOpen(false);
  };

  const handlePhoneModalSave = async (newPhone: string) => {
    setPhone(newPhone);
    await updateUserInfo({ phoneNumber: newPhone });
    setIsPhoneModalOpen(false);
  };
  const handlePasswordModalSave = async (_: string) => {
    setIsPasswordModalOpen(false);
  };

  const token = getAccessTokenSilently();
  console.log("AccessToken : ", token);
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const updateUserInfo = async (data: { [key: string]: string }) => {
    try {
      const token = await getAccessTokenSilently();
      const payload = {
        firstName: data.firstName ?? firstName,
        lastName: data.lastName ?? lastName,
        phoneNumber: data.phoneNumber ?? phone,
      };

      const response = await axios.post(
        `${API_BASE_URL}/User/Update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Kullanıcı güncellendi:", response.data);

      if (response.data.firstName) setFirstName(response.data.firstName);
      if (response.data.lastName) setLastName(response.data.lastName);
      if (response.data.phoneNumber) setPhone(response.data.phoneNumber);

      const newToken = await getAccessTokenSilently({
        cacheMode: "off",
      });

      console.log("Yenilenmiş Token: ", newToken);
    } catch (error) {
      console.error("User update error:", error);
      if (error instanceof AxiosError) {
        console.log("Axios Hata Detayları:", error.response?.data);
      }
    }
  };

  return (
    <Box
      className={!isMobile ? "container" : ""}
      sx={{ width: { xs: "100%", sm: "auto" }, margin: "auto" }}
      padding={isMobile ? 2 : 0}
      pb={isMobile ? 10 : 0}
    >
      {isMobile && (
        <MobileHeader
          title="Profile Info"
          handleBackClick={() => navigate("/menu")}
        />
      )}
      <Stack
        spacing={isMobile ? 0 : 9}
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "flex-start"}
        mt={isMobile ? 3 : 5}
        mb={5}
        sx={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
      >
        <Stack alignItems="flex-start" sx={{ width: "100%" }}>
          <Stack className="acc-users-box" mb={isMobile ? 0 : 4} pl={0} pt={0}>
            {!isMobile && (
              <Box maxWidth={"812px"} className="acc-users-box-header">
                <HeaderTitle
                  title={t("profilePage.personalInfo")}
                  subtitle={t("profilePage.updateYourPersonalDetails")}
                />
              </Box>
            )}
          </Stack>
          <Stack
            direction={"row"}
            spacing={1}
            pt={0}
            paddingBottom={4}
            sx={{ borderBottom: "1px solid var(--stroke-colors, #EBEBEB)" }}
            width={"100%"}
          >
            <Stack
              spacing={1}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <p className="B-16-16-600">{user?.name}</p>
              <p className="B-14-20-400">{user?.email}</p>
            </Stack>
            <Stack alignSelf={"flex-end"}>
              <Chip
                label="Admin"
                className="B-12-12-600"
                sx={{
                  height: "26px",
                  padding: "7px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  background: "var(--Gray-Gray-300, #E1E3EA)",
                }}
              />
            </Stack>
          </Stack>
          {profileFields.map((field, index) => (
            <TitleBoxH3
              key={index}
              title={field.title}
              subtitle={field.subtitle}
            >
              {index !== 1 && (
                <button
                  className="profile-settings-edit-button B-14-16-600"
                  onClick={() => handleOpenModal(index)}
                >
                  {t("profilePage.edit")}
                </button>
              )}
            </TitleBoxH3>
          ))}
        </Stack>
      </Stack>

      {isMobile ? (
        <CustomModalMobile
          open={isNameModalOpen}
          handleClose={handleCloseModal}
          title="Name and Surname"
          showFooter={false}
          handleSave={handleCloseModal}
        >
          <NameModal
            initialFirstName={firstName}
            initialLastName={lastName}
            onSave={handleNameModalSave}
          />
        </CustomModalMobile>
      ) : (
        <CustomModal
          open={isNameModalOpen}
          handleClose={handleCloseModal}
          title="Name and Surname"
          showFooter={false}
          handleSave={handleCloseModal}
        >
          <NameModal
            initialFirstName={firstName}
            initialLastName={lastName}
            onSave={handleNameModalSave}
          />
        </CustomModal>
      )}

      {isMobile ? (
        <CustomModalMobile
          open={isPhoneModalOpen}
          handleClose={handleCloseModal}
          title="Phone"
          showFooter={false}
          handleSave={handleCloseModal}
        >
          <PhoneModal phone={phone} onSave={handlePhoneModalSave} />
        </CustomModalMobile>
      ) : (
        <CustomModal
          open={isPhoneModalOpen}
          handleClose={handleCloseModal}
          title="Phone"
          showFooter={false}
          handleSave={handleCloseModal}
        >
          <PhoneModal phone={phone} onSave={handlePhoneModalSave} />
        </CustomModal>
      )}

      {isMobile ? (
        <CustomModalMobile
          open={isPasswordModalOpen}
          handleClose={handleCloseModal}
          title="Password"
          showFooter={false}
          handleSave={handleCloseModal}
        >
          <PasswordModal
            onClose={handleCloseModal}
            onSave={handlePasswordModalSave}
          />
        </CustomModalMobile>
      ) : (
        <CustomModal
          open={isPasswordModalOpen}
          handleClose={handleCloseModal}
          title="Password"
          showFooter={false}
          handleSave={handleCloseModal}
        >
          <PasswordModal
            onClose={handleCloseModal}
            onSave={handlePasswordModalSave}
          />
        </CustomModal>
      )}
    </Box>
  );
}

export default Profile;
