import { configureStore } from "@reduxjs/toolkit";
import teamMembersReducer from "./slices/teamMembersSlice";
import messageReducer from "./slices/messageSlice"
import chatReducer from "./slices/chatSlice"
import filterReducer from "./slices/filterSlice"
import globalReducer from "./slices/globalSlice"
import onboardReducer from "./slices/onboardSlice"
import propertyReducer from "./slices/propertySlice"
import reservationReducer from "./slices/reservationSlice"
import { filterMiddleware } from "./middleware/filterMiddleware";
import autopilotSlice from "./slices/autopilotSlice";

export const store = configureStore({
  reducer: {
    teamMembers: teamMembersReducer,
    message: messageReducer,
    chat: chatReducer,
    filter: filterReducer,
    global: globalReducer,
    onboard: onboardReducer,
    property: propertyReducer,
    autopilot: autopilotSlice,
    reservation: reservationReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(filterMiddleware as any),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
