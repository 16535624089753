import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postReservationDetailsApi } from "../../api/onboardApi";
import { BASE_SIGNAL_API } from "../../api";
import signalRService from "../../signalr/SignalRService";
import { GlobalState } from "./globalSlice";
import { toast } from "react-toastify";



export interface OnboardState {
  currentStep: number
  connectionStarted: boolean
  stepsComplete: {
    [key: number]: boolean
  }
  onboardingData: {
    categories: any[],
    listingItems: any[],
    channels: any[],
    getStarted: {
      accountName: string,
      clientId: string,
      clientSecret: string
    },
    selectedProviderType?: number;
  }
  progressLeft: number,
  syncProgress: number
}

const initialState: OnboardState = {
  currentStep: 1,
  connectionStarted: false,
  stepsComplete: {
    1: true,
    2: false,
    3: false,
    4: false,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true
  },
  onboardingData: {
    categories: [],
    listingItems: [],
    channels: [],
    getStarted: {
      accountName: "",
      clientId: "",
      clientSecret: "",
    }
  },
  progressLeft: 0,
  syncProgress: 0,
}

export const initializeSignalR = createAsyncThunk(
  "onboard/initializeSignalR",
  async (token: string, { dispatch }) => {

    try {
      await signalRService.startConnection(BASE_SIGNAL_API as string);
      try {
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        if (tokenPayload) {
          const customerId = tokenPayload.customerId
          const email = tokenPayload.email

          if (customerId && email) {
            const connectUser = {
              CustomerId: customerId,
              UserName: email,
            };
            await signalRService.sendMessage("ConnectClient", connectUser);
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }

      signalRService.onReceiveMessage("AlreadyStartedWorkflow", () => {
        toast.warning('You already finished onboarding');
        dispatch(handleAlreadyOnboard())
      })

      signalRService.onReceiveMessage("StartPropertySync", (data: any) => {
        const parsedData = JSON.parse(data)
        if (parsedData.TotalCount && parsedData.BatchSize) {
          if (parsedData.TotalCount === 0) {
          } else {
            const progressLeft = Math.ceil(parsedData.TotalCount / parsedData.BatchSize)
            dispatch(startPropertySync({ progressLeft }))
          }
        } else {
          toast.warning('No new property were found to be added');
          dispatch(handleAlreadyOnboard())
          console.error("CANNOT FIND TOTAL COUNT / BATCH SIZE")
        }
      });

      signalRService.onReceiveMessage("EndPropertySync", () => {
        dispatch(setProgressCount(null))
      });

      return true;
    } catch (error) {
      console.error("SignalR initialization error:", error);
      return false;
    }
  }
);

export const postReservationDetails = createAsyncThunk(
  "onboard/postReservationDetails",
  async ({ accessToken }: { accessToken: string }, { getState }) => {
    try {
      const state = getState() as { onboard: OnboardState, global: GlobalState }
      const extraData = {
        accessToken,
        customerId: state.global.userDetails?.customerId ?? "",
        selectedProviderType: state.onboard.onboardingData.selectedProviderType ?? 0
      }
      const response = await postReservationDetailsApi(extraData)
      return response
    } catch (error: any) {
      console.error("API call error:", error);
    }
  }
)

const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },
    setOnboardingData: (state, action: PayloadAction<any>) => {
      state.onboardingData = action.payload
    },
    setStepsComplete: (state, action: PayloadAction<{ key: number, status: boolean }>) => {
      const { key, status } = action.payload
      state.stepsComplete = {
        ...state.stepsComplete,
        [key]: status
      }
    },
    handleAlreadyOnboard: (state) => {
      state.currentStep = 1
    },
    startPropertySync: (state, action) => {
      const { progressLeft } = action.payload
      state.progressLeft = progressLeft
      state.syncProgress = 20
    },
    setProgressCount: (state, action) => {
      if (action.payload) {
        state.syncProgress = action.payload //NOTE: if has action it means dev will be set manually
      } else if (state.progressLeft > 0) {
        //NOTE: if no payload reducer will auto handle progress bar,
        //progressLeft = check out how many more request will be come from EndPropertySync socket
        //We already set the progressLeft, we did it in first section which StartPropertySync socket
        //We use 80 as divier cause we manullay start progress bar from 20%, for user to understand app still working

        const nextSync = state.syncProgress + Math.ceil(80 / state.progressLeft)

        if (nextSync > 100) state.syncProgress = 100
        else state.syncProgress = nextSync


        state.progressLeft -= 1


        if (state.progressLeft === 0) { //NOTE: IF NO PROGRESS LEFT FINISH
          state.stepsComplete = {
            ...state.stepsComplete,
            [state.currentStep]: true
          }
          state.syncProgress = 100
          state.currentStep = 5
        }
      }
    },
    flushOnboardState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeSignalR.fulfilled, (state, action) => {
        state.connectionStarted = action.payload;
      })
      .addCase(initializeSignalR.rejected, (state) => {
        state.connectionStarted = false;
      })
  }
})

export const {
  setCurrentStep,
  setOnboardingData,
  setStepsComplete,
  startPropertySync,
  setProgressCount,
  flushOnboardState,
  handleAlreadyOnboard
} = onboardSlice.actions


export default onboardSlice.reducer;
