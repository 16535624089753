import React from "react";

const MessageFormatter = ({ text }: { text: string }) => {
  const decodeHTML = (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const formatMessage = (message: string) => {
    let formattedText = decodeHTML(message);
    
    // Remove CSS comments and @font-face rules
    formattedText = formattedText
      .replace(/\/\*[\s\S]*?\*\//g, '') // Remove CSS comments
      .replace(/@font-face\s*{[^}]*}/g, '') // Remove @font-face rules
      .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, ''); // Remove style tags and their content

    // Remove tracking and metadata
    formattedText = formattedText
      .replace(/<div class="tracking"[\s\S]*?<\/div>/gi, '')
      .replace(/<div class="preheader"[\s\S]*?<\/div>/gi, '')
      .replace(/<div class="gmailfix"[\s\S]*?<\/div>/gi, '')
      .replace(/<!--[\s\S]*?-->/g, '');

    // Remove CSS and unwanted attributes
    formattedText = formattedText
      .replace(/^a\s*{[^}]*};?/i, "")
      .replace(/\s+style="[^"]*"/g, "")
      .replace(/\s+class="[^"]*"/g, "")
      .replace(/\s+dir="[^"]*"/g, "");

    // Clean HTML structure
    formattedText = formattedText
      .replace(/<head>.*?<\/head>/gs, "")
      .replace(/<style>.*?<\/style>/gs, "")
      .replace(/<script>.*?<\/script>/gs, "")
      .replace(/<meta[^>]*>/gi, "")
      .replace(/<!DOCTYPE[^>]*>/gi, "");

    // Convert block elements to line breaks
    formattedText = formattedText
      .replace(/<div[^>]*>/gi, "")
      .replace(/<\/div>/gi, "\n")
      .replace(/<p[^>]*>/gi, "")
      .replace(/<\/p>/gi, "\n")
      .replace(/<br[^>]*>/gi, "\n")
      .replace(/<table[^>]*>/gi, "")
      .replace(/<\/table>/gi, "\n")
      .replace(/<tr[^>]*>/gi, "")
      .replace(/<\/tr>/gi, "\n")
      .replace(/<td[^>]*>/gi, " ")
      .replace(/<\/td>/gi, " ");

    // Keep safe formatting tags
    formattedText = formattedText
      .replace(/<b>(.*?)<\/b>/gi, "**$1**")
      .replace(/<strong>(.*?)<\/strong>/gi, "**$1**")
      .replace(/<i>(.*?)<\/i>/gi, "_$1_")
      .replace(/<em>(.*?)<\/em>/gi, "_$1_");

    // Convert links to markdown style
    formattedText = formattedText.replace(
      /<a[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi,
      (match, url, text) => {
        return url === text ? url : `${url} (${text})`;
      }
    );

    // Remove remaining HTML tags
    formattedText = formattedText.replace(/<[^>]+>/g, "");

    // Convert HTML entities
    formattedText = formattedText
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&mdash;/g, "—")
      .replace(/&ndash;/g, "–")
      .replace(/&hellip;/g, "...")
      .replace(/\\n/g, "\n")
      .replace(/\r\n/g, "\n")
      .replace(/\r/g, "\n");

    // Clean up whitespace
    formattedText = formattedText
      .replace(/\n\s*\n\s*\n/g, "\n\n")
      .replace(/[ \t]+/g, " ")
      .trim();

    // Process URLs and formatting
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const boldPattern = /\*\*(.+?)\*\*/g;
    const italicPattern = /_(.+?)_/g;

    const parts = formattedText.split(urlPattern);

    return parts.map((part, index) => {
      if (part.match(urlPattern)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            {part}
          </a>
        );
      }

      // Process bold and italic text
      let formatted = part.split(boldPattern).map((segment, i) => {
        if (i % 2 === 1) {
          return <strong key={`bold-${i}`}>{segment}</strong>;
        }
        return segment;
      });

      formatted = formatted.map((segment, i) => {
        if (typeof segment === 'string') {
          const italicParts = segment.split(italicPattern);
          return italicParts.map((italicSegment, j) => {
            if (j % 2 === 1) {
              return <em key={`italic-${i}-${j}`}>{italicSegment}</em>;
            }
            return italicSegment;
          });
        }
        return segment;
      }).flat() as (string | JSX.Element)[];

      // Handle line breaks
      return (
        <span key={`text-${index}`}>
          {formatted.map((item, i) => (
            <React.Fragment key={`fragment-${index}-${i}`}>
              {typeof item === 'string' 
                ? item.split('\n').map((line, j) => (
                    <React.Fragment key={`line-${index}-${i}-${j}`}>
                      {j > 0 && <br />}
                      {line}
                    </React.Fragment>
                  ))
                : item}
            </React.Fragment>
          ))}
        </span>
      );
    });
  };

  return (
    <div className="whitespace-pre-wrap break-words">
      {formatMessage(text)}
    </div>
  );
};

export default MessageFormatter;
