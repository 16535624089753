import React, { useCallback, useRef, useState } from "react";
import { Box, Stack, Button, Snackbar, Alert, Typography } from "@mui/material";
import Upload from "../../../../assets/images/icons/upload.svg";
import DeleteIcon from "../../../../assets/images/icons/delete.svg";

interface UploadPhotoAreaProps {
  resolution?: [number, number];
  maxSize?: number;
  docType?: string[];
  isInvalid?: boolean;
  isTitleShown?: boolean;
  onFilesChange?: (files: File[]) => void;
}
const UploadPhotoArea = ({
  maxSize = 25600,
  docType = ["image/jpeg", "image/png"],
  isInvalid = false,
  isTitleShown = true,
  onFilesChange,
}: UploadPhotoAreaProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validFiles = acceptedFiles.filter(
        (file) => docType.includes(file.type) && file.size <= maxSize * 1024
      );
      setImageFiles(
        validFiles.filter((file) => file.type.startsWith("image/"))
      );
      onFilesChange?.(validFiles);
    },
    [onFilesChange, docType, maxSize]
  );

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  function getReadableFileType(mimeType: string) {
    switch (mimeType) {
      case "application/pdf":
        return "pdf";
      case "text/csv":
        return "csv";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";
      case "application/vnd.apple.pages":
        return "pages";
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/svg+xml":
        return "svg";
      case "image/gif":
        return "gif";
      default:
        return mimeType;
    }
  }


  const handleClick = () => {
    const fileUpload = document.getElementById("file-upload");
    if (fileUpload) {
      fileUpload.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      onDrop(Array.from(files));
    }
    if (event.target) {
      event.target.value = "";
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Stack spacing={1} width={"100%"}>
      {isTitleShown && (
        <p className="B-14" style={{ color: "#3F4254" }}>
          Photo
        </p>
      )}

      <Stack
        sx={{
          width: "100%",
          height: "230px",
          border: isInvalid ? "2px dashed #D92D20" : "2px dashed #E0E0E0",
          borderRadius: "12px",
        }}
        className={"onboarding-main-photo-add"}
      >
        <input />
        {imageFiles.length > 0 ? (
          <Stack
            sx={{
              position: "relative",
              width: "100%",
              height: "230px",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={URL.createObjectURL(imageFiles[0])}
              alt="Uploaded"
              style={{
                width: "310px",
                height: "230px",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            />
            <Button
              sx={{
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                width: "38px",
                height: "38px",
                padding: "9px",
                borderRadius: "60px",
                background: "var(--white-dark-white, #FFF)",
                border: "1px solid var(--Gray-Gray-100, #E0E0E0)",
                zIndex: 99,
                ":hover": {
                  background: "var(--Gray-Gray-100, #E0E0E0)",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                setImageFiles((prev) => prev.filter((_, i) => i !== 0));
              }}
            >
              <img
                src={DeleteIcon}
                alt="remove-icon"
                style={{
                  display: "flex",
                  width: "20px",
                  height: "20px",
                  padding: "1.042px 2.494px 1.042px 2.492px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                }}
              />
            </Button>
          </Stack>
        ) : (
          <Stack
            sx={{ width: "100%", minWidth: "300px", height: "230px" }}
            className="onboarding-main-photo-add-area"
          >
            <button
              onClick={handleClick}
              className="onboarding-main-photo-add-area-button"
            >
              <img
                src={Upload}
                alt="upload-icon"
                className="onboarding-main-photo-add-area-button-icon"
              />
            </button>
            <input
              id="file-upload"
              ref={inputFileRef}
              type="file"
              accept=".jpg,.png,.pdf,.csv,.xlsx"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />

            <Box className="onboarding-main-photo-add-area-texts">
              <p className="onboarding-main-photo-add-area-text B-14-22-500">
                Drag or drop your files here or &nbsp;
                <label htmlFor="file-upload">
                  <Typography
                    component="span"
                    sx={{ color: "#0065E0", cursor: "pointer" }}
                  >
                    choose files
                  </Typography>
                </label>
              </p>
              <p className="onboarding-main-photo-add-area-subtext B-12-12-500">
                Max 25MB, support {docType.map(getReadableFileType).join(", ")}
              </p>
            </Box>
          </Stack>
        )}
      </Stack>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {""}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default UploadPhotoArea;
