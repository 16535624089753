import { IconButton, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "../../../../../assets/images/icons/searchColored.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import { FilterType } from "../../../../../api/messageApi";
import { getMessages, setFilterType } from "../../../../../store/slices/messageSlice";

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState<string>("")
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently } = useAuth0();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    const params = new URLSearchParams(window.location.search);
    if (e.target.value) {
      params.set("search", e.target.value);
    } const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", newUrl);
  };

  const handleCloseSearch = async () => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("search")) {
      params.delete("search");
      try {
        dispatch(setFilterType(FilterType.ALL))
      } catch (error) {
        console.error("Arama sıfırlama hatası:", error);
      }
    } else {
      dispatch(setFilterType(FilterType.ALL))
    }
  };



  useEffect(() => {
    const handler = setTimeout(async () => {
      try {
        if (searchValue.trim() !== "") {
          const accessToken = await getAccessTokenSilently();
          await dispatch(
            getMessages({
              accessToken,
              page: 1,
              limit: 20,
              searchKey: searchValue.trim(),
              filterType: FilterType.SEARCH
            })
          );
        }
      } catch (error) {
        console.error("Error during search:", error);
      }
    }, 1000);

    return () => clearTimeout(handler);
  }, [searchValue]);


  return (
    <>
      <TextField
        placeholder={t("reservationpage.search")}
        fullWidth
        value={searchValue}
        onChange={handleSearchChange}
        className="message-card-search-text-box"
        InputProps={{
          startAdornment: (
            <img
              src={SearchIcon}
              alt="search-icon"
              style={{
                width: "15px",
                height: "15px",
                flexShrink: 0,
                marginRight: "10px",
              }}
            />
          ),
        }}
      />
      <IconButton onClick={handleCloseSearch}>
        <CloseIcon style={{ width: "16px", height: "16px" }} />
      </IconButton>
    </>
  )
}

export default SearchBar;
