import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReservationDetails } from "../../types";
import { getAllReservationDetailsApi, GetAllReservationDetailsParams, getReservationDetailsApi, GetReservationDetailsParams } from "../../api/reservationApi";
import { RootState } from "../store";
import { h_isDateBetween } from "../../utils/helpers";
import dayjs from "dayjs";
import { rh_isMoreThanTwoMonthsApart } from "../utils/messageUtils";

interface ReservationState {
  bookingId: string
  reservationCache: { [bookingId: string]: ReservationDetails[] | null }
  reservationLoading: boolean,
  calendarLoading: boolean,
  lastUsedStartDate: string | null
}

const initialState: ReservationState = {
  reservationLoading: false,
  calendarLoading: false,
  reservationCache: {},
  bookingId: '',
  lastUsedStartDate: null
}


export const getReservationDetails = createAsyncThunk(
  "reservation/getReservationDetails",
  async (
    params: GetReservationDetailsParams,
    { rejectWithValue, getState, dispatch }
  ) => {

    const { reservation } = getState() as RootState
    if (reservation.reservationCache[params.bookingId]) {
      dispatch(setReservation(params.bookingId))
    } else {
      try {
        const reservationData = await getReservationDetailsApi(params);
        //dispatch(getAllReservationDetails(params))
        return { id: params.bookingId, reservationData }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  }
)


export const getAllReservationDetails = createAsyncThunk(
  "reservation/getAllReservationDetails ",
  async (
    params: GetAllReservationDetailsParams,
    { rejectWithValue, getState, dispatch }
  ) => {

    const { reservation } = getState() as RootState

    dispatch(setLastUsedDate(params.startPoint))
    if (
      reservation.reservationCache[params.propertyId] &&
      reservation.lastUsedStartDate &&
      !rh_isMoreThanTwoMonthsApart(reservation.lastUsedStartDate, params.startPoint)
    ) {
      dispatch(setReservation(params.propertyId))
    } else {
      try {
        const reservationData = await getAllReservationDetailsApi(params);
        return { id: params.propertyId, reservationData }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }

  }
)


const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setReservation(state, action: PayloadAction<string>) {
      state.bookingId = action.payload
    },
    setLastUsedDate(state, action: PayloadAction<string>) {
      state.lastUsedStartDate = action.payload
    },
    clearReservationState: (state) => {
      state.reservationCache = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReservationDetails.pending, (state, action) => {
        const params = action.meta.arg;
        state.calendarLoading = true
        if (!state.reservationCache[params.propertyId]) {
          state.reservationLoading = true
        }
      })
      .addCase(getAllReservationDetails.fulfilled, (state, action) => {
        if (action.payload) {
          const { id, reservationData } = action.payload

          if (reservationData.items) {
            if (!state.reservationCache[id]) {
              state.reservationCache[id] = null
            }
            state.bookingId = id
            if (reservationData.items) {
              if (Array.isArray(state.reservationCache[id])) {
                // TS is retared in here, I checked everthing but still gives error total retared
                //@ts-ignore 
                state.reservationCache[id] = reservationData.items;
              } else {
                // If reservationCache[id] is null, directly assign the items
                state.bookingId = id
                state.reservationCache[id] = reservationData.items;
              }
            }
          }
          state.reservationLoading = false
        }
        state.calendarLoading = false
      })
      .addCase(getAllReservationDetails.rejected, (state) => {
        state.reservationLoading = false
      })
      .addCase(getReservationDetails.fulfilled, (state, action) => {
        if (action.payload) {
          const { id, reservationData } = action.payload

          if (!state.reservationCache[id]) {
            state.reservationCache[id] = null
          }
          state.bookingId = id
          state.reservationCache[id] = [{ ...reservationData, isActive: true }]
        }
        state.reservationLoading = false
      })

  }
})

export const { setReservation, clearReservationState, setLastUsedDate } = reservationSlice.actions

export const selectCurrentReservation = (state: { reservation: ReservationState },): ReservationDetails[] | null =>
  state.reservation.reservationCache[state.reservation.bookingId] || null


export const selectActivChateReservation = (state: { reservation: ReservationState }): ReservationDetails | null => {
  const currentReservations = selectCurrentReservation(state);
  if (currentReservations && currentReservations[0]) {
    const currentRes = currentReservations.find(res => h_isDateBetween(dayjs(), dayjs(res.checkInDate), dayjs(res.checkOutDate)))
    if (currentRes) {
      return currentRes
    } else return null
  } else return null
};


export default reservationSlice.reducer;
