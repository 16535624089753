import React from "react";
import Whatsapp from "../../../../../assets/images/icons/JvsnINJ2CZv 1.svg";
import StatusEllipseSvg from "../../../../../assets/images/icons/StatusEllipseSvg";
import moment from "moment";
import i18n from "../../../../../utils/i18n/i18n";
import walk from "../../../../../assets/images/icons/walking.svg";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { toggleActionNeededModal } from "../../../../../store/slices/chatSlice";
import { MessageHeader } from "../../../../../types";
import "../MessageCard.scss"
import { IconMapPin } from "@tabler/icons-react";
import { Flex, Text } from "@mantine/core";
import { h_formatLabel, h_formatUTCtoLocaleTime } from "../../../../../utils/helpers";
import { useSelector } from "react-redux";

interface IncomeMessagesProps { message: MessageHeader, onMessageClick: (message: MessageHeader) => void }


const IncomeMessages: React.FC<IncomeMessagesProps> = ({ message, onMessageClick }) => {
  const selectedMessage = useSelector((state: RootState) => state.chat.messageId);
  const cacheMessage = useSelector((state: RootState) => state.chat.messagesCache[message.id]);


  const dispatch = useDispatch<AppDispatch>();
  moment.locale(i18n.language);

  const formatMessageDate = (dateString: string) => {
    const messageDate = moment(dateString);
    const today = moment();

    if (messageDate.isSame(today, "day")) {
      return h_formatUTCtoLocaleTime(dateString)
    }

    return messageDate.locale(i18n.language).format("MMM DD");
  };

  const truncateMessage = (message: string) => {
    const maxWidth = 320;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "13px Inter";
      if (context.measureText(message).width > maxWidth) {
        let truncated = message;
        while (context.measureText(truncated + "...").width > maxWidth) {
          truncated = truncated.slice(0, -1);
        }
        return truncated.slice(0, -3) + "...";
      }
    }
    return message;
  };

  const stripHtml = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const {
    bookingStatus,
    id: messageId,
    isNeedAttention,
    lastMessage,
    assignedUser,
    isRead,
    propertyName
  } = message

  const isReadPointer = (
    <span
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: "#007AFF",
        marginLeft: "4px",
      }}
    />
  )

  return (

    <div className={`message-income-card ${selectedMessage === message.id && "active"}`} onClick={() => {
      if (message.id === selectedMessage) return ""
      onMessageClick(message)
    }} >
      <Flex direction="column" gap={3}>
        <Flex flex={1} justify="space-between">
          <Flex align="center" gap={5}>
            <Text fw={!isRead ? "600" : "500"} >
              {message?.title || "Unnamed Customer"}
            </Text>
            {!isRead && isReadPointer}
            {bookingStatus ? null : (
              <img
                src={Whatsapp}
                alt="whatsapp"
                style={{ width: "16.717px", height: "18px", flexShrink: 0 }}
              />
            )}
          </Flex>

          <Text size="xs" c="gray.7" fw={!isRead ? "600" : "500"} >
            {formatMessageDate(message?.lastMessageReceivedAt)}
          </Text>
        </Flex>

        {cacheMessage?.chatInput ?
          <span className={`income-messages-message-text draft`} >
            Draft: {cacheMessage.chatInput}
          </span>

          :
          <span className={`income-messages-message-text ${!isRead ? "unread" : ""}`} >
            {truncateMessage(stripHtml(lastMessage?.slice(0, 40)))}
          </span>}

        <Flex align="center" gap={8} pl={2}>
          <StatusEllipseSvg status={bookingStatus || "unknown"} />
          <Text size="sm" c="gray.6">
            {h_formatLabel(bookingStatus)}
          </Text>
        </Flex>
        <Flex align="center" gap={5}>
          <IconMapPin size="16px" color="gray" />
          {propertyName && (
            <Text size="sm" c="gray.6">
              {propertyName}
            </Text>
          )}
        </Flex>

        {assignedUser && (
          <span className="B-12-18-500">
            {selectedMessage === messageId ? (
              <>
                {(assignedUser) && (
                  <>Assigned to {assignedUser}</>
                )}
              </>
            ) : (
              <>{`Assigned to ${assignedUser}`}</>
            )}
          </span>
        )}
        {isNeedAttention && (
          <button
            onClick={() => { dispatch(toggleActionNeededModal()) }}
            className="message-income-ai-button"
          >
            <img src={walk} alt="walk icon" />
            Action Needed
          </button>
        )}
      </Flex >
    </div >
  );
};

export default React.memo(IncomeMessages);
