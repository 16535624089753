import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "../../../../utils/i18n/i18n";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Flex, Divider, Title, useCombobox } from "@mantine/core";
import "./ReservationDetails.scss"
import ReservationDetailCard from "./ReservationDetailCard";
import ReservationBookingDetail from "./ReservationBookingDetails";
import CustomCalendar from "./BotelCalendar";
import { useSelector } from "react-redux";
import { selectCurrentReservation } from "../../../../store/slices/reservationSlice";
import LoadingDots from "../../../../components/shared-components/loadings/LoadingDots";
import { RootState } from "../../../../store/store";
import { ReservationDetails } from "../../../../types";

moment.locale(i18n.language);
dayjs.extend(isBetween);

const ReservationDetailsCard = () => {

  const { reservationLoading } = useSelector((state: RootState) => state.reservation)
  const selectedMessage = useSelector((state: RootState) => state.message.selectedMessage)
  const reservationData = useSelector(selectCurrentReservation)
  const { t } = useTranslation();
  const combobox = useCombobox({ onDropdownClose: () => combobox.resetSelectedOption(), });

  //  const [isLoading, setIsLoading] = useState(false);
  //  const [calendarReservations, setCalendarReservations] = useState<any[]>([]);
  //@ts-ignore

  //  interface UpdateReservationRequest {
  //    id: string;
  //    checkInDate?: string;
  //    checkOutDate?: string;
  //  }
  //const API_BASE_URL = process.env.REACT_APP_API_URL;

  // const fetchMonthReservations = async (startDate: string, endDate: string) => {
  // setIsLoading(true);
  // try {
  //   const response = await fetch(`${API_BASE_URL} /Reservation/GetAll`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("accessToken_NEW")} `,
  //     },
  //     body: JSON.stringify({
  //       pageIndex: 1,
  //       pageSize: 100,
  //       startDate: startDate,
  //       endDate: endDate,
  //       status: ["confirmed"],
  //       properties: [reservationData.propertyId],
  //     }),
  //   });

  //   if (!response.ok) {
  //     throw new Error(`HTTP hata! Durum: ${response.status} `);
  //   }

  //   const data = await response.json();
  //   if (data && data.items && Array.isArray(data.items)) {
  //     setCalendarReservations(data.items);
  //   } else {
  //     setCalendarReservations([]);
  //   }
  // } catch (error) {
  //   console.error("Rezervasyon verisi çekme hatası:", error);
  //   setCalendarReservations([]);
  // } finally {
  //   setIsLoading(false);
  // }
  //  };

  //  const handleMonthChange = (newDate: Dayjs) => {
  //    const startDate = newDate.startOf("month").format("YYYY-MM-DD");
  //    const endDate = newDate.endOf("month").format("YYYY-MM-DD");
  //
  //    fetchMonthReservations(startDate, endDate);
  //  };

  //  interface UpdateReservationRequest {
  //    id: string;
  //    checkInDate?: string;
  //    checkOutDate?: string;
  //  }

  //const updateReservation = async (data: UpdateReservationRequest) => {
  //   try {
  //     const accessToken = await getAccessTokenSilently();
  //     const response = await fetch(`${API_BASE_URL} /Reservation/Update`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken} `,
  //       },
  //       body: JSON.stringify({
  //         reservation: {
  //           id: data.id,
  //           checkInDate: data.checkInDate,
  //           checkOutDate: data.checkOutDate,
  //         },
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status} `);
  //     }

  //     return await response.json();
  //   } catch (error) {
  //     console.error("Rezervasyon güncelleme hatası:", error);
  //     throw error;
  //   }
  //};

  // const options = [
  //   {
  //     value: "1",
  //     label: "Res. HMFWNNCWTN",
  //     subLabel: "08/17/2024 - 08/23/2024",
  //   },
  //   {
  //     value: "2",
  //     label: "Res. ZKFLFNCWOB",
  //     subLabel: "06/08/2023 - 07/12/2023",
  //   },
  //   {
  //     value: "3",
  //     label: "Res. LSJLFNCNWE",
  //     subLabel: "07/18/2022 - 07/25/2022",
  //   },
  // ];

  //const [value, setValue] = useState<string>("");


  // const comboOptions = options.map((item) => (
  //   <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
  //     <Flex direction="column">
  //       <Text> {item.label} </Text>
  //       <Text> {item.subLabel} </Text>
  //     </Flex>
  //   </Combobox.Option>
  // ));
  //

  if (reservationLoading) return <div style={{ minWidth: "340px" }}> <LoadingDots /> </div>
  if (!reservationData || !reservationData[0]) return <div></div>

  const currentRes = reservationData.find((res: ReservationDetails) => (res.id === selectedMessage?.bookingId))

  return (
    <div className="reservation-detail-main">
      <Flex
        direction="column"
        miw="340px"
        h="100%"
      >
        <Flex justify="center" p={10} direction="column" bg="gray.0" mih="72px">
          <Title order={5}>
            {t("inboxpage.reservationdetails")}
          </Title>
          {/*<Combobox
            store={combobox}
            withinPortal={false}
            onOptionSubmit={(val) => { setValue(val); combobox.closeDropdown(); }}
          >
            <Combobox.DropdownTarget>
              <InputBase
                variant="unstyled"
                component="button"
                type="button"
                pointer
                rightSection={<Combobox.Chevron />}
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents="none"
              >
                {options.find(opt => value === opt.value)?.label}
              </InputBase>
            </Combobox.DropdownTarget>

            <Combobox.Dropdown>
              <Combobox.Options>{comboOptions}</Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>*/}
        </Flex>
        <Divider mb={10} />
        <CustomCalendar />
        {/*<CalendarReservationDetailsModal reservationData={reservationData} />
        {/* RESERVATION*/}
        <ReservationDetailCard reservationData={currentRes ?? reservationData[0]} />
        <Divider />
        <ReservationBookingDetail reservationData={currentRes ?? reservationData[0]} />
      </Flex>
    </div >
  );
};

export default ReservationDetailsCard;
