import axios from "axios";
import { BASE_API_URL } from ".";
import { MessageDetails, MessageHeaderResponse } from "../types";

const API_BASE_URL = BASE_API_URL


export enum FilterType {
  SEARCH = "search",
  ALL = "all",
  UNREAD = "unread",
  ACTION_NEED = "actionNeeded",
  NOT_REPLIED = "notReplied",
  FULL_FILTER = "fullFilter",
  NONE = "none",
}

export interface GetMessagesParams {
  accessToken: string;
  page: number;
  limit: number;
  filterType: FilterType;
  searchKey?: string;
}

export const getMessagesApi = async ({
  page,
  limit,
  searchKey,
  accessToken,
  filterType
}: GetMessagesParams) => {
  try {
    const params = new URLSearchParams();

    // Add filterType logic
    if (filterType) {
      switch (filterType) {
        case FilterType.UNREAD:
          params.append("IsRead", "false");
          break;
        case FilterType.NOT_REPLIED:
          params.append("NotReplied", "true");
          break;
        case FilterType.ACTION_NEED:
          params.append("IsNeedAttention", "true");
          break;
        case FilterType.SEARCH:
          if (searchKey) {
            params.append("Filter", searchKey);
          }
          break;
        case FilterType.ALL:
        case FilterType.NONE:
        default:
          break;
      }
    }

    params.append("pageIndex", page.toString());
    params.append("pageSize", limit.toString());

    const response = await axios.get<MessageHeaderResponse>(
      `${API_BASE_URL}/Message/Get`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params,
      }
    );

    return {
      items: response.data.items,
      totalCount: response.data.totalCount,
      pageSize: response.data.pageSize,
      pageIndex: response.data.pageIndex,
      totalPageCount: response.data.totalPageCount,
      hasNext: response.data.hasNext,
      hasPrevious: response.data.hasPrevious,
    };
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Error fetching messages.");
  }
};

export interface GetMessageDetailParams {
  id: string;
  accessToken: string;
}

export const getMessageDetailApi = async ({
  id,
  accessToken,
}: GetMessageDetailParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Message/GetDetail`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { id },
    });
    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}



export interface FetchSignleMessageParams {
  messageId: string;
  page: number;
  limit: number;
  accessToken: string;
}

export const fetchSingleMessageApi = async ({
  messageId,
  page,
  limit,
  accessToken,
}: FetchSignleMessageParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Message/GetItem`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { MessageId: messageId, PageIndex: page, PageSize: limit },
    });
    return {
      messageId,
      data: response.data,
      page,
      totalCount: response.data.totalCount,
    };
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  };
}

export interface FetchAssigneesParams {
  accessToken: string
}

export const fetchAssigneesApi = async ({ accessToken }: FetchAssigneesParams) => {
  try {
    const response = await axios.get<any[]>(
      `${API_BASE_URL}/User/Get`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  }
};


export interface GetUnreadCountParams {
  accessToken: string
}

export const getUnreadCountApi = async ({ accessToken }: GetUnreadCountParams) => {
  try {
    const response = await axios.get<any>(
      `${API_BASE_URL}/Message/UnReadCount`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    return response
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  }
}


export interface PutAssignUserParams {
  messageId: string,
  userId: string,
  accessToken: string
}

export const putAssignUserApi = async ({
  messageId,
  userId,
  accessToken
}: PutAssignUserParams) => {
  try {
    const assignUserResponse = await axios({
      url: `${API_BASE_URL}/Message/AssignUser`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        messageHeaderId: messageId,
        userId: userId,
      },
    });

    return assignUserResponse;
  } catch (error: any) {
    console.error("Error in handleSelectChange:", error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch the message."
    );
  }
}

export interface UpdateMessageHeaderParams {
  accessToken: string,
  updateData: {
    id: string;
    aiReplyStatus?: string;
    assignedUser?: string;
    isNeedAttention?: boolean;
    isClosed?: number;
    isRead?: boolean;
    mute?: number;
  }
}

export const updateMessageHeaderApi = async ({ accessToken, updateData }: UpdateMessageHeaderParams) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}/Message/UpdateMessageHeader`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: updateData,
    });

    return response;
  } catch (error: any) {
    console.error("Error updating message header:", error);
    throw new Error(
      error.response?.data?.message || "Failed to Update Message"
    );
  }
};

export const updateReadStatusApi = async ({ accessToken, messageId }: { accessToken: string, messageId: string }) => {
  try {
    await axios({
      url: `${API_BASE_URL}/Message/ReadStatus`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        messageId,
      },
    });
    return messageId;
  } catch (error) {
    throw error;
  }

}

export interface PostSendMessageParams {
  accessToken: string
  messageDetails: MessageDetails
}

export const postSendMessageApi = async ({ accessToken, messageDetails }: PostSendMessageParams) => {
  try {
    await axios({
      url: `${API_BASE_URL}/Message/Send`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: { ...messageDetails },
    });
    return messageDetails
  } catch (error: any) {
    console.error("Error updating message header:", error);
    throw new Error(
      error.response?.data?.message || "Failed to send message"
    );
  }
}

export interface UpdateMessageStatusParams {
  accessToken: string
  messageDetails: {
    status: "approved" | "declined" | ""
    messageId: string
    isRegenerate?: boolean
  }
}

export const updateMessageStatusApi = async ({ accessToken, messageDetails }: UpdateMessageStatusParams) => {
  try {
    const { messageId, status, isRegenerate } = messageDetails
    const response = await axios.put(
      `${API_BASE_URL}/Message/UpdateMessageNeedPost`,
      {
        messageId: messageId,
        status: status,
        isRegenerate: isRegenerate ?? false,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error: any) {
    console.error("Durum güncelleme hatası:", error);
    throw new Error(
      error.response?.data?.message || "Failed to send message"
    );
  }
};

