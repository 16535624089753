import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import CloseModalButton from "../../../../../components/shared-components/CloseModalButton";
import { useTranslation } from "react-i18next";
import walk from "../../../../../assets/images/icons/walking.svg";
import MainTextField from "../../../../../components/core-components/inputs/TextField/MainTextField";
import { chatBoxStyles } from "../../ChatBoxStyles";
import deleteIcon from "../../../../../assets/images/icons/deletegray.svg";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import MessageFormatter from "./MessageFormatter";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { clearInputMessage, postSendMessage, setActionNeededModal } from "../../../../../store/slices/chatSlice";
import { useSelector } from "react-redux";
import { updateMessageNeededPost } from "../../../../../store/slices/messageSlice";

interface MessageNeedPost {
  id: string;
  message: string;
  messageAI: string | null;
  status: string;
  isRegenerate: boolean;
  messageType: string;
}

interface AiModalProps {
  paperStyles?: object;
  setShowEdit: (value: boolean) => void;
  showEdit: boolean;
}

function AiModal({
  setShowEdit,
  showEdit,
}: AiModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { actionNeededModalOpen, messageId } = useSelector((state: RootState) => state.chat);
  const { selectedMessage } = useSelector((state: RootState) => state.message);
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRegenerated, setIsRegenerated] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [messageDetails, setMessageDetails] = useState<MessageNeedPost | null>(
    null
  );
  const [isLoadingMessage, setIsLoadingMessage] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const fetchMessageDetails = async () => {
    try {
      setIsLoadingMessage(true);
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_BASE_URL}/Message/GetMessageNeedPost`,
        {
          params: { MessageId: messageId },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setMessageDetails(response.data);
    } catch (error) {
      console.error("Mesaj detayları alınırken hata oluştu:", error);
    } finally {
      setIsLoadingMessage(false);
    }
  };

  useEffect(() => {
    if (actionNeededModalOpen && messageId) {
      fetchMessageDetails();
    }
    if (!actionNeededModalOpen) {
      setAnswer("");
      setIsRegenerated(false);
    }
  }, [actionNeededModalOpen, messageId]);

  const handleUpdateNeededPost = async (status: "" | "approved" | "declined", isRegenerate: boolean) => {
    try {
      const accessToken = await getAccessTokenSilently();
      await dispatch(updateMessageNeededPost({
        accessToken,
        messageDetails: {
          messageId,
          status,
          isRegenerate
        }
      }))
    } catch (err: any) {

    }
  }

  const handleRegenerate = async () => {
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();

      const response = await axios.post(
        `${API_BASE_URL}/Message/Regenerate`,
        {
          messageId: messageId,
          missignInformation: messageDetails?.message || "",
          newInformation: answer,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        const regeneratedAnswer =
          response.data.message || response.data.answer || response.data;
        if (typeof regeneratedAnswer === "string") {
          setAnswer(regeneratedAnswer);
          handleUpdateNeededPost("", true)
        } else {
          throw new Error("Invalid response format");
        }
        setIsRegenerated(true);
      }
    } catch (error) {
      console.error("Error in regeneration:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMessageStatus = async (status: "approved" | "declined") => {
    try {
      setIsLoading(true);
      await handleUpdateNeededPost(status, true)
      const accessToken = await getAccessTokenSilently();

      handleClose();

      dispatch(clearInputMessage())

      if (status === "approved") {
        await dispatch(postSendMessage({
          accessToken,
          messageDetails: {
            propertyId: selectedMessage?.propertyId ?? "",
            messageId: messageId,
            message: answer,
            channelId: "",
            channel: "",
            messageType: "message",
            sender: "property",
            usedAI: true,
            isNeedAttention: false,
            editAImessage: isRegenerated,
          }
        }))
      }

    } catch (error) {
      console.error("Durum güncelleme hatası:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleDecline = async () => {
    try {
      setIsLoading(true);
      await handleUpdateNeededPost("declined", true)
      handleClose();
    } catch (error) {
      console.error("Error declining message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCombinedAction = () => {
    if (!isRegenerated) {
      handleRegenerate();
    } else {
      updateMessageStatus("approved")
    }
  };

  const handleClose = () => {
    dispatch(setActionNeededModal(false))
  }

  return (
    <Modal
      open={actionNeededModalOpen}
      onClose={handleClose}
      aria-labelledby="ai-modal-title"
      aria-describedby="ai-modal-description"
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <Box
        sx={{
          width: "634px",
          display: "flex",
          padding: "32px",
          flexDirection: "column",
          borderRadius: "12px",
          background: "#FFF",
          boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.12)",
          gap: "20px",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          p: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <p className="ai-modal-title">
            <img
              style={{
                width: "22px",
                height: "22px",
                marginRight: "10px",
              }}
              src={walk}
              alt=""
            />
            {t("Action Needed")}
          </p>
          <CloseModalButton show={true} onClick={handleClose} />
        </Box>
        <Box>
          {isLoadingMessage ? (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <p
              style={{
                padding: "12px",
                backgroundColor: "#F3F4F6",
                borderRadius: "8px",
                color: "#333",
                fontSize: "14px",
                lineHeight: "1.5",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                border: "1px solid #E0E0E0",
              }}
            >
              <MessageFormatter
                text={messageDetails?.message || "No Message Found in Server"}
              />{" "}
            </p>
          )}
        </Box>

        {actionNeededModalOpen && !showEdit && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
              mt={2}
            >
              <Typography
                sx={{
                  color: "var(--Gray-Gray-900, #181C32)",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
                variant="body1"
                component="label"
                htmlFor="main-text-field"
              >
                Information{" "}
              </Typography>
              <MainTextField
                placeholder={"Provide details to the questions above"}
                onChange={(e) => setAnswer(e.target.value)}
                value={answer}
                type="text"
                multiline
              />
            </Box>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
            >
              <Button
                onClick={handleDecline}
                sx={chatBoxStyles.aiModalActionButtons}
                disabled={isLoading}
              >
                <img alt="deleteIcon" src={deleteIcon} /> Decline
              </Button>
              <button
                onClick={handleCombinedAction}
                style={{
                  fontWeight: 500,
                  padding: "10px 12px",
                  width: "120px",
                }}
                className="listing-details-is-editing-box-bottom-button-save B-13-13-600"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : isRegenerated ? (
                  "Approve"
                ) : (
                  "Regenerate"
                )}
              </button>
            </Stack>
          </>
        )}
        {!showEdit && !actionNeededModalOpen && (
          <>
            <Stack direction={"column"} justifyContent={"flex-end"} gap={2}>
              <Button
                onClick={() => setShowEdit(true)}
                sx={chatBoxStyles.aiOptionButtons}
              >
                Daha Kısa
              </Button>
              <Button sx={chatBoxStyles.aiOptionButtons}>Daha Uzun</Button>
              <Button onClick={handleClose} sx={chatBoxStyles.aiOptionButtons}>
                Samimi Ol
              </Button>
              <button
                style={{
                  fontWeight: 600,
                }}
                className="ai-button"
              >
                Başka Bir Şey
              </button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default AiModal;
