import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { BASE_SIGNAL_API } from "../../api";
import { fetchAssigneesApi, FetchAssigneesParams, FilterType, getMessageDetailApi, GetMessageDetailParams, getMessagesApi, GetMessagesParams, getUnreadCountApi, GetUnreadCountParams, putAssignUserApi, PutAssignUserParams, updateMessageHeaderApi, UpdateMessageHeaderParams, updateMessageStatusApi, UpdateMessageStatusParams, updateReadStatusApi } from "../../api/messageApi";
import signalRService from "../../signalr/SignalRService";
import { AssigneUser, MessageDetails, MessageHeader, MessageItemResponse } from "../../types";
import { MessageState, MessageWithTypePayload, ParsedMessage } from "../types/messageTypes";
import { rh_isSignalMessageValid, rh_parseSignalRMessage, rh_updateMessageInAllCaches } from "../utils/messageUtils";
import { updateChatCache } from "./chatSlice";


const initialState: MessageState = {
  connectionStarted: false,
  actionNeededModalOpen: false,
  messagesLoading: false,
  mobileMessageOpen: false,
  filterType: FilterType.ALL,
  allMessagesCache: {
    search: [],
    all: [],
    unread: [],
    actionNeeded: [],
    notReplied: [],
    fullFilter: [],
    none: [],
  },
  searchCache: {},
  messageMetaDataCache: {
    search: null,
    all: null,
    unread: null,
    actionNeeded: null,
    notReplied: null,
    fullFilter: null,
    none: null,
  },
  tabMessageCounts: {
    all: 0,
    unread: 0,
    actionNeeded: 0,
    notReplied: 0,
    archived: 0,
  },
  selectedMessage: null,
  assigneUsers: [],
  messageId: "",
  error: null,
};


export const initializeSignalR = createAsyncThunk(
  "chat/initializeSignalR",
  async (token: string, { dispatch }) => {
    try {
      await signalRService.startConnection(BASE_SIGNAL_API as string);
      try {
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        if (tokenPayload) {
          const customerId = tokenPayload.customerId
          const email = tokenPayload.email

          if (customerId && email) {
            const connectUser = {
              CustomerId: customerId,
              UserName: email,
            };
            await signalRService.sendMessage("ConnectClient", connectUser);
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }

      signalRService.onReceiveMessage("SendMessage", (message) => {
        dispatch(updateMessageCache(message));
      });

      signalRService.onReceiveMessage("ReceiveMessage", (message) => {
        const parsedMessage = rh_parseSignalRMessage(message)

        if (rh_isSignalMessageValid(parsedMessage)) {
          dispatch(updateMessageCache(parsedMessage));
          dispatch(updateChatCache(parsedMessage));
        }
      });

      signalRService.onReceiveMessage("NeedAttentionMessage", (message) => {
        const parsedMessage = rh_parseSignalRMessage(message)

        if (rh_isSignalMessageValid(parsedMessage)) {
          dispatch(handleMessageWithType({ parsedMessage, messageType: "actionneeded" }));
        }
      });

      signalRService.onReceiveMessage("SemiautoMessage", (message) => {
        const parsedMessage = rh_parseSignalRMessage(message)

        if (rh_isSignalMessageValid(parsedMessage)) {
          dispatch(handleMessageWithType({ parsedMessage, messageType: "semiauto" }));
        }
      });

      signalRService.onReceiveMessage("NeedMessageIsChange", (message) => {
        const parsedMessage = rh_parseSignalRMessage(message)

        if (rh_isSignalMessageValid(parsedMessage)) {
          // IF MESSAGE HANDLED FROM PMS OR OTHER PLACE REMOVE IS NEED ATTENTION
          dispatch(handleMessageRemoveAttention(parsedMessage));
        }

      });

      signalRService.onReceiveMessage("NewConversation", (message) => {
        let parsedMessage: MessageHeader;
        if (typeof message === "string") {
          parsedMessage = JSON.parse(message);
        } else {
          parsedMessage = message;
        }
        //WARN: NEED TO CALL API WITH THAT MESSAGE ID FROM HERE, THIS NEED TO TEST NOT SURE WORKING OR NOT,
        //@ts-ignore
        dispatch(getMessageDetail({ accessToken: token, id: parsedMessage.Id }))
      });
      return true;
    } catch (error) {
      console.error("SignalR initialization error:", error);
      return false;
    }
  }
);

export const getMessages = createAsyncThunk("message/getMessage", async (
  params: GetMessagesParams
  , { rejectWithValue }
) => {
  try {
    const response = await getMessagesApi(params);
    return {
      ...response,
      //items: h_sortMessagesByDate(response.items ?? []),
      items: response.items ?? [],
      filterType: params.filterType,
      searchKey: params.searchKey
    };
  } catch (error: any) {
    console.error("Error fetching messages:", error.message);
    return rejectWithValue(error.message);
  }
})

export const getMessageDetail = createAsyncThunk("message/getMessageDetail", async (
  params: GetMessageDetailParams,
  { rejectWithValue }
) => {
  try {
    const response = await getMessageDetailApi(params);
    return response
  } catch (error: any) {
    console.error("Error fetching messages:", error.message);
    return rejectWithValue(error.message);
  }
})
export const getAssigness = createAsyncThunk("message/getAssigness", async (
  params: FetchAssigneesParams,
  { rejectWithValue }
) => {
  try {
    const response = await fetchAssigneesApi(params);
    return response.data
  } catch (error: any) {
    console.error("Error fetching messages:", error.message);
    return rejectWithValue(error.message);
  }
})


export const getUnreadCount = createAsyncThunk("message/getUnreadCount", async (
  params: GetUnreadCountParams,
  { rejectWithValue }
) => {
  try {
    const response = await getUnreadCountApi(params);
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

export const updateMessageHeader = createAsyncThunk("message/updateMessageHeader", async (
  params: UpdateMessageHeaderParams,
  { rejectWithValue }
) => {
  try {
    await updateMessageHeaderApi(params);
    return params.updateData
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

export const updateMessageReadStatus = createAsyncThunk("message/updateReadStatus", async (
  params: any,
  { rejectWithValue }
) => {
  try {
    return await updateReadStatusApi(params)
    //TODO: HANDLE LOCAL STATE
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateMessageNeededPost = createAsyncThunk("message/updateMessageNeededPost", async (
  params: UpdateMessageStatusParams,
  { rejectWithValue }
) => {
  try {
    return await updateMessageStatusApi(params)
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})

// PUT
export const putAssignUser = createAsyncThunk("message/putAssignUser", async (
  params: PutAssignUserParams,
  { rejectWithValue }
) => {
  try {
    await putAssignUserApi(params);
    return params.userId
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
})



const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessagesLoading: (state, action: PayloadAction<boolean>) => {
      state.messagesLoading = action.payload
    },
    setFilterType: (state, action: PayloadAction<FilterType>) => {
      state.filterType = action.payload
    },
    setSelectedMessage: (state, action: PayloadAction<MessageHeader | null>) => {
      if (action.payload) {
        const messageId = action.payload.id;
        state.selectedMessage = { ...action.payload, filterType: state.filterType }
        state.messageId = messageId;
      }
    },

    handleFilteredMessages: (state, action) => {
      const { items, ...rest } = action.payload;
      const rft = FilterType.FULL_FILTER

      state.messageMetaDataCache[rft] = rest
      state.allMessagesCache[rft] = items

      state.messagesLoading = false;
    },
    addMessageToCache: (state, action: PayloadAction<MessageDetails>) => {
      const { messageId } = state

      const id = Math.floor(Math.random() * 1000000).toString()
      const newMessage = {
        ...action.payload,
        id,
        createdAt: new Date().toISOString(),
        createdByName: "Property",
      };

      const updatedMessage = {
        lastMessage: newMessage.message,
        lastMessageReceivedAt: newMessage.createdAt,
      };

      rh_updateMessageInAllCaches(state.allMessagesCache, messageId, updatedMessage)

      //        const updatedMessage = {
      //          ...state.allMessages[messageIndex],
      //          lastMessage: newMessage.message,
      //          lastMessageReceivedAt: newMessage.createdAt,
      //          messageCount: (state.allMessages[messageIndex].messageCount || 0) + 1,
      //        };
      //
      //        state.allMessages.splice(messageIndex, 1);
      //        state.allMessages.unshift(updatedMessage);
    },
    updateMessageCache: (state, action: PayloadAction<ParsedMessage>) => {
      const parsedMessage = action.payload

      const updatedMessage = {
        lastMessage: parsedMessage.message,
        lastMessageReceivedAt: new Date().toISOString(),
        isRead: false,
      };

      rh_updateMessageInAllCaches(state.allMessagesCache, parsedMessage.messageId, updatedMessage)
      // const existingMessageIndex = state.allMessages.findIndex(
      //   (msg) => msg.id === parsedMessage.messageId
      // );

      // // add to all messages, which left side messages on screen
      // if (existingMessageIndex !== -1) {
      //   const updatedMessage = {
      //     ...state.allMessages[existingMessageIndex],
      //     lastMessage: parsedMessage.message,
      //     lastMessageReceivedAt: new Date().toISOString(),
      //     messageCount:
      //       (state.allMessages[existingMessageIndex].messageCount || 0) + 1,
      //     isRead: false,
      //   };

      //   state.allMessages.splice(existingMessageIndex, 1);
      //   state.allMessages.unshift(updatedMessage);
      // }
    },
    handleMessageRemoveAttention: (state, action: PayloadAction<ParsedMessage>) => {
      const parsedMessage = action.payload

      const newMessage = {
        isNeedAttention: false,
        messageNeedPost: {
          status: "",
          messageType: "",
          messageAIKnows: "",
          message: "",
          id: null,
          isRegenerate: null,
        },
      }

      rh_updateMessageInAllCaches(state.allMessagesCache, parsedMessage.messageId, newMessage)

      if (state.selectedMessage && state.selectedMessage.id === parsedMessage.messageId) {
        state.selectedMessage = { ...state.selectedMessage, ...newMessage }
      }
    },
    handleMessageWithType: (state, action: PayloadAction<MessageWithTypePayload>) => {
      //NOTE: HANDLE IF MESSAGE HAS ANY TYPE
      const { parsedMessage, messageType } = action.payload;

      //TEST: NOT DO FOR SELECETED DO FOR IN ALL MESSAGE
      //TODO: HANDLE SELECTED MESSAGE WIT HFILTER
      //
      //
      const newMessage = {
        isNeedAttention: messageType === "actionneeded",
        messageNeedPost: {
          status: "pending",
          messageType: messageType,
          messageAIKnows: "",
          message: parsedMessage.message,
          id: parsedMessage.id,
          isRegenerate: null,
        }
      };

      rh_updateMessageInAllCaches(state.allMessagesCache, parsedMessage.messageId, newMessage)

      if (state.selectedMessage && state.selectedMessage.id === parsedMessage.messageId) {
        state.selectedMessage = { ...state.selectedMessage, ...newMessage }
      }

      // state.allMessages[messageIndex] = {
      //   ...state.allMessages[messageIndex],
      //   isNeedAttention: messageType === "actionneeded" ? true : false,
      //   messageNeedPost: {
      //     status: "pending",
      //     messageType: messageType,
      //     messageAIKnows: "",
      //     message: parsedMessage.message,
      //     id: null,
      //     isRegenerate: null,
      //   },
      // };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeSignalR.fulfilled, (state, action) => {
        state.connectionStarted = action.payload;
      })
      .addCase(initializeSignalR.rejected, (state) => {
        state.connectionStarted = false;
        state.error = "SignalR bağlantısı başlatılamadı";
      })
      .addCase(getMessages.pending, (state) => {
        state.messagesLoading = true;
        state.error = null;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        const { items, ...rest } = action.payload;

        const rft = rest.filterType

        // Handle which type of filter we are dealing with
        if (rft) {
          if (rft !== FilterType.SEARCH) {
            state.messageMetaDataCache[rft] = rest
            state.allMessagesCache[rft] = [...state.allMessagesCache[rft], ...items]
          } else {
            state.messageMetaDataCache[rft] = rest
            state.allMessagesCache[rft] = [...items]
          }

          //  else if (rest.searchKey && rft === FilterType.SEARCH) {
          //    const skey = rest.searchKey
          //    if (!state.searchCache[skey] && items.length) {
          //      // SEARCH FILTER
          //      state.searchCache[skey] = [...state.searchCache[skey], ...items]
          //      state.allMessagesCache[rft] = [...state.allMessagesCache[rft], ...items]
          //    }
          // }
        }
        // if payload has filter type set it 
        //  if (state.filterType !== rest.filterType) {
        //    state.filterType = rest.filterType
        //  }

        if (rest.filterType === FilterType.ACTION_NEED) {
          state.tabMessageCounts.actionNeeded = rest.totalCount
        } else if (rest.filterType === FilterType.NOT_REPLIED) {
          state.tabMessageCounts.notReplied = rest.totalCount
        } else {
          state.tabMessageCounts.all = rest.totalCount
        }

        state.messagesLoading = false;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.messagesLoading = false;
        state.error = action.payload as string;
      })
      .addCase(getMessageDetail.fulfilled, (state, action) => {
        const message = action.payload.data as unknown as MessageItemResponse
        if (message && message.items) {
          const items = message.items[0] as MessageHeader
          state.allMessagesCache[FilterType.ALL] = [items, ...state.allMessagesCache[FilterType.ALL]]
        }
      })
      .addCase(getUnreadCount.fulfilled, (state, action) => {
        state.tabMessageCounts.unread = action.payload?.count
      })
      .addCase(updateMessageNeededPost.fulfilled, (state, action) => {

        const newMessageNeed = {
          isNeedAttention: false,
          messageNeedPost: {
            status: "",
            messageType: "",
            messageAIKnows: "",
            message: "",
            id: "",
            isRegenerate: null,
          }
        }
        rh_updateMessageInAllCaches(state.allMessagesCache, state.messageId, newMessageNeed)

        //WARN: CAN BE BUG THERE NEED TO CHECK

        if (state.selectedMessage) {
          state.selectedMessage = { ...state.selectedMessage, ...newMessageNeed }
        }

        if (state.tabMessageCounts.actionNeeded > 0) {
          state.tabMessageCounts.actionNeeded -= 1;
        }
        //          state.allMessages[messageIndex] = {
        //            ...state.allMessages[messageIndex],
        //            isNeedAttention: false,
        //            messageNeedPost
        //          };
        //

      })
      .addCase(updateMessageNeededPost.rejected, (state, action) => {
      })
      .addCase(updateMessageReadStatus.fulfilled, (state) => {

        rh_updateMessageInAllCaches(state.allMessagesCache, state.messageId, { isRead: true })

        if (state.tabMessageCounts.unread > 0) {
          state.tabMessageCounts.unread -= 1;
        }
      })
      .addCase(updateMessageHeader.fulfilled, (state) => {

        rh_updateMessageInAllCaches(state.allMessagesCache, state.messageId, { isRead: false })

        state.tabMessageCounts.unread += 1;
      })
      .addCase(getAssigness.fulfilled, (state, action) => {
        const users: AssigneUser[] = action.payload.map((user: AssigneUser) => {
          const data = {
            id: user.id,
            email: user.email,
            name: user.firstName,
            firstName: user.firstName,
            lastName: user.lastName,
          }
          if (user.lastName) {
            data.name = `${user.firstName} ${user.lastName}`
          }
          return data
        });
        state.assigneUsers = users
      })
      .addCase(putAssignUser.pending, (state) => {
        state.error = null;
      })
      .addCase(putAssignUser.fulfilled, (state, action: PayloadAction<string>) => {
        const user = state.assigneUsers.find(ass => ass.id === action.payload)
        if (user && state.selectedMessage) {
          state.selectedMessage = { ...state.selectedMessage, assignedUser: user.email }
          rh_updateMessageInAllCaches(state.allMessagesCache, state.messageId, { assignedUser: user.email })
          toast.success(`${user.name} assigned to this customer`)
        }

      })
      .addCase(putAssignUser.rejected, (state, action) => {
        state.error = action.error.message || "Mesajlar yüklenirken bir hata oluştu";
      })

  }
})

export const {
  setMessagesLoading,
  setSelectedMessage,
  setFilterType,
  addMessageToCache,
  updateMessageCache,
  handleMessageWithType,
  handleFilteredMessages,
  handleMessageRemoveAttention,
} = messageSlice.actions;

export default messageSlice.reducer;

