import { Flex, Title } from "@mantine/core";
import InviteTeamMember from "../../onboardingTwo/step3/InviteTeamMember";

const AccountUsers = () => {

  const handleSelectionChangeStep12 = () => {

  }

  return (
    <Flex w="100%" p={20} direction="column">
      <Title order={2}>User Management</Title>
      <InviteTeamMember handleSelectionChange={handleSelectionChangeStep12} />
    </Flex>
  )
}

export default AccountUsers;
