export enum ProviderType {
  MANUAL = "manual",
  WHATSAPP = "whatsapp",
  AIRBNB = "airbnb",
  AIRBNB2 = "airbnb2",
  GUESTY = "guesty",
  HOSTAWAY = "hostaway",
  LODGIFY = "lodgify",
  BOOKING = "bookingcom",
  BOOKING2 = "booking.com",
}

export type MessageType = "message" | "booking";
