import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Paper, Text } from "@mantine/core"
import { useEffect } from "react";
import Logo from "../../../assets/images/Logo.png";

const EmailVerify = () => {
	const { user } = useAuth0();
	//const dispatch: AppDispatch = useDispatch();

	useEffect(() => {
		//	setTimeout(() => {
		//		sessionStorage.clear();
		//		localStorage.clear();
		//		dispatch(clearUserToken())
		//		logout({
		//			logoutParams: {
		//				returnTo: window.location.origin,
		//			},
		//		})
		//	}, 5000)
	}, [])

	return (
		<Flex h="100vh" w="100%" align="center" justify="center">
			<Paper withBorder py={20} px={40} radius="md" shadow="xs">
				<Flex gap={20} direction="column" align="center" justify="center">
					<img
						style={{
							cursor: "pointer",
						}}
						src={Logo}
						alt="Botel Logo"
						className="logo"
					/>
					<Text size="xl" fw={500}>Please verify your email</Text>
					<Flex direction="column">
						<Text size="md" c="dimmed">We just sent an email to {user?.email}.</Text>
						<Text size="md" c="dimmed">Please activate your account and log in again.</Text>
					</Flex>
				</Flex>
			</Paper>
		</Flex>
	)
}

export default EmailVerify
