import { MantineThemeOverride } from "@mantine/core";

export const getMantineTheme = (): MantineThemeOverride => ({
  colors: {
    primary: [
      "#EAF2FF", // Lightest shade
      "#CCE0FF",
      "#99C3FF",
      "#66A6FF",
      "#338AFF",
      "#0065E0", // Main shade
      "#0053B3",
      "#003E80",
      "#002B5C",
      "#001A40", // Darkest shade
    ],
    blue: [
      '#E3F2FD', // blue.0
      '#BBDEFB', // blue.1
      '#90CAF9', // blue.2
      '#64B5F6', // blue.3
      '#42A5F5', // blue.4
      '#0065E0', // BOTEL CUSTOM COLOR
      '#1E88E5', // blue.6
      '#1976D2', // blue.7
      '#1565C0', // blue.8
      '#0D47A1', // blue.9
    ],
  },
  primaryColor: "primary",
  fontFamily: 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
  primaryShade: 5, // Refers to the 6th color (#0065E0)
  focusRing: 'never',
  headings: {
    fontFamily: 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
    fontWeight: "600",
  },
  // globalStyles: () => ({
  //   "*, *::before, *::after": {
  //     color: "#181B1D",
  //     fontSize: "14px",
  //     lineHeight: "24px",
  //     wordBreak: "break-word",
  //   },
  // }),
});

